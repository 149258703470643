// routing.tsx

import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import { MainPageContainer as NotificationsMainPage } from "./main.page";
import { RouteData } from "../../main.page";

export const NotificationsRouting: RouteData[] = [
  {
    key: "/notifications",
    path: "/notifications",
    component: NotificationsMainPage,
    exact: true
  }
];
