// locations.service.ts

import ApolloClient from "apollo-boost";
import { gql } from "apollo-boost";
import Axios from "axios";
import { HelperService } from "../../../services/helper.service";
import { client } from "./graphql.module";

const FRAGMENT = gql`
  fragment TimezoneFragment on Timezone {
    dstOffset
    rawOffset
    id
    name
  }
`;

export class LocationsService {
  constructor() {}
  /*
	public getTimezone(id: string): Observable<any> {
		return this._helperService.get('timezone', FRAGMENT, id);
  }*/

  public getTimezoneLocation(
    lat: number,
    lon: number,
    timestamp: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: HelperService.createQueryGetInput(
            "timezoneLocation",
            "LocationTimezoneInput",
            FRAGMENT,
            "TimezoneFragment"
          ),
          variables: {
            input: { lat, lon, timestamp }
          }
        })
        .then(result => {
          console.log(result);
          resolve(result.data.timezoneLocation.data);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }

  public getReverseLocation(
    latitude: number,
    longitude: number,
    language: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      Axios.get("https://nominatim.openstreetmap.org/reverse", {
        params: {
          format: "jsonv2",
          language: language,
          lat: latitude,
          lon: longitude
        }
      })
        .then(response => {
          console.log(response);
          const city = response.data.address.city_district
            ? response.data.address.city_district
            : response.data.address.town
            ? response.data.address.town
            : response.data.address.village
            ? response.data.address.village
            : response.data.address.county;
          resolve({
            city: city,
            country: response.data.address.country_code.toUpperCase()
          });
        })
        .catch(error => {
          console.log(error);
          reject(error);
        })
        .then(() => {
          // always executed
        });
    });
  }
}
