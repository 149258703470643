// auth.validator.ts

import * as Yup from "yup";

export const SigninStep1Schema = Yup.object().shape({
  username: Yup.string()
    .min(5, "Your username must be at least 5 characters!")
    .max(64, "Your username must be no more than 64 characters!")
    .required("Username is required"),
  password: Yup.string()
    .required("Password is required")
});

export const SigninStep2Schema = Yup.object().shape({
  token: Yup.string()
    .min(6, "Your token must be at least 6 digits!")
    .max(6, "Your token must be at least 6 digits!")
    .required("Token is required")
});
