// header-menu.component.tsx

import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { useHistory } from "react-router-dom";

const menu = (history: any) => (
  <Menu>
    <Menu.Item key="0" onClick={() => {
      history.push("/settings");
    }}>
      <div style={{ padding: "0 35px 0 10px" }}>
        <i className="fas fa-cog" style={{ marginRight: "10px" }}></i> Settings
      </div>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="1" onClick={() => {
      history.push("/signout");
    }}>
    <div style={{ padding: "0 35px 0 10px" }}>
        <i className="fas fa-sign-out-alt" style={{ marginRight: "10px" }}></i> Sign out
      </div>
    </Menu.Item>
  </Menu>
);

type Props = {};

const HeaderMenu: React.FC<Props> = ({ children }) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const history = useHistory();

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Dropdown overlay={menu(history)} trigger={["click"]}>
      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
        <Avatar
          style={{ backgroundColor: "#1f411d" }}
          icon={<i className="fas fa-user"></i>}
        />
      </a>
    </Dropdown>
  );
};

export default HeaderMenu;
