import * as React from "react";
import { Layout, Menu, Icon } from "antd";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import "./main.layout.css";
import { MenuSectionContainer } from "../menu.section";
import HeaderMenu from "../components/menu/header-menu.component";

const { Header, Sider, Content } = Layout;

type Props = {};

const MainLayout: React.FC<Props> = ({ children }) => {
  const [collapsed, setCollapsed] = React.useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout className="main-layout">
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <MenuSectionContainer collapsed={collapsed}/>
      </Sider>
      <Layout>
        <Header style={{ background: "#fff", padding: 0, display: 'flex', alignItems: 'center' }}>
          <Icon
            className="trigger"
            type={collapsed ? "menu-unfold" : "menu-fold"}
            onClick={toggle}
          />
          <div style={{ display: "flex", flex: 1, flexDirection: "column", alignItems: 'flex-end', margin: '0 30px' }}>
            <HeaderMenu />
          </div>
        </Header>
        {children}
      </Layout>
    </Layout>
  );
};

export default MainLayout;
