// numeric-input.tsx

import React from "react";
import { Input } from "formik-antd";

import "./numeric-input.css";

/* function formatNumber(value: string) {
  value += "";
  const list = value.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
} */

type Props = {
  name: string;
  value?: string;
  size?: "small" | "default" | "large";
  prefix?: React.ReactNode;
  placeholder?: string;
  maxLength: number;
  // onChange?: (event?: any) => void;
  // onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void;
};

type State = {
  value: string;
};

export class NumericInput extends React.Component<Props, State> {
  state = {
    value: this.props.value || ""
  }

  onChange = (e: any) => {
    console.log(e.target.value);
    const { value } = e.target;
    const reg = /^[0-9]*?$/;
    if ((!isNaN(value) && reg.test(value)) || value === "") {
      /* if (this.props.onChange) {
        this.props.onChange(value);
        this.setState({ value });
      } */
      this.setState({ value });
    }
  };

  // '.' at the end or only '-' in the input box.
  /* onBlur = () => {
    console.log("onBlur.........");
    const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    if (value && value.charAt(value.length - 1) === "." || value === "-") {
      valueTemp = value.slice(0, -1);
    }
    if (valueTemp && onChange) {
      onChange(valueTemp.replace(/0*(\d+)/, "$1"));
      if (onBlur) {
        onBlur();
      }
    }
  }; */

  render() {
    const { value } = this.props;
    return (
      <Input
        {...this.props}
        value={this.state.value}
        onChange={this.onChange}
        // onBlur={this.onBlur}
      />
    );
  }
}
