export const DataOptions = {
	status: [
		{ name: 'Disabled', value: 0 },
		{ name: 'Enabled', value: 1 }
	],
	creatorType:
		[
			{ name: 'Admin', value: 0 },
			{ name: 'Masjid', value: 1 },
			{ name: 'User', value: 2 }
		],
	locationType:
		[
			{ name: 'GPS', value: 0 },
			{ name: 'City', value: 1 },
			{ name: 'Country', value: 2 },
			{ name: 'World', value: 3 }
		],
	method:
		[
			{ name: 'Ithna Ashari', value: 0 },
			{ name: 'University of Islamic Sciences, Karachi', value: 1 },
			{ name: 'Islamic Society of North America (ISNA)', value: 2 },
			{ name: 'Muslim World League (MWL)', value: 3 },
			{ name: 'Umm al-Qura, Makkah', value: 4 },
			{ name: 'Egyptian General Authority of Survey', value: 5 },
			{ name: 'Institute of Geophysics University of Tehran', value: 6 },
			{ name: 'Union des Organisations Islamiques de france (UOIF)', value: 7 },
			{ name: 'Twilight Angle', value: -1 },
			{ name: 'Twilight Angle and Time', value: -2 }
		],
	angleBasedMethod:
		[
			{ name: 'No adjustment', value: 0 },
			{ name: 'Middle of night', value: 1 },
			{ name: '1/7th of night', value: 2 },
			{ name: 'Angle/60th of night', value: 3 }
		],
	midnightMethod:
		[
			{ name: 'Standard method', value: 0 },
			{ name: 'Jafari method', value: 1 }
		],
	asrMethod:
		[
			{ name: 'Standard method', value: 0 },
			{ name: 'Hanafi method', value: 1 }
		],
	countries: [
		{ name: 'Afghanistan', code: 'AF' },
		{ name: 'Åland Islands', code: 'AX' },
		{ name: 'Albania', code: 'AL' },
		{ name: 'Algeria', code: 'DZ' },
		{ name: 'American Samoa', code: 'AS' },
		{ name: 'AndorrA', code: 'AD' },
		{ name: 'Angola', code: 'AO' },
		{ name: 'Anguilla', code: 'AI' },
		{ name: 'Antarctica', code: 'AQ' },
		{ name: 'Antigua and Barbuda', code: 'AG' },
		{ name: 'Argentina', code: 'AR' },
		{ name: 'Armenia', code: 'AM' },
		{ name: 'Aruba', code: 'AW' },
		{ name: 'Australia', code: 'AU' },
		{ name: 'Austria', code: 'AT' },
		{ name: 'Azerbaijan', code: 'AZ' },
		{ name: 'Bahamas', code: 'BS' },
		{ name: 'Bahrain', code: 'BH' },
		{ name: 'Bangladesh', code: 'BD' },
		{ name: 'Barbados', code: 'BB' },
		{ name: 'Belarus', code: 'BY' },
		{ name: 'Belgium', code: 'BE' },
		{ name: 'Belize', code: 'BZ' },
		{ name: 'Benin', code: 'BJ' },
		{ name: 'Bermuda', code: 'BM' },
		{ name: 'Bhutan', code: 'BT' },
		{ name: 'Bolivia', code: 'BO' },
		{ name: 'Bosnia and Herzegovina', code: 'BA' },
		{ name: 'Botswana', code: 'BW' },
		{ name: 'Bouvet Island', code: 'BV' },
		{ name: 'Brazil', code: 'BR' },
		{ name: 'British Indian Ocean Territory', code: 'IO' },
		{ name: 'Brunei Darussalam', code: 'BN' },
		{ name: 'Bulgaria', code: 'BG' },
		{ name: 'Burkina Faso', code: 'BF' },
		{ name: 'Burundi', code: 'BI' },
		{ name: 'Cambodia', code: 'KH' },
		{ name: 'Cameroon', code: 'CM' },
		{ name: 'Canada', code: 'CA' },
		{ name: 'Cape Verde', code: 'CV' },
		{ name: 'Cayman Islands', code: 'KY' },
		{ name: 'Central African Republic', code: 'CF' },
		{ name: 'Chad', code: 'TD' },
		{ name: 'Chile', code: 'CL' },
		{ name: 'China', code: 'CN' },
		{ name: 'Christmas Island', code: 'CX' },
		{ name: 'Cocos (Keeling) Islands', code: 'CC' },
		{ name: 'Colombia', code: 'CO' },
		{ name: 'Comoros', code: 'KM' },
		{ name: 'Congo', code: 'CG' },
		{ name: 'Congo, The Democratic Republic of the', code: 'CD' },
		{ name: 'Cook Islands', code: 'CK' },
		{ name: 'Costa Rica', code: 'CR' },
		{ name: 'Cote D\'Ivoire', code: 'CI' },
		{ name: 'Croatia', code: 'HR' },
		{ name: 'Cuba', code: 'CU' },
		{ name: 'Cyprus', code: 'CY' },
		{ name: 'Czech Republic', code: 'CZ' },
		{ name: 'Denmark', code: 'DK' },
		{ name: 'Djibouti', code: 'DJ' },
		{ name: 'Dominica', code: 'DM' },
		{ name: 'Dominican Republic', code: 'DO' },
		{ name: 'Ecuador', code: 'EC' },
		{ name: 'Egypt', code: 'EG' },
		{ name: 'El Salvador', code: 'SV' },
		{ name: 'Equatorial Guinea', code: 'GQ' },
		{ name: 'Eritrea', code: 'ER' },
		{ name: 'Estonia', code: 'EE' },
		{ name: 'Ethiopia', code: 'ET' },
		{ name: 'Falkland Islands (Malvinas)', code: 'FK' },
		{ name: 'Faroe Islands', code: 'FO' },
		{ name: 'Fiji', code: 'FJ' },
		{ name: 'Finland', code: 'FI' },
		{ name: 'France', code: 'FR' },
		{ name: 'French Guiana', code: 'GF' },
		{ name: 'French Polynesia', code: 'PF' },
		{ name: 'French Southern Territories', code: 'TF' },
		{ name: 'Gabon', code: 'GA' },
		{ name: 'Gambia', code: 'GM' },
		{ name: 'Georgia', code: 'GE' },
		{ name: 'Germany', code: 'DE' },
		{ name: 'Ghana', code: 'GH' },
		{ name: 'Gibraltar', code: 'GI' },
		{ name: 'Greece', code: 'GR' },
		{ name: 'Greenland', code: 'GL' },
		{ name: 'Grenada', code: 'GD' },
		{ name: 'Guadeloupe', code: 'GP' },
		{ name: 'Guam', code: 'GU' },
		{ name: 'Guatemala', code: 'GT' },
		{ name: 'Guernsey', code: 'GG' },
		{ name: 'Guinea', code: 'GN' },
		{ name: 'Guinea-Bissau', code: 'GW' },
		{ name: 'Guyana', code: 'GY' },
		{ name: 'Haiti', code: 'HT' },
		{ name: 'Heard Island and Mcdonald Islands', code: 'HM' },
		{ name: 'Holy See (Vatican City State)', code: 'VA' },
		{ name: 'Honduras', code: 'HN' },
		{ name: 'Hong Kong', code: 'HK' },
		{ name: 'Hungary', code: 'HU' },
		{ name: 'Iceland', code: 'IS' },
		{ name: 'India', code: 'IN' },
		{ name: 'Indonesia', code: 'ID' },
		{ name: 'Iran, Islamic Republic Of', code: 'IR' },
		{ name: 'Iraq', code: 'IQ' },
		{ name: 'Ireland', code: 'IE' },
		{ name: 'Isle of Man', code: 'IM' },
		{ name: 'Israel', code: 'IL' },
		{ name: 'Italy', code: 'IT' },
		{ name: 'Jamaica', code: 'JM' },
		{ name: 'Japan', code: 'JP' },
		{ name: 'Jersey', code: 'JE' },
		{ name: 'Jordan', code: 'JO' },
		{ name: 'Kazakhstan', code: 'KZ' },
		{ name: 'Kenya', code: 'KE' },
		{ name: 'Kiribati', code: 'KI' },
		{ name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
		{ name: 'Korea, Republic of', code: 'KR' },
		{ name: 'Kuwait', code: 'KW' },
		{ name: 'Kyrgyzstan', code: 'KG' },
		{ name: 'Lao People\'S Democratic Republic', code: 'LA' },
		{ name: 'Latvia', code: 'LV' },
		{ name: 'Lebanon', code: 'LB' },
		{ name: 'Lesotho', code: 'LS' },
		{ name: 'Liberia', code: 'LR' },
		{ name: 'Libyan Arab Jamahiriya', code: 'LY' },
		{ name: 'Liechtenstein', code: 'LI' },
		{ name: 'Lithuania', code: 'LT' },
		{ name: 'Luxembourg', code: 'LU' },
		{ name: 'Macao', code: 'MO' },
		{ name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
		{ name: 'Madagascar', code: 'MG' },
		{ name: 'Malawi', code: 'MW' },
		{ name: 'Malaysia', code: 'MY' },
		{ name: 'Maldives', code: 'MV' },
		{ name: 'Mali', code: 'ML' },
		{ name: 'Malta', code: 'MT' },
		{ name: 'Marshall Islands', code: 'MH' },
		{ name: 'Martinique', code: 'MQ' },
		{ name: 'Mauritania', code: 'MR' },
		{ name: 'Mauritius', code: 'MU' },
		{ name: 'Mayotte', code: 'YT' },
		{ name: 'Mexico', code: 'MX' },
		{ name: 'Micronesia, Federated States of', code: 'FM' },
		{ name: 'Moldova, Republic of', code: 'MD' },
		{ name: 'Monaco', code: 'MC' },
		{ name: 'Mongolia', code: 'MN' },
		{ name: 'Montserrat', code: 'MS' },
		{ name: 'Morocco', code: 'MA' },
		{ name: 'Mozambique', code: 'MZ' },
		{ name: 'Myanmar', code: 'MM' },
		{ name: 'Namibia', code: 'NA' },
		{ name: 'Nauru', code: 'NR' },
		{ name: 'Nepal', code: 'NP' },
		{ name: 'Netherlands', code: 'NL' },
		{ name: 'Netherlands Antilles', code: 'AN' },
		{ name: 'New Caledonia', code: 'NC' },
		{ name: 'New Zealand', code: 'NZ' },
		{ name: 'Nicaragua', code: 'NI' },
		{ name: 'Niger', code: 'NE' },
		{ name: 'Nigeria', code: 'NG' },
		{ name: 'Niue', code: 'NU' },
		{ name: 'Norfolk Island', code: 'NF' },
		{ name: 'Northern Mariana Islands', code: 'MP' },
		{ name: 'Norway', code: 'NO' },
		{ name: 'Oman', code: 'OM' },
		{ name: 'Pakistan', code: 'PK' },
		{ name: 'Palau', code: 'PW' },
		{ name: 'Palestinian Territory, Occupied', code: 'PS' },
		{ name: 'Panama', code: 'PA' },
		{ name: 'Papua New Guinea', code: 'PG' },
		{ name: 'Paraguay', code: 'PY' },
		{ name: 'Peru', code: 'PE' },
		{ name: 'Philippines', code: 'PH' },
		{ name: 'Pitcairn', code: 'PN' },
		{ name: 'Poland', code: 'PL' },
		{ name: 'Portugal', code: 'PT' },
		{ name: 'Puerto Rico', code: 'PR' },
		{ name: 'Qatar', code: 'QA' },
		{ name: 'Reunion', code: 'RE' },
		{ name: 'Romania', code: 'RO' },
		{ name: 'Russian Federation', code: 'RU' },
		{ name: 'RWANDA', code: 'RW' },
		{ name: 'Saint Helena', code: 'SH' },
		{ name: 'Saint Kitts and Nevis', code: 'KN' },
		{ name: 'Saint Lucia', code: 'LC' },
		{ name: 'Saint Pierre and Miquelon', code: 'PM' },
		{ name: 'Saint Vincent and the Grenadines', code: 'VC' },
		{ name: 'Samoa', code: 'WS' },
		{ name: 'San Marino', code: 'SM' },
		{ name: 'Sao Tome and Principe', code: 'ST' },
		{ name: 'Saudi Arabia', code: 'SA' },
		{ name: 'Senegal', code: 'SN' },
		{ name: 'Serbia and Montenegro', code: 'CS' },
		{ name: 'Seychelles', code: 'SC' },
		{ name: 'Sierra Leone', code: 'SL' },
		{ name: 'Singapore', code: 'SG' },
		{ name: 'Slovakia', code: 'SK' },
		{ name: 'Slovenia', code: 'SI' },
		{ name: 'Solomon Islands', code: 'SB' },
		{ name: 'Somalia', code: 'SO' },
		{ name: 'South Africa', code: 'ZA' },
		{ name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
		{ name: 'Spain', code: 'ES' },
		{ name: 'Sri Lanka', code: 'LK' },
		{ name: 'Sudan', code: 'SD' },
		{ name: 'Suriname', code: 'SR' },
		{ name: 'Svalbard and Jan Mayen', code: 'SJ' },
		{ name: 'Swaziland', code: 'SZ' },
		{ name: 'Sweden', code: 'SE' },
		{ name: 'Switzerland', code: 'CH' },
		{ name: 'Syrian Arab Republic', code: 'SY' },
		{ name: 'Taiwan, Province of China', code: 'TW' },
		{ name: 'Tajikistan', code: 'TJ' },
		{ name: 'Tanzania, United Republic of', code: 'TZ' },
		{ name: 'Thailand', code: 'TH' },
		{ name: 'Timor-Leste', code: 'TL' },
		{ name: 'Togo', code: 'TG' },
		{ name: 'Tokelau', code: 'TK' },
		{ name: 'Tonga', code: 'TO' },
		{ name: 'Trinidad and Tobago', code: 'TT' },
		{ name: 'Tunisia', code: 'TN' },
		{ name: 'Turkey', code: 'TR' },
		{ name: 'Turkmenistan', code: 'TM' },
		{ name: 'Turks and Caicos Islands', code: 'TC' },
		{ name: 'Tuvalu', code: 'TV' },
		{ name: 'Uganda', code: 'UG' },
		{ name: 'Ukraine', code: 'UA' },
		{ name: 'United Arab Emirates', code: 'AE' },
		{ name: 'United Kingdom', code: 'GB' },
		{ name: 'United States', code: 'US' },
		{ name: 'United States Minor Outlying Islands', code: 'UM' },
		{ name: 'Uruguay', code: 'UY' },
		{ name: 'Uzbekistan', code: 'UZ' },
		{ name: 'Vanuatu', code: 'VU' },
		{ name: 'Venezuela', code: 'VE' },
		{ name: 'Viet Nam', code: 'VN' },
		{ name: 'Virgin Islands, British', code: 'VG' },
		{ name: 'Virgin Islands, U.S.', code: 'VI' },
		{ name: 'Wallis and Futuna', code: 'WF' },
		{ name: 'Western Sahara', code: 'EH' },
		{ name: 'Yemen', code: 'YE' },
		{ name: 'Zambia', code: 'ZM' },
		{ name: 'Zimbabwe', code: 'ZW' }
	],
	timezones:
		[
			{ name: 'GMT-12:00 - Etc/GMT+12', value: 'Etc/GMT+12' },
			{ name: 'GMT-11:00 - Etc/GMT+11', value: 'Etc/GMT+11' },
			{ name: 'Samoa Standard Time - Pacific/Midway', value: 'Pacific/Midway' },
			{ name: 'Niue Time - Pacific/Niue', value: 'Pacific/Niue' },
			{ name: 'Samoa Standard Time - Pacific/Pago_Pago', value: 'Pacific/Pago_Pago' },
			{ name: 'Samoa Standard Time - Pacific/Samoa', value: 'Pacific/Samoa' },
			{ name: 'Samoa Standard Time - US/Samoa', value: 'US/Samoa' },
			{ name: 'Hawaii-Aleutian Standard Time - America/Adak', value: 'America/Adak' },
			{ name: 'Hawaii-Aleutian Standard Time - America/Atka', value: 'America/Atka' },
			{ name: 'GMT-10:00 - Etc/GMT+10', value: 'Etc/GMT+10' },
			{ name: 'Hawaii Standard Time - HST', value: 'HST' },
			{ name: 'Hawaii Standard Time - Pacific/Honolulu', value: 'Pacific/Honolulu' },
			{ name: 'Hawaii Standard Time - Pacific/Johnston', value: 'Pacific/Johnston' },
			{ name: 'Cook Is. Time - Pacific/Rarotonga', value: 'Pacific/Rarotonga' },
			{ name: 'Tahiti Time - Pacific/Tahiti', value: 'Pacific/Tahiti' },
			{ name: 'Hawaii Standard Time - SystemV/HST10', value: 'SystemV/HST10' },
			{ name: 'Hawaii-Aleutian Standard Time - US/Aleutian', value: 'US/Aleutian' },
			{ name: 'Hawaii Standard Time - US/Hawaii', value: 'US/Hawaii' },
			{ name: 'Marquesas Time - Pacific/Marquesas', value: 'Pacific/Marquesas' },
			{ name: 'Alaska Standard Time - AST', value: 'AST' },
			{ name: 'Alaska Standard Time - America/Anchorage', value: 'America/Anchorage' },
			{ name: 'Alaska Standard Time - America/Juneau', value: 'America/Juneau' },
			{ name: 'Alaska Standard Time - America/Nome', value: 'America/Nome' },
			{ name: 'Alaska Standard Time - America/Sitka', value: 'America/Sitka' },
			{ name: 'Alaska Standard Time - America/Yakutat', value: 'America/Yakutat' },
			{ name: 'GMT-09:00 - Etc/GMT+9', value: 'Etc/GMT+9' },
			{ name: 'Gambier Time - Pacific/Gambier', value: 'Pacific/Gambier' },
			{ name: 'Alaska Standard Time - SystemV/YST9', value: 'SystemV/YST9' },
			{ name: 'Alaska Standard Time - SystemV/YST9YDT', value: 'SystemV/YST9YDT' },
			{ name: 'Alaska Standard Time - US/Alaska', value: 'US/Alaska' },
			{ name: 'Pacific Standard Time - America/Dawson', value: 'America/Dawson' },
			{ name: 'Pacific Standard Time - America/Ensenada', value: 'America/Ensenada' },
			{ name: 'Pacific Standard Time - America/Los_Angeles', value: 'America/Los_Angeles' },
			{ name: 'Metlakatla Standard Time - America/Metlakatla', value: 'America/Metlakatla' },
			{ name: 'Pacific Standard Time - America/Santa_Isabel', value: 'America/Santa_Isabel' },
			{ name: 'Pacific Standard Time - America/Tijuana', value: 'America/Tijuana' },
			{ name: 'Pacific Standard Time - America/Vancouver', value: 'America/Vancouver' },
			{ name: 'Pacific Standard Time - America/Whitehorse', value: 'America/Whitehorse' },
			{ name: 'Pacific Standard Time - Canada/Pacific', value: 'Canada/Pacific' },
			{ name: 'Pacific Standard Time - Canada/Yukon', value: 'Canada/Yukon' },
			{ name: 'GMT-08:00 - Etc/GMT+8', value: 'Etc/GMT+8' },
			{ name: 'Pacific Standard Time - Mexico/BajaNorte', value: 'Mexico/BajaNorte' },
			{ name: 'Pacific Standard Time - PST', value: 'PST' },
			{ name: 'Pacific Standard Time - PST8PDT', value: 'PST8PDT' },
			{ name: 'Pitcairn Standard Time - Pacific/Pitcairn', value: 'Pacific/Pitcairn' },
			{ name: 'Pacific Standard Time - SystemV/PST8', value: 'SystemV/PST8' },
			{ name: 'Pacific Standard Time - SystemV/PST8PDT', value: 'SystemV/PST8PDT' },
			{ name: 'Pacific Standard Time - US/Pacific', value: 'US/Pacific' },
			{ name: 'Pacific Standard Time - US/Pacific-New', value: 'US/Pacific-New' },
			{ name: 'Mountain Standard Time - America/Boise', value: 'America/Boise' },
			{ name: 'Mountain Standard Time - America/Cambridge_Bay', value: 'America/Cambridge_Bay' },
			{ name: 'Mountain Standard Time - America/Chihuahua', value: 'America/Chihuahua' },
			{ name: 'Mountain Standard Time - America/Creston', value: 'America/Creston' },
			{ name: 'Mountain Standard Time - America/Dawson_Creek', value: 'America/Dawson_Creek' },
			{ name: 'Mountain Standard Time - America/Denver', value: 'America/Denver' },
			{ name: 'Mountain Standard Time - America/Edmonton', value: 'America/Edmonton' },
			{ name: 'Mountain Standard Time - America/Hermosillo', value: 'America/Hermosillo' },
			{ name: 'Mountain Standard Time - America/Inuvik', value: 'America/Inuvik' },
			{ name: 'Mountain Standard Time - America/Mazatlan', value: 'America/Mazatlan' },
			{ name: 'Mountain Standard Time - America/Ojinaga', value: 'America/Ojinaga' },
			{ name: 'Mountain Standard Time - America/Phoenix', value: 'America/Phoenix' },
			{ name: 'Mountain Standard Time - America/Shiprock', value: 'America/Shiprock' },
			{ name: 'Mountain Standard Time - America/Yellowknife', value: 'America/Yellowknife' },
			{ name: 'Mountain Standard Time - Canada/Mountain', value: 'Canada/Mountain' },
			{ name: 'GMT-07:00 - Etc/GMT+7', value: 'Etc/GMT+7' },
			{ name: 'Mountain Standard Time - MST', value: 'MST' },
			{ name: 'Mountain Standard Time - MST7MDT', value: 'MST7MDT' },
			{ name: 'Mountain Standard Time - Mexico/BajaSur', value: 'Mexico/BajaSur' },
			{ name: 'Mountain Standard Time - Navajo', value: 'Navajo' },
			{ name: 'Mountain Standard Time - PNT', value: 'PNT' },
			{ name: 'Mountain Standard Time - SystemV/MST7', value: 'SystemV/MST7' },
			{ name: 'Mountain Standard Time - SystemV/MST7MDT', value: 'SystemV/MST7MDT' },
			{ name: 'Mountain Standard Time - US/Arizona', value: 'US/Arizona' },
			{ name: 'Mountain Standard Time - US/Mountain', value: 'US/Mountain' },
			{ name: 'Central Standard Time - America/Bahia_Banderas', value: 'America/Bahia_Banderas' },
			{ name: 'Central Standard Time - America/Belize', value: 'America/Belize' },
			{ name: 'Central Standard Time - America/Cancun', value: 'America/Cancun' },
			{ name: 'Central Standard Time - America/Chicago', value: 'America/Chicago' },
			{ name: 'Central Standard Time - America/Costa_Rica', value: 'America/Costa_Rica' },
			{ name: 'Central Standard Time - America/El_Salvador', value: 'America/El_Salvador' },
			{ name: 'Central Standard Time - America/Guatemala', value: 'America/Guatemala' },
			{ name: 'Central Standard Time - America/Indiana/Knox', value: 'America/Indiana/Knox' },
			{ name: 'Central Standard Time - America/Indiana/Tell_City', value: 'America/Indiana/Tell_City' },
			{ name: 'Central Standard Time - America/Knox_IN', value: 'America/Knox_IN' },
			{ name: 'Central Standard Time - America/Managua', value: 'America/Managua' },
			{ name: 'Central Standard Time - America/Matamoros', value: 'America/Matamoros' },
			{ name: 'Central Standard Time - America/Menominee', value: 'America/Menominee' },
			{ name: 'Central Standard Time - America/Merida', value: 'America/Merida' },
			{ name: 'Central Standard Time - America/Mexico_City', value: 'America/Mexico_City' },
			{ name: 'Central Standard Time - America/Monterrey', value: 'America/Monterrey' },
			{ name: 'Central Standard Time - America/North_Dakota/Beulah', value: 'America/North_Dakota/Beulah' },
			{ name: 'Central Standard Time - America/North_Dakota/Center', value: 'America/North_Dakota/Center' },
			{ name: 'Central Standard Time - America/North_Dakota/New_Salem', value: 'America/North_Dakota/New_Salem' },
			{ name: 'Central Standard Time - America/Rainy_River', value: 'America/Rainy_River' },
			{ name: 'Central Standard Time - America/Rankin_Inlet', value: 'America/Rankin_Inlet' },
			{ name: 'Central Standard Time - America/Regina', value: 'America/Regina' },
			{ name: 'Central Standard Time - America/Resolute', value: 'America/Resolute' },
			{ name: 'Central Standard Time - America/Swift_Current', value: 'America/Swift_Current' },
			{ name: 'Central Standard Time - America/Tegucigalpa', value: 'America/Tegucigalpa' },
			{ name: 'Central Standard Time - America/Winnipeg', value: 'America/Winnipeg' },
			{ name: 'Central Standard Time - CST', value: 'CST' },
			{ name: 'Central Standard Time - CST6CDT', value: 'CST6CDT' },
			{ name: 'Central Standard Time - Canada/Central', value: 'Canada/Central' },
			{ name: 'Central Standard Time - Canada/East-Saskatchewan', value: 'Canada/East-Saskatchewan' },
			{ name: 'Central Standard Time - Canada/Saskatchewan', value: 'Canada/Saskatchewan' },
			{ name: 'Easter Is. Time - Chile/EasterIsland', value: 'Chile/EasterIsland' },
			{ name: 'GMT-06:00 - Etc/GMT+6', value: 'Etc/GMT+6' },
			{ name: 'Central Standard Time - Mexico/General', value: 'Mexico/General' },
			{ name: 'Easter Is. Time - Pacific/Easter', value: 'Pacific/Easter' },
			{ name: 'Galapagos Time - Pacific/Galapagos', value: 'Pacific/Galapagos' },
			{ name: 'Central Standard Time - SystemV/CST6', value: 'SystemV/CST6' },
			{ name: 'Central Standard Time - SystemV/CST6CDT', value: 'SystemV/CST6CDT' },
			{ name: 'Central Standard Time - US/Central', value: 'US/Central' },
			{ name: 'Central Standard Time - US/Indiana-Starke', value: 'US/Indiana-Starke' },
			{ name: 'Eastern Standard Time - America/Atikokan', value: 'America/Atikokan' },
			{ name: 'Colombia Time - America/Bogota', value: 'America/Bogota' },
			{ name: 'Eastern Standard Time - America/Cayman', value: 'America/Cayman' },
			{ name: 'Eastern Standard Time - America/Coral_Harbour', value: 'America/Coral_Harbour' },
			{ name: 'Eastern Standard Time - America/Detroit', value: 'America/Detroit' },
			{ name: 'Acre Time - America/Eirunepe', value: 'America/Eirunepe' },
			{ name: 'Eastern Standard Time - America/Fort_Wayne', value: 'America/Fort_Wayne' },
			{ name: 'Eastern Standard Time - America/Grand_Turk', value: 'America/Grand_Turk' },
			{ name: 'Ecuador Time - America/Guayaquil', value: 'America/Guayaquil' },
			{ name: 'Cuba Standard Time - America/Havana', value: 'America/Havana' },
			{ name: 'Eastern Standard Time - America/Indiana/Indianapolis', value: 'America/Indiana/Indianapolis' },
			{ name: 'Eastern Standard Time - America/Indiana/Marengo', value: 'America/Indiana/Marengo' },
			{ name: 'Eastern Standard Time - America/Indiana/Petersburg', value: 'America/Indiana/Petersburg' },
			{ name: 'Eastern Standard Time - America/Indiana/Vevay', value: 'America/Indiana/Vevay' },
			{ name: 'Eastern Standard Time - America/Indiana/Vincennes', value: 'America/Indiana/Vincennes' },
			{ name: 'Eastern Standard Time - America/Indiana/Winamac', value: 'America/Indiana/Winamac' },
			{ name: 'Eastern Standard Time - America/Indianapolis', value: 'America/Indianapolis' },
			{ name: 'Eastern Standard Time - America/Iqaluit', value: 'America/Iqaluit' },
			{ name: 'Eastern Standard Time - America/Jamaica', value: 'America/Jamaica' },
			{ name: 'Eastern Standard Time - America/Kentucky/Louisville', value: 'America/Kentucky/Louisville' },
			{ name: 'Eastern Standard Time - America/Kentucky/Monticello', value: 'America/Kentucky/Monticello' },
			{ name: 'Peru Time - America/Lima', value: 'America/Lima' },
			{ name: 'Eastern Standard Time - America/Louisville', value: 'America/Louisville' },
			{ name: 'Eastern Standard Time - America/Montreal', value: 'America/Montreal' },
			{ name: 'Eastern Standard Time - America/Nassau', value: 'America/Nassau' },
			{ name: 'Eastern Standard Time - America/New_York', value: 'America/New_York' },
			{ name: 'Eastern Standard Time - America/Nipigon', value: 'America/Nipigon' },
			{ name: 'Eastern Standard Time - America/Panama', value: 'America/Panama' },
			{ name: 'Eastern Standard Time - America/Pangnirtung', value: 'America/Pangnirtung' },
			{ name: 'Eastern Standard Time - America/Port-au-Prince', value: 'America/Port-au-Prince' },
			{ name: 'Acre Time - America/Porto_Acre', value: 'America/Porto_Acre' },
			{ name: 'Acre Time - America/Rio_Branco', value: 'America/Rio_Branco' },
			{ name: 'Eastern Standard Time - America/Thunder_Bay', value: 'America/Thunder_Bay' },
			{ name: 'Eastern Standard Time - America/Toronto', value: 'America/Toronto' },
			{ name: 'Acre Time - Brazil/Acre', value: 'Brazil/Acre' },
			{ name: 'Eastern Standard Time - Canada/Eastern', value: 'Canada/Eastern' },
			{ name: 'Cuba Standard Time - Cuba', value: 'Cuba' },
			{ name: 'Eastern Standard Time - EST', value: 'EST' },
			{ name: 'Eastern Standard Time - EST5EDT', value: 'EST5EDT' },
			{ name: 'GMT-05:00 - Etc/GMT+5', value: 'Etc/GMT+5' },
			{ name: 'Eastern Standard Time - IET', value: 'IET' },
			{ name: 'Eastern Standard Time - Jamaica', value: 'Jamaica' },
			{ name: 'Eastern Standard Time - SystemV/EST5', value: 'SystemV/EST5' },
			{ name: 'Eastern Standard Time - SystemV/EST5EDT', value: 'SystemV/EST5EDT' },
			{ name: 'Eastern Standard Time - US/East-Indiana', value: 'US/East-Indiana' },
			{ name: 'Eastern Standard Time - US/Eastern', value: 'US/Eastern' },
			{ name: 'Eastern Standard Time - US/Michigan', value: 'US/Michigan' },
			{ name: 'Venezuela Time - America/Caracas', value: 'America/Caracas' },
			{ name: 'Atlantic Standard Time - America/Anguilla', value: 'America/Anguilla' },
			{ name: 'Atlantic Standard Time - America/Antigua', value: 'America/Antigua' },
			{ name: 'Atlantic Standard Time - America/Aruba', value: 'America/Aruba' },
			{ name: 'Paraguay Time - America/Asuncion', value: 'America/Asuncion' },
			{ name: 'Atlantic Standard Time - America/Barbados', value: 'America/Barbados' },
			{ name: 'Atlantic Standard Time - America/Blanc-Sablon', value: 'America/Blanc-Sablon' },
			{ name: 'Amazon Time - America/Boa_Vista', value: 'America/Boa_Vista' },
			{ name: 'Amazon Time - America/Campo_Grande', value: 'America/Campo_Grande' },
			{ name: 'Amazon Time - America/Cuiaba', value: 'America/Cuiaba' },
			{ name: 'Atlantic Standard Time - America/Curacao', value: 'America/Curacao' },
			{ name: 'Atlantic Standard Time - America/Dominica', value: 'America/Dominica' },
			{ name: 'Atlantic Standard Time - America/Glace_Bay', value: 'America/Glace_Bay' },
			{ name: 'Atlantic Standard Time - America/Goose_Bay', value: 'America/Goose_Bay' },
			{ name: 'Atlantic Standard Time - America/Grenada', value: 'America/Grenada' },
			{ name: 'Atlantic Standard Time - America/Guadeloupe', value: 'America/Guadeloupe' },
			{ name: 'Guyana Time - America/Guyana', value: 'America/Guyana' },
			{ name: 'Atlantic Standard Time - America/Halifax', value: 'America/Halifax' },
			{ name: 'Atlantic Standard Time - America/Kralendijk', value: 'America/Kralendijk' },
			{ name: 'Bolivia Time - America/La_Paz', value: 'America/La_Paz' },
			{ name: 'Atlantic Standard Time - America/Lower_Princes', value: 'America/Lower_Princes' },
			{ name: 'Amazon Time - America/Manaus', value: 'America/Manaus' },
			{ name: 'Atlantic Standard Time - America/Marigot', value: 'America/Marigot' },
			{ name: 'Atlantic Standard Time - America/Martinique', value: 'America/Martinique' },
			{ name: 'Atlantic Standard Time - America/Moncton', value: 'America/Moncton' },
			{ name: 'Atlantic Standard Time - America/Montserrat', value: 'America/Montserrat' },
			{ name: 'Atlantic Standard Time - America/Port_of_Spain', value: 'America/Port_of_Spain' },
			{ name: 'Amazon Time - America/Porto_Velho', value: 'America/Porto_Velho' },
			{ name: 'Atlantic Standard Time - America/Puerto_Rico', value: 'America/Puerto_Rico' },
			{ name: 'Chile Time - America/Santiago', value: 'America/Santiago' },
			{ name: 'Atlantic Standard Time - America/Santo_Domingo', value: 'America/Santo_Domingo' },
			{ name: 'Atlantic Standard Time - America/St_Barthelemy', value: 'America/St_Barthelemy' },
			{ name: 'Atlantic Standard Time - America/St_Kitts', value: 'America/St_Kitts' },
			{ name: 'Atlantic Standard Time - America/St_Lucia', value: 'America/St_Lucia' },
			{ name: 'Atlantic Standard Time - America/St_Thomas', value: 'America/St_Thomas' },
			{ name: 'Atlantic Standard Time - America/St_Vincent', value: 'America/St_Vincent' },
			{ name: 'Atlantic Standard Time - America/Thule', value: 'America/Thule' },
			{ name: 'Atlantic Standard Time - America/Tortola', value: 'America/Tortola' },
			{ name: 'Atlantic Standard Time - America/Virgin', value: 'America/Virgin' },
			{ name: 'Chile Time - Antarctica/Palmer', value: 'Antarctica/Palmer' },
			{ name: 'Atlantic Standard Time - Atlantic/Bermuda', value: 'Atlantic/Bermuda' },
			{ name: 'Amazon Time - Brazil/West', value: 'Brazil/West' },
			{ name: 'Atlantic Standard Time - Canada/Atlantic', value: 'Canada/Atlantic' },
			{ name: 'Chile Time - Chile/Continental', value: 'Chile/Continental' },
			{ name: 'GMT-04:00 - Etc/GMT+4', value: 'Etc/GMT+4' },
			{ name: 'Atlantic Standard Time - PRT', value: 'PRT' },
			{ name: 'Atlantic Standard Time - SystemV/AST4', value: 'SystemV/AST4' },
			{ name: 'Atlantic Standard Time - SystemV/AST4ADT', value: 'SystemV/AST4ADT' },
			{ name: 'Newfoundland Standard Time - America/St_Johns', value: 'America/St_Johns' },
			{ name: 'Newfoundland Standard Time - CNT', value: 'CNT' },
			{ name: 'Newfoundland Standard Time - Canada/Newfoundland', value: 'Canada/Newfoundland' },
			{ name: 'Argentine Time - AGT', value: 'AGT' },
			{ name: 'Brasilia Time - America/Araguaina', value: 'America/Araguaina' },
			{ name: 'Argentine Time - America/Argentina/Buenos_Aires', value: 'America/Argentina/Buenos_Aires' },
			{ name: 'Argentine Time - America/Argentina/Catamarca', value: 'America/Argentina/Catamarca' },
			{ name: 'Argentine Time - America/Argentina/ComodRivadavia', value: 'America/Argentina/ComodRivadavia' },
			{ name: 'Argentine Time - America/Argentina/Cordoba', value: 'America/Argentina/Cordoba' },
			{ name: 'Argentine Time - America/Argentina/Jujuy', value: 'America/Argentina/Jujuy' },
			{ name: 'Argentine Time - America/Argentina/La_Rioja', value: 'America/Argentina/La_Rioja' },
			{ name: 'Argentine Time - America/Argentina/Mendoza', value: 'America/Argentina/Mendoza' },
			{ name: 'Argentine Time - America/Argentina/Rio_Gallegos', value: 'America/Argentina/Rio_Gallegos' },
			{ name: 'Argentine Time - America/Argentina/Salta', value: 'America/Argentina/Salta' },
			{ name: 'Argentine Time - America/Argentina/San_Juan', value: 'America/Argentina/San_Juan' },
			{ name: 'Argentine Time - America/Argentina/San_Luis', value: 'America/Argentina/San_Luis' },
			{ name: 'Argentine Time - America/Argentina/Tucuman', value: 'America/Argentina/Tucuman' },
			{ name: 'Argentine Time - America/Argentina/Ushuaia', value: 'America/Argentina/Ushuaia' },
			{ name: 'Brasilia Time - America/Bahia', value: 'America/Bahia' },
			{ name: 'Brasilia Time - America/Belem', value: 'America/Belem' },
			{ name: 'Argentine Time - America/Buenos_Aires', value: 'America/Buenos_Aires' },
			{ name: 'Argentine Time - America/Catamarca', value: 'America/Catamarca' },
			{ name: 'French Guiana Time - America/Cayenne', value: 'America/Cayenne' },
			{ name: 'Argentine Time - America/Cordoba', value: 'America/Cordoba' },
			{ name: 'Brasilia Time - America/Fortaleza', value: 'America/Fortaleza' },
			{ name: 'Western Greenland Time - America/Godthab', value: 'America/Godthab' },
			{ name: 'Argentine Time - America/Jujuy', value: 'America/Jujuy' },
			{ name: 'Brasilia Time - America/Maceio', value: 'America/Maceio' },
			{ name: 'Argentine Time - America/Mendoza', value: 'America/Mendoza' },
			{ name: 'Pierre & Miquelon Standard Time - America/Miquelon', value: 'America/Miquelon' },
			{ name: 'Uruguay Time - America/Montevideo', value: 'America/Montevideo' },
			{ name: 'Suriname Time - America/Paramaribo', value: 'America/Paramaribo' },
			{ name: 'Brasilia Time - America/Recife', value: 'America/Recife' },
			{ name: 'Argentine Time - America/Rosario', value: 'America/Rosario' },
			{ name: 'Brasilia Time - America/Santarem', value: 'America/Santarem' },
			{ name: 'Brasilia Time - America/Sao_Paulo', value: 'America/Sao_Paulo' },
			{ name: 'Rothera Time - Antarctica/Rothera', value: 'Antarctica/Rothera' },
			{ name: 'Falkland Is. Time - Atlantic/Stanley', value: 'Atlantic/Stanley' },
			{ name: 'Brasilia Time - BET', value: 'BET' },
			{ name: 'Brasilia Time - Brazil/East', value: 'Brazil/East' },
			{ name: 'GMT-03:00 - Etc/GMT+3', value: 'Etc/GMT+3' },
			{ name: 'Fernando de Noronha Time - America/Noronha', value: 'America/Noronha' },
			{ name: 'South Georgia Standard Time - Atlantic/South_Georgia', value: 'Atlantic/South_Georgia' },
			{ name: 'Fernando de Noronha Time - Brazil/DeNoronha', value: 'Brazil/DeNoronha' },
			{ name: 'GMT-02:00 - Etc/GMT+2', value: 'Etc/GMT+2' },
			{ name: 'Eastern Greenland Time - America/Scoresbysund', value: 'America/Scoresbysund' },
			{ name: 'Azores Time - Atlantic/Azores', value: 'Atlantic/Azores' },
			{ name: 'Cape Verde Time - Atlantic/Cape_Verde', value: 'Atlantic/Cape_Verde' },
			{ name: 'GMT-01:00 - Etc/GMT+1', value: 'Etc/GMT+1' },
			{ name: 'Greenwich Mean Time - Africa/Abidjan', value: 'Africa/Abidjan' },
			{ name: 'Ghana Mean Time - Africa/Accra', value: 'Africa/Accra' },
			{ name: 'Greenwich Mean Time - Africa/Bamako', value: 'Africa/Bamako' },
			{ name: 'Greenwich Mean Time - Africa/Banjul', value: 'Africa/Banjul' },
			{ name: 'Greenwich Mean Time - Africa/Bissau', value: 'Africa/Bissau' },
			{ name: 'Western European Time - Africa/Casablanca', value: 'Africa/Casablanca' },
			{ name: 'Greenwich Mean Time - Africa/Conakry', value: 'Africa/Conakry' },
			{ name: 'Greenwich Mean Time - Africa/Dakar', value: 'Africa/Dakar' },
			{ name: 'Western European Time - Africa/El_Aaiun', value: 'Africa/El_Aaiun' },
			{ name: 'Greenwich Mean Time - Africa/Freetown', value: 'Africa/Freetown' },
			{ name: 'Greenwich Mean Time - Africa/Lome', value: 'Africa/Lome' },
			{ name: 'Greenwich Mean Time - Africa/Monrovia', value: 'Africa/Monrovia' },
			{ name: 'Greenwich Mean Time - Africa/Nouakchott', value: 'Africa/Nouakchott' },
			{ name: 'Greenwich Mean Time - Africa/Ouagadougou', value: 'Africa/Ouagadougou' },
			{ name: 'Greenwich Mean Time - Africa/Sao_Tome', value: 'Africa/Sao_Tome' },
			{ name: 'Greenwich Mean Time - Africa/Timbuktu', value: 'Africa/Timbuktu' },
			{ name: 'Greenwich Mean Time - America/Danmarkshavn', value: 'America/Danmarkshavn' },
			{ name: 'Coordinated Universal Time - Antarctica/Troll', value: 'Antarctica/Troll' },
			{ name: 'Western European Time - Atlantic/Canary', value: 'Atlantic/Canary' },
			{ name: 'Western European Time - Atlantic/Faeroe', value: 'Atlantic/Faeroe' },
			{ name: 'Western European Time - Atlantic/Faroe', value: 'Atlantic/Faroe' },
			{ name: 'Western European Time - Atlantic/Madeira', value: 'Atlantic/Madeira' },
			{ name: 'Greenwich Mean Time - Atlantic/Reykjavik', value: 'Atlantic/Reykjavik' },
			{ name: 'Greenwich Mean Time - Atlantic/St_Helena', value: 'Atlantic/St_Helena' },
			{ name: 'Greenwich Mean Time - Eire', value: 'Eire' },
			{ name: 'GMT+00:00 - Etc/GMT', value: 'Etc/GMT' },
			{ name: 'GMT+00:00 - Etc/GMT+0', value: 'Etc/GMT+0' },
			{ name: 'GMT+00:00 - Etc/GMT-0', value: 'Etc/GMT-0' },
			{ name: 'GMT+00:00 - Etc/GMT0', value: 'Etc/GMT0' },
			{ name: 'Greenwich Mean Time - Etc/Greenwich', value: 'Etc/Greenwich' },
			{ name: 'Coordinated Universal Time - Etc/UCT', value: 'Etc/UCT' },
			{ name: 'Coordinated Universal Time - Etc/UTC', value: 'Etc/UTC' },
			{ name: 'Coordinated Universal Time - Etc/Universal', value: 'Etc/Universal' },
			{ name: 'Coordinated Universal Time - Etc/Zulu', value: 'Etc/Zulu' },
			{ name: 'Greenwich Mean Time - Europe/Belfast', value: 'Europe/Belfast' },
			{ name: 'Greenwich Mean Time - Europe/Dublin', value: 'Europe/Dublin' },
			{ name: 'Greenwich Mean Time - Europe/Guernsey', value: 'Europe/Guernsey' },
			{ name: 'Greenwich Mean Time - Europe/Isle_of_Man', value: 'Europe/Isle_of_Man' },
			{ name: 'Greenwich Mean Time - Europe/Jersey', value: 'Europe/Jersey' },
			{ name: 'Western European Time - Europe/Lisbon', value: 'Europe/Lisbon' },
			{ name: 'Greenwich Mean Time - Europe/London', value: 'Europe/London' },
			{ name: 'Greenwich Mean Time - GB', value: 'GB' },
			{ name: 'Greenwich Mean Time - GB-Eire', value: 'GB-Eire' },
			{ name: 'Greenwich Mean Time - GMT', value: 'GMT' },
			{ name: 'GMT+00:00 - GMT0', value: 'GMT0' },
			{ name: 'Greenwich Mean Time - Greenwich', value: 'Greenwich' },
			{ name: 'Greenwich Mean Time - Iceland', value: 'Iceland' },
			{ name: 'Western European Time - Portugal', value: 'Portugal' },
			{ name: 'Coordinated Universal Time - UCT', value: 'UCT' },
			{ name: 'Coordinated Universal Time - UTC', value: 'UTC' },
			{ name: 'Coordinated Universal Time - Universal', value: 'Universal' },
			{ name: 'Western European Time - WET', value: 'WET' },
			{ name: 'Coordinated Universal Time - Zulu', value: 'Zulu' },
			{ name: 'Central European Time - Africa/Algiers', value: 'Africa/Algiers' },
			{ name: 'Western African Time - Africa/Bangui', value: 'Africa/Bangui' },
			{ name: 'Western African Time - Africa/Brazzaville', value: 'Africa/Brazzaville' },
			{ name: 'Central European Time - Africa/Ceuta', value: 'Africa/Ceuta' },
			{ name: 'Western African Time - Africa/Douala', value: 'Africa/Douala' },
			{ name: 'Western African Time - Africa/Kinshasa', value: 'Africa/Kinshasa' },
			{ name: 'Western African Time - Africa/Lagos', value: 'Africa/Lagos' },
			{ name: 'Western African Time - Africa/Libreville', value: 'Africa/Libreville' },
			{ name: 'Western African Time - Africa/Luanda', value: 'Africa/Luanda' },
			{ name: 'Western African Time - Africa/Malabo', value: 'Africa/Malabo' },
			{ name: 'Western African Time - Africa/Ndjamena', value: 'Africa/Ndjamena' },
			{ name: 'Western African Time - Africa/Niamey', value: 'Africa/Niamey' },
			{ name: 'Western African Time - Africa/Porto-Novo', value: 'Africa/Porto-Novo' },
			{ name: 'Central European Time - Africa/Tunis', value: 'Africa/Tunis' },
			{ name: 'Western African Time - Africa/Windhoek', value: 'Africa/Windhoek' },
			{ name: 'Central European Time - Arctic/Longyearbyen', value: 'Arctic/Longyearbyen' },
			{ name: 'Central European Time - Atlantic/Jan_Mayen', value: 'Atlantic/Jan_Mayen' },
			{ name: 'Central European Time - CET', value: 'CET' },
			{ name: 'Central European Time - ECT', value: 'ECT' },
			{ name: 'GMT+01:00 - Etc/GMT-1', value: 'Etc/GMT-1' },
			{ name: 'Central European Time - Europe/Amsterdam', value: 'Europe/Amsterdam' },
			{ name: 'Central European Time - Europe/Andorra', value: 'Europe/Andorra' },
			{ name: 'Central European Time - Europe/Belgrade', value: 'Europe/Belgrade' },
			{ name: 'Central European Time - Europe/Berlin', value: 'Europe/Berlin' },
			{ name: 'Central European Time - Europe/Bratislava', value: 'Europe/Bratislava' },
			{ name: 'Central European Time - Europe/Brussels', value: 'Europe/Brussels' },
			{ name: 'Central European Time - Europe/Budapest', value: 'Europe/Budapest' },
			{ name: 'Central European Time - Europe/Busingen', value: 'Europe/Busingen' },
			{ name: 'Central European Time - Europe/Copenhagen', value: 'Europe/Copenhagen' },
			{ name: 'Central European Time - Europe/Gibraltar', value: 'Europe/Gibraltar' },
			{ name: 'Central European Time - Europe/Ljubljana', value: 'Europe/Ljubljana' },
			{ name: 'Central European Time - Europe/Luxembourg', value: 'Europe/Luxembourg' },
			{ name: 'Central European Time - Europe/Madrid', value: 'Europe/Madrid' },
			{ name: 'Central European Time - Europe/Malta', value: 'Europe/Malta' },
			{ name: 'Central European Time - Europe/Monaco', value: 'Europe/Monaco' },
			{ name: 'Central European Time - Europe/Oslo', value: 'Europe/Oslo' },
			{ name: 'Central European Time - Europe/Paris', value: 'Europe/Paris' },
			{ name: 'Central European Time - Europe/Podgorica', value: 'Europe/Podgorica' },
			{ name: 'Central European Time - Europe/Prague', value: 'Europe/Prague' },
			{ name: 'Central European Time - Europe/Rome', value: 'Europe/Rome' },
			{ name: 'Central European Time - Europe/San_Marino', value: 'Europe/San_Marino' },
			{ name: 'Central European Time - Europe/Sarajevo', value: 'Europe/Sarajevo' },
			{ name: 'Central European Time - Europe/Skopje', value: 'Europe/Skopje' },
			{ name: 'Central European Time - Europe/Stockholm', value: 'Europe/Stockholm' },
			{ name: 'Central European Time - Europe/Tirane', value: 'Europe/Tirane' },
			{ name: 'Central European Time - Europe/Vaduz', value: 'Europe/Vaduz' },
			{ name: 'Central European Time - Europe/Vatican', value: 'Europe/Vatican' },
			{ name: 'Central European Time - Europe/Vienna', value: 'Europe/Vienna' },
			{ name: 'Central European Time - Europe/Warsaw', value: 'Europe/Warsaw' },
			{ name: 'Central European Time - Europe/Zagreb', value: 'Europe/Zagreb' },
			{ name: 'Central European Time - Europe/Zurich', value: 'Europe/Zurich' },
			{ name: 'Middle Europe Time - MET', value: 'MET' },
			{ name: 'Central European Time - Poland', value: 'Poland' },
			{ name: 'Eastern European Time - ART', value: 'ART' },
			{ name: 'Central African Time - Africa/Blantyre', value: 'Africa/Blantyre' },
			{ name: 'Central African Time - Africa/Bujumbura', value: 'Africa/Bujumbura' },
			{ name: 'Eastern European Time - Africa/Cairo', value: 'Africa/Cairo' },
			{ name: 'Central African Time - Africa/Gaborone', value: 'Africa/Gaborone' },
			{ name: 'Central African Time - Africa/Harare', value: 'Africa/Harare' },
			{ name: 'South Africa Standard Time - Africa/Johannesburg', value: 'Africa/Johannesburg' },
			{ name: 'Central African Time - Africa/Kigali', value: 'Africa/Kigali' },
			{ name: 'Central African Time - Africa/Lubumbashi', value: 'Africa/Lubumbashi' },
			{ name: 'Central African Time - Africa/Lusaka', value: 'Africa/Lusaka' },
			{ name: 'Central African Time - Africa/Maputo', value: 'Africa/Maputo' },
			{ name: 'South Africa Standard Time - Africa/Maseru', value: 'Africa/Maseru' },
			{ name: 'South Africa Standard Time - Africa/Mbabane', value: 'Africa/Mbabane' },
			{ name: 'Eastern European Time - Africa/Tripoli', value: 'Africa/Tripoli' },
			{ name: 'Eastern European Time - Asia/Amman', value: 'Asia/Amman' },
			{ name: 'Eastern European Time - Asia/Beirut', value: 'Asia/Beirut' },
			{ name: 'Eastern European Time - Asia/Damascus', value: 'Asia/Damascus' },
			{ name: 'Eastern European Time - Asia/Gaza', value: 'Asia/Gaza' },
			{ name: 'Eastern European Time - Asia/Hebron', value: 'Asia/Hebron' },
			{ name: 'Eastern European Time - Asia/Istanbul', value: 'Asia/Istanbul' },
			{ name: 'Israel Standard Time - Asia/Jerusalem', value: 'Asia/Jerusalem' },
			{ name: 'Eastern European Time - Asia/Nicosia', value: 'Asia/Nicosia' },
			{ name: 'Israel Standard Time - Asia/Tel_Aviv', value: 'Asia/Tel_Aviv' },
			{ name: 'Central African Time - CAT', value: 'CAT' },
			{ name: 'Eastern European Time - EET', value: 'EET' },
			{ name: 'Eastern European Time - Egypt', value: 'Egypt' },
			{ name: 'GMT+02:00 - Etc/GMT-2', value: 'Etc/GMT-2' },
			{ name: 'Eastern European Time - Europe/Athens', value: 'Europe/Athens' },
			{ name: 'Eastern European Time - Europe/Bucharest', value: 'Europe/Bucharest' },
			{ name: 'Eastern European Time - Europe/Chisinau', value: 'Europe/Chisinau' },
			{ name: 'Eastern European Time - Europe/Helsinki', value: 'Europe/Helsinki' },
			{ name: 'Eastern European Time - Europe/Istanbul', value: 'Europe/Istanbul' },
			{ name: 'Eastern European Time - Europe/Kiev', value: 'Europe/Kiev' },
			{ name: 'Eastern European Time - Europe/Mariehamn', value: 'Europe/Mariehamn' },
			{ name: 'Eastern European Time - Europe/Nicosia', value: 'Europe/Nicosia' },
			{ name: 'Eastern European Time - Europe/Riga', value: 'Europe/Riga' },
			{ name: 'Eastern European Time - Europe/Sofia', value: 'Europe/Sofia' },
			{ name: 'Eastern European Time - Europe/Tallinn', value: 'Europe/Tallinn' },
			{ name: 'Eastern European Time - Europe/Tiraspol', value: 'Europe/Tiraspol' },
			{ name: 'Eastern European Time - Europe/Uzhgorod', value: 'Europe/Uzhgorod' },
			{ name: 'Eastern European Time - Europe/Vilnius', value: 'Europe/Vilnius' },
			{ name: 'Eastern European Time - Europe/Zaporozhye', value: 'Europe/Zaporozhye' },
			{ name: 'Israel Standard Time - Israel', value: 'Israel' },
			{ name: 'Eastern European Time - Libya', value: 'Libya' },
			{ name: 'Eastern European Time - Turkey', value: 'Turkey' },
			{ name: 'Eastern African Time - Africa/Addis_Ababa', value: 'Africa/Addis_Ababa' },
			{ name: 'Eastern African Time - Africa/Asmara', value: 'Africa/Asmara' },
			{ name: 'Eastern African Time - Africa/Asmera', value: 'Africa/Asmera' },
			{ name: 'Eastern African Time - Africa/Dar_es_Salaam', value: 'Africa/Dar_es_Salaam' },
			{ name: 'Eastern African Time - Africa/Djibouti', value: 'Africa/Djibouti' },
			{ name: 'Eastern African Time - Africa/Juba', value: 'Africa/Juba' },
			{ name: 'Eastern African Time - Africa/Kampala', value: 'Africa/Kampala' },
			{ name: 'Eastern African Time - Africa/Khartoum', value: 'Africa/Khartoum' },
			{ name: 'Eastern African Time - Africa/Mogadishu', value: 'Africa/Mogadishu' },
			{ name: 'Eastern African Time - Africa/Nairobi', value: 'Africa/Nairobi' },
			{ name: 'Syowa Time - Antarctica/Syowa', value: 'Antarctica/Syowa' },
			{ name: 'Arabia Standard Time - Asia/Aden', value: 'Asia/Aden' },
			{ name: 'Arabia Standard Time - Asia/Baghdad', value: 'Asia/Baghdad' },
			{ name: 'Arabia Standard Time - Asia/Bahrain', value: 'Asia/Bahrain' },
			{ name: 'Arabia Standard Time - Asia/Kuwait', value: 'Asia/Kuwait' },
			{ name: 'Arabia Standard Time - Asia/Qatar', value: 'Asia/Qatar' },
			{ name: 'Arabia Standard Time - Asia/Riyadh', value: 'Asia/Riyadh' },
			{ name: 'Eastern African Time - EAT', value: 'EAT' },
			{ name: 'GMT+03:00 - Etc/GMT-3', value: 'Etc/GMT-3' },
			{ name: 'Further-eastern European Time - Europe/Kaliningrad', value: 'Europe/Kaliningrad' },
			{ name: 'Further-eastern European Time - Europe/Minsk', value: 'Europe/Minsk' },
			{ name: 'Eastern African Time - Indian/Antananarivo', value: 'Indian/Antananarivo' },
			{ name: 'Eastern African Time - Indian/Comoro', value: 'Indian/Comoro' },
			{ name: 'Eastern African Time - Indian/Mayotte', value: 'Indian/Mayotte' },
			{ name: 'GMT+03:07 - Asia/Riyadh87', value: 'Asia/Riyadh87' },
			{ name: 'GMT+03:07 - Asia/Riyadh88', value: 'Asia/Riyadh88' },
			{ name: 'GMT+03:07 - Asia/Riyadh89', value: 'Asia/Riyadh89' },
			{ name: 'GMT+03:07 - Mideast/Riyadh87', value: 'Mideast/Riyadh87' },
			{ name: 'GMT+03:07 - Mideast/Riyadh88', value: 'Mideast/Riyadh88' },
			{ name: 'GMT+03:07 - Mideast/Riyadh89', value: 'Mideast/Riyadh89' },
			{ name: 'Iran Standard Time - Asia/Tehran', value: 'Asia/Tehran' },
			{ name: 'Iran Standard Time - Iran', value: 'Iran' },
			{ name: 'Azerbaijan Time - Asia/Baku', value: 'Asia/Baku' },
			{ name: 'Gulf Standard Time - Asia/Dubai', value: 'Asia/Dubai' },
			{ name: 'Gulf Standard Time - Asia/Muscat', value: 'Asia/Muscat' },
			{ name: 'Georgia Time - Asia/Tbilisi', value: 'Asia/Tbilisi' },
			{ name: 'Armenia Time - Asia/Yerevan', value: 'Asia/Yerevan' },
			{ name: 'GMT+04:00 - Etc/GMT-4', value: 'Etc/GMT-4' },
			{ name: 'Moscow Standard Time - Europe/Moscow', value: 'Europe/Moscow' },
			{ name: 'Samara Time - Europe/Samara', value: 'Europe/Samara' },
			{ name: 'Moscow Standard Time - Europe/Simferopol', value: 'Europe/Simferopol' },
			{ name: 'Volgograd Time - Europe/Volgograd', value: 'Europe/Volgograd' },
			{ name: 'Seychelles Time - Indian/Mahe', value: 'Indian/Mahe' },
			{ name: 'Mauritius Time - Indian/Mauritius', value: 'Indian/Mauritius' },
			{ name: 'Reunion Time - Indian/Reunion', value: 'Indian/Reunion' },
			{ name: 'Armenia Time - NET', value: 'NET' },
			{ name: 'Moscow Standard Time - W-SU', value: 'W-SU' },
			{ name: 'Afghanistan Time - Asia/Kabul', value: 'Asia/Kabul' },
			{ name: 'Mawson Time - Antarctica/Mawson', value: 'Antarctica/Mawson' },
			{ name: 'Aqtau Time - Asia/Aqtau', value: 'Asia/Aqtau' },
			{ name: 'Aqtobe Time - Asia/Aqtobe', value: 'Asia/Aqtobe' },
			{ name: 'Turkmenistan Time - Asia/Ashgabat', value: 'Asia/Ashgabat' },
			{ name: 'Turkmenistan Time - Asia/Ashkhabad', value: 'Asia/Ashkhabad' },
			{ name: 'Tajikistan Time - Asia/Dushanbe', value: 'Asia/Dushanbe' },
			{ name: 'Pakistan Time - Asia/Karachi', value: 'Asia/Karachi' },
			{ name: 'Oral Time - Asia/Oral', value: 'Asia/Oral' },
			{ name: 'Uzbekistan Time - Asia/Samarkand', value: 'Asia/Samarkand' },
			{ name: 'Uzbekistan Time - Asia/Tashkent', value: 'Asia/Tashkent' },
			{ name: 'GMT+05:00 - Etc/GMT-5', value: 'Etc/GMT-5' },
			{ name: 'French Southern & Antarctic Lands Time - Indian/Kerguelen', value: 'Indian/Kerguelen' },
			{ name: 'Maldives Time - Indian/Maldives', value: 'Indian/Maldives' },
			{ name: 'Pakistan Time - PLT', value: 'PLT' },
			{ name: 'India Standard Time - Asia/Calcutta', value: 'Asia/Calcutta' },
			{ name: 'India Standard Time - Asia/Colombo', value: 'Asia/Colombo' },
			{ name: 'India Standard Time - Asia/Kolkata', value: 'Asia/Kolkata' },
			{ name: 'India Standard Time - IST', value: 'IST' },
			{ name: 'Nepal Time - Asia/Kathmandu', value: 'Asia/Kathmandu' },
			{ name: 'Nepal Time - Asia/Katmandu', value: 'Asia/Katmandu' },
			{ name: 'Vostok Time - Antarctica/Vostok', value: 'Antarctica/Vostok' },
			{ name: 'Alma-Ata Time - Asia/Almaty', value: 'Asia/Almaty' },
			{ name: 'Kirgizstan Time - Asia/Bishkek', value: 'Asia/Bishkek' },
			{ name: 'Bangladesh Time - Asia/Dacca', value: 'Asia/Dacca' },
			{ name: 'Bangladesh Time - Asia/Dhaka', value: 'Asia/Dhaka' },
			{ name: 'Qyzylorda Time - Asia/Qyzylorda', value: 'Asia/Qyzylorda' },
			{ name: 'Bhutan Time - Asia/Thimbu', value: 'Asia/Thimbu' },
			{ name: 'Bhutan Time - Asia/Thimphu', value: 'Asia/Thimphu' },
			{ name: 'Yekaterinburg Time - Asia/Yekaterinburg', value: 'Asia/Yekaterinburg' },
			{ name: 'Bangladesh Time - BST', value: 'BST' },
			{ name: 'GMT+06:00 - Etc/GMT-6', value: 'Etc/GMT-6' },
			{ name: 'Indian Ocean Territory Time - Indian/Chagos', value: 'Indian/Chagos' },
			{ name: 'Myanmar Time - Asia/Rangoon', value: 'Asia/Rangoon' },
			{ name: 'Cocos Islands Time - Indian/Cocos', value: 'Indian/Cocos' },
			{ name: 'Davis Time - Antarctica/Davis', value: 'Antarctica/Davis' },
			{ name: 'Indochina Time - Asia/Bangkok', value: 'Asia/Bangkok' },
			{ name: 'Indochina Time - Asia/Ho_Chi_Minh', value: 'Asia/Ho_Chi_Minh' },
			{ name: 'Hovd Time - Asia/Hovd', value: 'Asia/Hovd' },
			{ name: 'West Indonesia Time - Asia/Jakarta', value: 'Asia/Jakarta' },
			{ name: 'Novosibirsk Time - Asia/Novokuznetsk', value: 'Asia/Novokuznetsk' },
			{ name: 'Novosibirsk Time - Asia/Novosibirsk', value: 'Asia/Novosibirsk' },
			{ name: 'Omsk Time - Asia/Omsk', value: 'Asia/Omsk' },
			{ name: 'Indochina Time - Asia/Phnom_Penh', value: 'Asia/Phnom_Penh' },
			{ name: 'West Indonesia Time - Asia/Pontianak', value: 'Asia/Pontianak' },
			{ name: 'Indochina Time - Asia/Saigon', value: 'Asia/Saigon' },
			{ name: 'Indochina Time - Asia/Vientiane', value: 'Asia/Vientiane' },
			{ name: 'GMT+07:00 - Etc/GMT-7', value: 'Etc/GMT-7' },
			{ name: 'Christmas Island Time - Indian/Christmas', value: 'Indian/Christmas' },
			{ name: 'Indochina Time - VST', value: 'VST' },
			{ name: 'Western Standard Time (Australia) - Antarctica/Casey', value: 'Antarctica/Casey' },
			{ name: 'Brunei Time - Asia/Brunei', value: 'Asia/Brunei' },
			{ name: 'Choibalsan Time - Asia/Choibalsan', value: 'Asia/Choibalsan' },
			{ name: 'China Standard Time - Asia/Chongqing', value: 'Asia/Chongqing' },
			{ name: 'China Standard Time - Asia/Chungking', value: 'Asia/Chungking' },
			{ name: 'China Standard Time - Asia/Harbin', value: 'Asia/Harbin' },
			{ name: 'Hong Kong Time - Asia/Hong_Kong', value: 'Asia/Hong_Kong' },
			{ name: 'China Standard Time - Asia/Kashgar', value: 'Asia/Kashgar' },
			{ name: 'Krasnoyarsk Time - Asia/Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
			{ name: 'Malaysia Time - Asia/Kuala_Lumpur', value: 'Asia/Kuala_Lumpur' },
			{ name: 'Malaysia Time - Asia/Kuching', value: 'Asia/Kuching' },
			{ name: 'China Standard Time - Asia/Macao', value: 'Asia/Macao' },
			{ name: 'China Standard Time - Asia/Macau', value: 'Asia/Macau' },
			{ name: 'Central Indonesia Time - Asia/Makassar', value: 'Asia/Makassar' },
			{ name: 'Philippines Time - Asia/Manila', value: 'Asia/Manila' },
			{ name: 'China Standard Time - Asia/Shanghai', value: 'Asia/Shanghai' },
			{ name: 'Singapore Time - Asia/Singapore', value: 'Asia/Singapore' },
			{ name: 'China Standard Time - Asia/Taipei', value: 'Asia/Taipei' },
			{ name: 'Central Indonesia Time - Asia/Ujung_Pandang', value: 'Asia/Ujung_Pandang' },
			{ name: 'Ulaanbaatar Time - Asia/Ulaanbaatar', value: 'Asia/Ulaanbaatar' },
			{ name: 'Ulaanbaatar Time - Asia/Ulan_Bator', value: 'Asia/Ulan_Bator' },
			{ name: 'China Standard Time - Asia/Urumqi', value: 'Asia/Urumqi' },
			{ name: 'Western Standard Time (Australia) - Australia/Perth', value: 'Australia/Perth' },
			{ name: 'Western Standard Time (Australia) - Australia/West', value: 'Australia/West' },
			{ name: 'China Standard Time - CTT', value: 'CTT' },
			{ name: 'GMT+08:00 - Etc/GMT-8', value: 'Etc/GMT-8' },
			{ name: 'Hong Kong Time - Hongkong', value: 'Hongkong' },
			{ name: 'China Standard Time - PRC', value: 'PRC' },
			{ name: 'Singapore Time - Singapore', value: 'Singapore' },
			{ name: 'Central Western Standard Time (Australia) - Australia/Eucla', value: 'Australia/Eucla' },
			{ name: 'Timor-Leste Time - Asia/Dili', value: 'Asia/Dili' },
			{ name: 'Irkutsk Time - Asia/Irkutsk', value: 'Asia/Irkutsk' },
			{ name: 'East Indonesia Time - Asia/Jayapura', value: 'Asia/Jayapura' },
			{ name: 'Korea Standard Time - Asia/Pyongyang', value: 'Asia/Pyongyang' },
			{ name: 'Korea Standard Time - Asia/Seoul', value: 'Asia/Seoul' },
			{ name: 'Japan Standard Time - Asia/Tokyo', value: 'Asia/Tokyo' },
			{ name: 'GMT+09:00 - Etc/GMT-9', value: 'Etc/GMT-9' },
			{ name: 'Japan Standard Time - JST', value: 'JST' },
			{ name: 'Japan Standard Time - Japan', value: 'Japan' },
			{ name: 'Palau Time - Pacific/Palau', value: 'Pacific/Palau' },
			{ name: 'Korea Standard Time - ROK', value: 'ROK' },
			{ name: 'Central Standard Time (Northern Territory) - ACT', value: 'ACT' },
			{ name: 'Central Standard Time (South Australia) - Australia/Adelaide', value: 'Australia/Adelaide' },
			{ name: 'Central Standard Time (South Australia/New South Wales) - Australia/Broken_Hill', value: 'Australia/Broken_Hill' },
			{ name: 'Central Standard Time (Northern Territory) - Australia/Darwin', value: 'Australia/Darwin' },
			{ name: 'Central Standard Time (Northern Territory) - Australia/North', value: 'Australia/North' },
			{ name: 'Central Standard Time (South Australia) - Australia/South', value: 'Australia/South' },
			{ name: 'Central Standard Time (South Australia/New South Wales) - Australia/Yancowinna', value: 'Australia/Yancowinna' },
			{ name: 'Eastern Standard Time (New South Wales) - AET', value: 'AET' },
			{ name: 'Dumont-d\'Urville Time - Antarctica/DumontDUrville', value: 'Antarctica/DumontDUrville' },
			{ name: 'Khandyga Time - Asia/Khandyga', value: 'Asia/Khandyga' },
			{ name: 'Yakutsk Time - Asia/Yakutsk', value: 'Asia/Yakutsk' },
			{ name: 'Eastern Standard Time (New South Wales) - Australia/ACT', value: 'Australia/ACT' },
			{ name: 'Eastern Standard Time (Queensland) - Australia/Brisbane', value: 'Australia/Brisbane' },
			{ name: 'Eastern Standard Time (New South Wales) - Australia/Canberra', value: 'Australia/Canberra' },
			{ name: 'Eastern Standard Time (New South Wales) - Australia/Currie', value: 'Australia/Currie' },
			{ name: 'Eastern Standard Time (Tasmania) - Australia/Hobart', value: 'Australia/Hobart' },
			{ name: 'Eastern Standard Time (Queensland) - Australia/Lindeman', value: 'Australia/Lindeman' },
			{ name: 'Eastern Standard Time (Victoria) - Australia/Melbourne', value: 'Australia/Melbourne' },
			{ name: 'Eastern Standard Time (New South Wales) - Australia/NSW', value: 'Australia/NSW' },
			{ name: 'Eastern Standard Time (Queensland) - Australia/Queensland', value: 'Australia/Queensland' },
			{ name: 'Eastern Standard Time (New South Wales) - Australia/Sydney', value: 'Australia/Sydney' },
			{ name: 'Eastern Standard Time (Tasmania) - Australia/Tasmania', value: 'Australia/Tasmania' },
			{ name: 'Eastern Standard Time (Victoria) - Australia/Victoria', value: 'Australia/Victoria' },
			{ name: 'GMT+10:00 - Etc/GMT-10', value: 'Etc/GMT-10' },
			{ name: 'Chuuk Time - Pacific/Chuuk', value: 'Pacific/Chuuk' },
			{ name: 'Chamorro Standard Time - Pacific/Guam', value: 'Pacific/Guam' },
			{ name: 'Papua New Guinea Time - Pacific/Port_Moresby', value: 'Pacific/Port_Moresby' },
			{ name: 'Chamorro Standard Time - Pacific/Saipan', value: 'Pacific/Saipan' },
			{ name: 'Chuuk Time - Pacific/Truk', value: 'Pacific/Truk' },
			{ name: 'Chuuk Time - Pacific/Yap', value: 'Pacific/Yap' },
			{ name: 'Lord Howe Standard Time - Australia/LHI', value: 'Australia/LHI' },
			{ name: 'Lord Howe Standard Time - Australia/Lord_Howe', value: 'Australia/Lord_Howe' },
			{ name: 'Macquarie Island Time - Antarctica/Macquarie', value: 'Antarctica/Macquarie' },
			{ name: 'Sakhalin Time - Asia/Sakhalin', value: 'Asia/Sakhalin' },
			{ name: 'Ust-Nera Time - Asia/Ust-Nera', value: 'Asia/Ust-Nera' },
			{ name: 'Vladivostok Time - Asia/Vladivostok', value: 'Asia/Vladivostok' },
			{ name: 'GMT+11:00 - Etc/GMT-11', value: 'Etc/GMT-11' },
			{ name: 'Vanuatu Time - Pacific/Efate', value: 'Pacific/Efate' },
			{ name: 'Solomon Is. Time - Pacific/Guadalcanal', value: 'Pacific/Guadalcanal' },
			{ name: 'Kosrae Time - Pacific/Kosrae', value: 'Pacific/Kosrae' },
			{ name: 'New Caledonia Time - Pacific/Noumea', value: 'Pacific/Noumea' },
			{ name: 'Pohnpei Time - Pacific/Pohnpei', value: 'Pacific/Pohnpei' },
			{ name: 'Pohnpei Time - Pacific/Ponape', value: 'Pacific/Ponape' },
			{ name: 'Solomon Is. Time - SST', value: 'SST' },
			{ name: 'Norfolk Time - Pacific/Norfolk', value: 'Pacific/Norfolk' },
			{ name: 'New Zealand Standard Time - Antarctica/McMurdo', value: 'Antarctica/McMurdo' },
			{ name: 'New Zealand Standard Time - Antarctica/South_Pole', value: 'Antarctica/South_Pole' },
			{ name: 'Anadyr Time - Asia/Anadyr', value: 'Asia/Anadyr' },
			{ name: 'Petropavlovsk-Kamchatski Time - Asia/Kamchatka', value: 'Asia/Kamchatka' },
			{ name: 'Magadan Time - Asia/Magadan', value: 'Asia/Magadan' },
			{ name: 'GMT+12:00 - Etc/GMT-12', value: 'Etc/GMT-12' },
			{ name: 'Marshall Islands Time - Kwajalein', value: 'Kwajalein' },
			{ name: 'New Zealand Standard Time - NST', value: 'NST' },
			{ name: 'New Zealand Standard Time - NZ', value: 'NZ' },
			{ name: 'New Zealand Standard Time - Pacific/Auckland', value: 'Pacific/Auckland' },
			{ name: 'Fiji Time - Pacific/Fiji', value: 'Pacific/Fiji' },
			{ name: 'Tuvalu Time - Pacific/Funafuti', value: 'Pacific/Funafuti' },
			{ name: 'Marshall Islands Time - Pacific/Kwajalein', value: 'Pacific/Kwajalein' },
			{ name: 'Marshall Islands Time - Pacific/Majuro', value: 'Pacific/Majuro' },
			{ name: 'Nauru Time - Pacific/Nauru', value: 'Pacific/Nauru' },
			{ name: 'Gilbert Is. Time - Pacific/Tarawa', value: 'Pacific/Tarawa' },
			{ name: 'Wake Time - Pacific/Wake', value: 'Pacific/Wake' },
			{ name: 'Wallis & Futuna Time - Pacific/Wallis', value: 'Pacific/Wallis' },
			{ name: 'Chatham Standard Time - NZ-CHAT', value: 'NZ-CHAT' },
			{ name: 'Chatham Standard Time - Pacific/Chatham', value: 'Pacific/Chatham' },
			{ name: 'GMT+13:00 - Etc/GMT-13', value: 'Etc/GMT-13' },
			{ name: 'West Samoa Time - MIT', value: 'MIT' },
			{ name: 'West Samoa Time - Pacific/Apia', value: 'Pacific/Apia' },
			{ name: 'Phoenix Is. Time - Pacific/Enderbury', value: 'Pacific/Enderbury' },
			{ name: 'Tokelau Time - Pacific/Fakaofo', value: 'Pacific/Fakaofo' },
			{ name: 'Tonga Time - Pacific/Tongatapu', value: 'Pacific/Tongatapu' },
			{ name: 'GMT+14:00 - Etc/GMT-14', value: 'Etc/GMT-14' },
			{ name: 'Line Is. Time - Pacific/Kiritimati', value: 'Pacific/Kiritimati' },
		]
};
