// main.page.tsx

import React, { Component } from "react";
import { Layout, Menu, Icon, Tabs, Result } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./main.page.css";

import { ApplicationState } from "../../store";

const { Header, Sider, Content } = Layout;
const { TabPane } = Tabs;

type Props = {};

type State = {};

class MainPageComponent extends Component<Props, State> {
  componentDidMount() {}

  render() {
    return (
      <Content
        style={{
          margin: "24px 16px",
          padding: 24,
          background: "#fff",
          minHeight: 280
        }}
      >
        <Result
          icon={<i className="fas fa-hard-hat quran-coming-soon-icon"></i>}
          title="Quran"
          subTitle="Coming soon!!"
        />
      </Content>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({}, dispatch);
};

export const MainPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainPageComponent);
