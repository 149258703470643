// signin.page.tsx

import React, { Component } from "react";
import { Layout, Button, Alert, Typography, Icon } from "antd";
import { Input, SubmitButton } from "formik-antd";
import { Formik, Form, ErrorMessage } from "formik";
import * as _ from "lodash";
import Superagent from "superagent";

import "./signin.page.css";

import { createSimpleField } from "../../components/create-simple.form";
import { SigninStep1Schema, SigninStep2Schema } from "../validators/auth.validator";
import { AuthService } from "../services/auth.service";
import { AuthContext } from "../components/with-auth";
import { withRouter, RouteProps, RouteComponentProps } from "react-router-dom";
import { NumericInput } from "../../components/inputs/numeric-input";

const { Header, Sider, Content } = Layout;
const { Title } = Typography;

type Props = RouteComponentProps & {};

type State = {
  initValues: any;
  error?: string;
  state?: {
    token: string;
  };
};

class SigninPageComponent extends Component<Props, State> {
  static contextType = AuthContext;

  private formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };

  private innerRef: any;

  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      initValues: {
        username: "",
        error: null,
        state: {
          token: "kjhkhkjh"
        }
      }
    };
  }

  onSubmitStep1(values: any) {
    const { history } = this.props;
    AuthService.signinStep1({ username: values.username, password: values.password })
      .then((response: Superagent.Response) => {
        console.log(response);
        const token = JSON.parse(response?.text).data.token;
        this.setState({ state: { token: token } });
        /* const { setAuth, checkAuth } = this.context;
        checkAuth();
        // setAuth(true);
        history.push("/"); */
      })
      .catch((error: any) => {
        console.log(JSON.stringify(error.response.text));
        const res = error.response?.text
          ? JSON.parse(error.response?.text)
          : { message: "Internal error" };
        this.setState({ error: res.message });
      });
  }

  onSubmitStep2(state: string, token: string) {
    const { history } = this.props;
    AuthService.signinStep2({ state: state, token: token })
      .then((response: Superagent.Response) => {
        console.log(response);
        const { setAuth, checkAuth } = this.context;
        checkAuth();
        // setAuth(true);
        history.push("/");
      })
      .catch((error: any) => {
        console.log(JSON.stringify(error.response.text));
        const res = error.response?.text
          ? JSON.parse(error.response?.text)
          : { message: "Internal error" };
        this.setState({ error: res.message });
      });
  }

  componentDidMount() {}

  errorRender() {
    const { error } = this.state;
    if (error) {
      return (
        <Alert
          style={{ margin: "20px 0" }}
          message={error}
          type="error"
          showIcon
        />
      );
    } else {
      return null;
    }
  }

  step1Render() {
    const { initValues } = this.state;
    return (
      <Formik
        innerRef={this.innerRef}
        onSubmit={() => {
          console.log("onSubmit........");
        }}
        initialValues={initValues}
        validationSchema={SigninStep1Schema}
        enableReinitialize={true}
        render={({ errors, touched, values, handleReset }) => {
          // this.updateState(values);
          return (
            <Form {...this.formItemLayout}>
              {this.errorRender()}
              <div>
                {createSimpleField(
                  "username",
                  "",
                  values,
                  errors,
                  touched,
                  <Input
                    size="large"
                    name="username"
                    prefix={
                      <i
                        className="fas fa-user"
                        style={{ color: "#939393" }}
                      ></i>
                    }
                    placeholder="Enter a username"
                  />
                )}
              </div>
              <div>
                {createSimpleField(
                  "password",
                  "",
                  values,
                  errors,
                  touched,
                  <Input.Password
                    size="large"
                    name="password"
                    prefix={
                      <i
                        className="fas fa-key"
                        style={{ color: "#939393" }}
                      ></i>
                    }
                    placeholder="Enter a password"
                  />
                )}
              </div>
              {/* <Button
            type="primary"
            style={{ width: "100%" }}
            size={"large"}
            disabled={!AuthSchema.isValidSync(values)}
            onClick={() => {
              const v = Object.assign({}, values);
              this.onSubmit(v);
              values.password = "";
            }}
            onKeyPress={event => {
              console.log("onSubmit........");
              if (event.key === "Enter") {
                // do some stuff
              }
            }}
          >
            Sign in
          </Button> */}
              <SubmitButton
                style={{ width: "100%" }}
                size={"large"}
                disabled={!SigninStep1Schema.isValidSync(values)}
                onClick={() => {
                  const v = Object.assign({}, values);
                  this.setState({ ...this.state, error: undefined });
                  this.onSubmitStep1(v);
                  values.password = "";
                }}
              >
                Sign in
              </SubmitButton>
            </Form>
          );
        }}
      />
    );
  }

  step2Render() {
    const { state } = this.state;
    return (
      <Formik
        innerRef={this.innerRef}
        onSubmit={() => {
          console.log("onSubmit........");
        }}
        initialValues={{ token: "" }}
        validationSchema={SigninStep2Schema}
        enableReinitialize={true}
        render={({ errors, touched, values, handleReset }) => {
          // this.updateState(values);
          return (
            <Form {...this.formItemLayout}>
              {this.errorRender()}
              <div>
                {createSimpleField(
                  "token",
                  "",
                  values,
                  errors,
                  touched,
                  <NumericInput
                    size="large"
                    name="token"
                    prefix={
                      <i
                        className="fas fa-lock"
                        style={{ color: "#939393" }}
                      ></i>
                    }
                    placeholder="Enter a OTP Token"
                    maxLength={6}
                  />
                )}
              </div>
              <SubmitButton
                style={{ width: "100%" }}
                size={"large"}
                disabled={!SigninStep2Schema.isValidSync(values)}
                onClick={() => {
                  const v = Object.assign({}, values);
                  this.setState({ ...this.state, error: undefined });
                  this.onSubmitStep2(this.state.state?.token || "", v.token);
                  values.token = "";
                }}
              >
                Sign in
              </SubmitButton>
            </Form>
          );
        }}
      />
    );
  }

  render() {
    const { state } = this.state;
    const content = (state?.token) ? this.step2Render() : this.step1Render();
    return (
      <div className="auth-layout">
        <div className="outer">
          <div className="inner">
            <div className="inner-header">
              <img src={require("../../../assets/muslimtoolbox_logo.png")} />
            </div>
            <div className="content">
              <Title level={2} style={{ textAlign: "center" }}>
                Sign in
              </Title>
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SigninPageComponent);
