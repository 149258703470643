// prayertimes-table.component.tsx

import React, { Component } from "react";
import { Layout, Row, Col, Modal, Button, Result, Icon } from "antd";
import { forkJoin } from "rxjs";
import * as _ from "lodash";
import moment from "moment";
import * as momentHijri from "moment-hijri";
import * as momentTz from "moment-timezone";
import * as Yup from "yup";

import "./prayertimes-table.component.css";
import { Adjustment } from "../models/adjustment.model";
import { Prayertimes } from "./prayertimes";
import { AdjustmentSchema } from "../validators/adjustment.validator";
import { LocationSchema } from "../validators/location.validator";
import { Location } from "../models/location.model";

const { Content } = Layout;

type AllPrintableDate = {
  gregorian: string;
  islamic: string;
};

type AllPrayertimes = {
  fajrTime: string;
  sahurTime: string;
  sunriseTime: string;
  dhuhrTime: string;
  asrTime: string;
  sunsetTime: string;
  maghribTime: string;
  iftarTime: string;
  ishaTime: string;
  midnightTime: string;
};

type Props = {
  location?: Location;
  adjustment?: Adjustment;
};

type State = {
  date: Date;
};

export class PrayertimesTableComponent extends Component<Props, State> {
  private methodList = [
    "Jafari",
    "Karachi",
    "ISNA",
    "MWL",
    "Makkah",
    "Egypt",
    "Tehran",
    "UOIF"
  ];
  private angleBasedList = ["None", "NightMiddle", "OneSeventh", "AngleBased"];
  private asrMethodList = ["Standard", "Hanafi"];
  private midnightMethodList = ["Standard", "Jafari"];

  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      date: new Date()
    };
  }

  componentDidMount() {}

  onClickUpDate() {
    const date = new Date(this.state.date);
    date.setDate(date.getDate() + 1);
    this.setState({ date });
  }

  onClickDownDate() {
    const date = new Date(this.state.date);
    date.setDate(date.getDate() - 1);
    this.setState({ date });
  }

  getPrintableDate(): AllPrintableDate {
    const { adjustment } = this.props;
    const { date } = this.state;

    const hijriAdjustment = adjustment ? adjustment.hijri : 0;
    moment.locale("en-USA");
    const dateWithHijri = new Date().setDate(date.getDate() + hijriAdjustment);
    return {
      gregorian: moment(date).format("DD/MM/YYYY"),
      islamic: momentHijri.default(dateWithHijri).format("iDD/iMM/iYYYY")
    };
  }

  calculatePrayertimes(): AllPrayertimes {
    // console.log('Calculate Prayertimes.......... timezone = %s', this.timezone);
    const location = this.props.location as Location;
    const adjustment = this.props.adjustment as Adjustment;
    const { date } = this.state;
    const prayertimes = new Prayertimes();

    if (adjustment.method.type >= 0) {
      prayertimes.setMethod(this.methodList[adjustment.method.type]);
    } else if (adjustment.method.type === -1) {
      prayertimes.adjust({
        fajr: adjustment.method.parameters?.fajrAngle,
        isha: adjustment.method.parameters?.ishaAngle
      });
    } else if (adjustment.method.type === -2) {
      prayertimes.adjust({
        fajr: adjustment.method.parameters?.fajrAngle,
        isha: adjustment.method.parameters?.ishaTime + " mins"
      });
    }

    prayertimes.adjust({
      highLats: this.angleBasedList[adjustment.angleBasedMethod],
      asr: this.asrMethodList[adjustment.asrMethod],
      midnight: this.midnightMethodList[adjustment.midnightMethod]
    });
    /*prayertimes.tune({
      imsak: adjustment.manual.sahur,
      fajr: adjustment.manual.fajr,
      sunrise: adjustment.manual.sunrise,
      dhuhr: adjustment.manual.dhuhr,
      asr: adjustment.manual.asr,
      sunset: adjustment.manual.sunset,
      maghrib: adjustment.manual.maghrib,
      isha: adjustment.manual.isha,
      midnight: adjustment.manual.midnight
    });*/
    prayertimes.tune({
      sunrise: adjustment.manual.sunrise,
      sunset: adjustment.manual.sunset,
      midnight: adjustment.manual.midnight
    });

    /*const timezone = -momentTz.tz
      .zone(location.timezone)
      .utcOffset(1388563200000);*/

    const utcOffset = date.getTime()
    const timezone = -momentTz.tz
      .zone(location.timezone)
      .utcOffset(utcOffset);
    
    // if (this.adjustment['timezone']) {
    //    timezone = -momentTz.tz.zone(this.adjustment['timezone']).offset(1388563200000);
    // }

    let latitude = 0;
    let longitude = 0;
    if (adjustment.location.type === 0) {
      latitude = adjustment.location.parameters?.latitude || 0;
      longitude = adjustment.location.parameters?.longitude || 0;
    } else {
      latitude = location.latitude;
      longitude = location.longitude;
    }

    const times = prayertimes.getTimes(
      date,
      [latitude, longitude],
      timezone / 60,
      null,
      null
    );
    const list = [
      "Fajr",
      "Sunrise",
      "Dhuhr",
      "Asr",
      "Sunset",
      "Maghrib",
      "Isha",
      "Midnight"
    ];

    // console.log('Times = %s', JSON.stringify(times));

    const fajr = moment(times[list[0].toLowerCase()], "HH:mm")
      .add(adjustment.manual.fajr, "m")
      .format("HH:mm");
    const sahur = moment(times[list[0].toLowerCase()], "HH:mm")
      .add(adjustment.manual.fajr + adjustment.manual.sahur, "m")
      .format("HH:mm");
    const sunrise = times[list[1].toLowerCase()];
    const dhuhr = moment(times[list[2].toLowerCase()], "HH:mm")
      .add(adjustment.manual.dhuhr, "m")
      .format("HH:mm");
    const asr = moment(times[list[3].toLowerCase()], "HH:mm")
      .add(adjustment.manual.asr, "m")
      .format("HH:mm");
    const sunset = times[list[4].toLowerCase()];
    const maghrib = moment(times[list[5].toLowerCase()], "HH:mm")
      .add(adjustment.manual.sunset + adjustment.manual.maghrib, "m")
      .format("HH:mm");
    const iftar = moment(times[list[5].toLowerCase()], "HH:mm")
      .add(adjustment.manual.sunset + adjustment.manual.maghrib + adjustment.manual.iftar, "m")
      .format("HH:mm");
    const isha = moment(times[list[6].toLowerCase()], "HH:mm")
      .add(adjustment.manual.isha, "m")
      .format("HH:mm");
    const midnight = times[list[7].toLowerCase()];

    return {
      fajrTime: fajr,
      sahurTime: sahur,
      sunriseTime: sunrise,
      dhuhrTime: dhuhr,
      asrTime: asr,
      sunsetTime: sunset,
      maghribTime: maghrib,
      iftarTime: iftar,
      ishaTime: isha,
      midnightTime: midnight
    };
  }

  renderError() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <Result
          style={{ flex: 1 }}
          icon={
            <Icon
              style={{ fontSize: "12em" }}
              type="calendar"
              theme="twoTone"
            />
          }
          title="Please settings adjustment and main location..."
        />
      </div>
    );
  }

  render() {
    const { location, adjustment } = this.props;
    const { date } = this.state;
    const { gregorian, islamic } = this.getPrintableDate();
    let content = null;
    if (
      !(
        LocationSchema.isValidSync(location) &&
        AdjustmentSchema.isValidSync(adjustment)
      )
    ) {
      content = this.renderError();
    } else {
      const {
        fajrTime,
        sahurTime,
        sunriseTime,
        dhuhrTime,
        asrTime,
        sunsetTime,
        maghribTime,
        iftarTime,
        ishaTime,
        midnightTime
      } = this.calculatePrayertimes();
      content = (
        <div>
          <div
            style={{ fontSize: "2em", textAlign: "center", fontWeight: "bold" }}
          >
            {location?.city}
          </div>
          <div style={{ fontSize: "1.6em", textAlign: "center" }}>
            {location?.country}
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              marginTop: "30px",
              padding: "15px"
            }}
          >
            <Row>
              <Col span={16} style={{ fontSize: "1.6em" }}>
                <div style={{ fontWeight: "bold", marginBottom: "20px" }}>
                  Prayer
                </div>
                <div>Fajr</div>
                <div>Sahur</div>
                <div>Sunrise</div>
                <div>Dhuhr</div>
                <div>Asr</div>
                <div>Sunset</div>
                <div>Maghrib</div>
                <div>Iftar</div>
                <div>Isha</div>
                <div>Midnight</div>
              </Col>
              <Col span={8} style={{ fontSize: "1.6em", textAlign: "end" }}>
                <div style={{ fontWeight: "bold", marginBottom: "20px" }}>
                  Time
                </div>
                <div>{fajrTime}</div>
                <div>{sahurTime}</div>
                <div>{sunriseTime}</div>
                <div>{dhuhrTime}</div>
                <div>{asrTime}</div>
                <div>{sunsetTime}</div>
                <div>{maghribTime}</div>
                <div>{iftarTime}</div>
                <div>{ishaTime}</div>
                <div>{midnightTime}</div>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
    return (
      <Content
        style={{
          margin: "24px 16px"
        }}
      >
        <Row style={{ backgroundColor: "#1d411d", color: "#FFFFFF" }}>
          <Col span={3}>
            <div
              style={{
                margin: "20px",
                padding: "0px 10px"
              }}
            >
              <a
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  fontSize: "2.8em"
                }}
                onClick={this.onClickDownDate.bind(this)}
              >
                <i
                  className="fa fa-chevron-left"
                  style={{ color: "#FFFFFF", padding: "14px;" }}
                ></i>
              </a>
            </div>
          </Col>
          <Col span={18}>
            <div style={{ fontSize: "2em", textAlign: "center" }}>
              {gregorian}
            </div>
            <div style={{ fontSize: "1.8em", textAlign: "center" }}>
              {islamic}
            </div>
          </Col>
          <Col span={3}>
            <div
              style={{
                margin: "20px",
                padding: "0px 10px"
              }}
            >
              <a
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  fontSize: "2.8em"
                }}
                onClick={this.onClickUpDate.bind(this)}
              >
                <i
                  className="fa fa-chevron-right"
                  style={{ color: "#FFFFFF", padding: "14px;" }}
                ></i>
              </a>
            </div>
          </Col>
        </Row>
        <div
          style={{
            minHeight: "560px"
          }}
        >
          {content}
        </div>
      </Content>
    );
  }
}
