// manual-adjustments-prayertimes.form.tsx

import React, { Component } from "react";
import { Divider } from "antd";
import { Slider } from "formik-antd";
import * as _ from "lodash";
import { createSimpleField } from "../../../../components/create-simple.form";

type Props = {
  errors: any;
  touched: any;
  values: any;
};

type State = {};

export class ManualAdjustmentsFormComponent extends Component<Props, State> {
  private static FIELDS_TIMES = [
    { name: "manual.sunrise", label: "Sunrise" },
    { name: "manual.sunset", label: "Sunset" },
    { name: "manual.midnight", label: "Midnight" }
  ];

  private static FIELDS_PRAYER_TIMES = [
    { name: "manual.fajr", label: "Fajr" },
    { name: "manual.dhuhr", label: "Dhuhr" },
    { name: "manual.asr", label: "Asr" },
    { name: "manual.maghrib", label: "Maghrib" },
    { name: "manual.isha", label: "Isha" }
  ];

  private static FIELDS_FASTING_TIMES = [
    { name: "manual.sahur", label: "Sahur" },
    { name: "manual.iftar", label: "Iftar" }
  ];

  constructor(props: Props, state: State) {
    super(props, state);
  }

  createSlider(name: string, label: string, propsForm: any) {
    return createSimpleField(
      name,
      label,
      propsForm.values,
      propsForm.errors,
      propsForm.touched,
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1 }}>
          <Slider name={name} min={-30} max={30} />
        </div>
        <div>{_.get(propsForm.values, name)} mins</div>
      </div>
    );
  }

  render() {
    const { errors, touched, values } = this.props;

    const fieldsTimes = [];
    for (const field of ManualAdjustmentsFormComponent.FIELDS_TIMES) {
      fieldsTimes.push(this.createSlider(field.name, field.label, this.props));
    }
    const fieldsPrayertimes = [];
    for (const field of ManualAdjustmentsFormComponent.FIELDS_PRAYER_TIMES) {
      fieldsPrayertimes.push(this.createSlider(field.name, field.label, this.props));
    }
    const fieldsFastingtimes = [];
    for (const field of ManualAdjustmentsFormComponent.FIELDS_FASTING_TIMES) {
      fieldsFastingtimes.push(this.createSlider(field.name, field.label, this.props));
    }
    return <div>
      <Divider orientation="left">Manual Adjustments times</Divider>
      {fieldsTimes}
      <Divider orientation="left">Manual Adjustments Prayer times</Divider>
      {fieldsPrayertimes}
      <Divider orientation="left">Manual Adjustments Fasting times</Divider>
      {fieldsFastingtimes}
    </div>;
  }
}
