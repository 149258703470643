// generic-modal.component.tsx

import React, { Component, useRef, ReactNode } from "react";
import { Modal } from "antd";
import * as _ from "lodash";

type Props = {
  title: ReactNode;
  footer: ReactNode;
};

type State = {
  visible: boolean;
};

export class GenericModalComponent extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      visible: false
    };
  }

  open() {
    this.setState({ visible: true });
  }

  close() {
    this.setState({ visible: false });
  }

  componentDidMount() {}

  render() {
    const { title, footer, children } = this.props;
    const { visible } = this.state;

    return (
      <Modal
        title={title}
        visible={visible}
        onCancel={() => {
          this.close();
        }}
        footer={footer}
      >
        {children}
      </Modal>
    );
  }
}
