// types.ts

export enum CategoryActionTypes {
  COUNT = '@@category/COUNT',
  FETCH_COUNT_SUCCESS = '@@category/FETCH_COUNT_SUCCESS',
  FETCH_COUNT_ERROR = '@@category/FETCH_COUNT_ERROR'
}

export interface CategoryState {
  readonly count: number;
}
