// adjustment-detail.page.tsx

import React, { Component, useRef } from "react";
import { Layout, Row, Col, Modal, Result, Icon, message, Button } from "antd";
import { Map, TileLayer, Marker } from "react-leaflet";
import { forkJoin } from "rxjs";
import * as _ from "lodash";
import { keys } from "ts-transformer-keys";
import { withRouter, RouteComponentProps } from "react-router-dom";

import "./adjustment-detail.page.css";

import { PrayertimesTableComponent } from "../components/prayertimes-table.component";

import { LocationsService } from "../services/locations.service";
import {
  AdjustmentsService,
  AdjustmentParams
} from "../services/adjustments.service";

import { LocationFormContainer } from "./forms/location.form";
import { AdjustmentFormComponent } from "./forms/adjustment.form";
import { Adjustment } from "../models/adjustment.model";
import { Location } from "../models/location.model";
import { deepCleaner, read } from "../../../utils/deep-cleaner.util";

const { Content } = Layout;

type PathParamsType = {};
type Props = RouteComponentProps<PathParamsType> & {
  match?: any;
};

type State = {
  isModify: boolean;
  loading: boolean;
  map: {
    latLng: any[];
    zoom: number;
  };
  visible: boolean;
  location: Location;
  initValueAdjustment: Adjustment;
  valueAdjustment: Adjustment;
};

export class AdjustmentDetailPageComponent extends Component<Props, State> {
  private _mapRef: any;
  private _locationFormRef: any = null;
  private _adjustmentFormRef: any = null;

  constructor(props: Props, state: State) {
    super(props, state);
    const adjustment = {
      status: 1,
      description: "",
      creator: {
        type: 0
      },
      location: {
        type: 0,
        parameters: {}
      },
      method: {
        type: 3
      },
      angleBasedMethod: 0,
      asrMethod: 0,
      midnightMethod: 0,
      hijri: 0,
      manual: {
        sunrise: 0,
        sunset: 0,
        midnight: 0,
        fajr: 0,
        dhuhr: 0,
        asr: 0,
        maghrib: 0,
        isha: 0,
        sahur: 0,
        iftar: 0
      }
    };
    this.state = {
      isModify: (Object.keys(this.props.match?.params).length > 0),
      loading: (Object.keys(this.props.match?.params).length > 0),
      map: {
        latLng: [50.6917977224672, 3.1784561088391],
        zoom: 13
      },
      visible: false,
      location: {
        latitude: 50.6917977224672,
        longitude: 3.1784561088391,
        city: "Roubaix",
        country: "FR",
        timezone: "Europe/Paris"
      },
      initValueAdjustment: adjustment,
      valueAdjustment: adjustment
    };
  }

  componentDidMount() {
    console.log(this.props.match);
    if (Object.keys(this.props.match?.params).length > 0) {
      const { id } = this.props.match.params;
      AdjustmentsService.get(id).then(response => {
        const adjustment = response.data.adjustment.data;
        this.setState({
          loading: false,
          initValueAdjustment: adjustment
        });
      });
    }
  }

  onClickAdjustment() {
    const { latLng } = this.state.map;
    const location = new LocationsService();
    const reverseLocation = location.getReverseLocation(
      latLng[0],
      latLng[1],
      "en"
    );
    const timezone = location.getTimezoneLocation(latLng[0], latLng[1], 0);
    forkJoin([reverseLocation, timezone]).subscribe(results => {
      const adjustment = _.cloneDeep(this.state.valueAdjustment);
      if (adjustment) {
        const result = {
          type: 0,
          parameters: {
            latitude: latLng[0],
            longitude: latLng[1],
            city: results[0].city,
            country: results[0].country,
            timezone: results[1].id
          }
        };
        if (this._adjustmentFormRef) {
          this._adjustmentFormRef.setFieldValue("location", result);
        }
      }
    });
    this.setState({ ...this.state, visible: false });
  }

  onClickMain() {
    const { latLng } = this.state.map;
    const location = new LocationsService();
    const reverseLocation = location.getReverseLocation(
      latLng[0],
      latLng[1],
      "en"
    );
    const timezone = location.getTimezoneLocation(latLng[0], latLng[1], 0);
    forkJoin([reverseLocation, timezone]).subscribe(results => {
      const location = {
        latitude: latLng[0],
        longitude: latLng[1],
        city: results[0].city,
        country: results[0].country,
        timezone: results[1].id
      };
      if (this._locationFormRef) {
        console.log("onClickMain update.....");
        this._locationFormRef.setValues(location);
      }
      /*this.setState({
        location: {
          latitude: latLng[0],
          longitude: latLng[1],
          city: results[0].city,
          country: results[0].country,
          timezone: results[1].id
        }
      });*/
    });
    this.setState({ ...this.state, visible: false });
  }

  updateLocationState(value: Location) {
    const { location } = this.state;
    if (!_.isEqual(location, value)) {
      const newLocation = _.clone(value);
      this.setState({ location: newLocation });
    }
  }

  updateAdjustmentState(value: Adjustment) {
    const { valueAdjustment } = this.state;
    if (!_.isEqual(valueAdjustment, value)) {
      const adjustment = _.clone(value);
      this.setState({ valueAdjustment: adjustment });
    }
  }

  render() {
    const { history } = this.props
    const {
      isModify,
      loading,
      map,
      location,
      initValueAdjustment,
      valueAdjustment
    } = this.state;

    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Result
            icon={<Icon type="loading" />}
            title="Loading adjustment..."
          />
        </div>
      );
    } else {
      return (
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280
          }}
        >
          <Modal
            title="Location"
            visible={this.state.visible}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            footer={[
              <Button key={"mainButton"} onClick={this.onClickMain.bind(this)}>
                Main
              </Button>,
              <Button
                key={"adjustmentButton"}
                type="primary"
                onClick={this.onClickAdjustment.bind(this)}
              >
                Adjustment
              </Button>
            ]}
          >
            <p>Select this location for ?</p>
          </Modal>
          <Row>
            <Col span={8} push={16}>
              <div
                style={{
                  backgroundColor: "#fff",
                  margin: "20px",
                  padding: "20px"
                }}
              >
                <LocationFormContainer 
                innerRef={(ref: any) => (this._locationFormRef = ref)}
                location={location}
                onChange={this.updateLocationState.bind(this)} />
              </div>
            </Col>
            <Col span={16} pull={8}>
              <Map
                id="prayertimes-detail-map"
                ref={ref => (this._mapRef = ref)}
                center={[map.latLng[0], map.latLng[1]]}
                zoom={map.zoom}
                onMove={() => {
                  if (this._mapRef?.viewport?.center) {
                    this.setState({
                      map: {
                        zoom: this._mapRef.viewport.zoom,
                        latLng: this._mapRef.viewport.center
                      }
                    });
                  }
                }}
                onClick={() => {
                  this.setState({ ...this.state, visible: true });
                }}
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[map.latLng[0], map.latLng[1]]}></Marker>
              </Map>
            </Col>
          </Row>

          <div
            style={{
              backgroundColor: "#fff",
              marginTop: "30px",
              padding: "15px"
            }}
          >
            <Row>
              <Col span={16}>
                <AdjustmentFormComponent
                  innerRef={(ref: any) => (this._adjustmentFormRef = ref)}
                  isModify={isModify}
                  initValue={initValueAdjustment}
                  isEqual={_.isEqual(initValueAdjustment, valueAdjustment)}
                  onChange={this.updateAdjustmentState.bind(this)}
                  onSubmit={() => {
                    console.log("Submit..........");
                    const value: any = deepCleaner<AdjustmentParams>(
                      _.clone(valueAdjustment)
                    );
                    value["_id"] = undefined;
                    value["createdAt"] = undefined;
                    value["modifiedAt"] = undefined;
                    if (isModify) {
                      const { id } = this.props.match.params;
                      AdjustmentsService.update(id, value)
                        .then(value => {
                          message.success("This adjustment was updated");
                          history.push("/prayertimes");
                          history.push(this.props.match.url);
                        })
                        .catch(error => {
                          message.error(
                            "This adjustment was NOT updated, error: " +
                              error.toString()
                          );
                        });
                    } else {
                      AdjustmentsService.create(value)
                        .then(value => {
                          message.success("This adjustment was created");
                          history.push("/prayertimes");
                          history.push("/prayertimes/adjustments");
                        })
                        .catch(error => {
                          message.error(
                            "This adjustment was NOT created, error: " +
                              error.toString()
                          );
                        });
                    }
                  }}
                />
              </Col>
              <Col span={8}>
                <PrayertimesTableComponent
                  location={location}
                  adjustment={valueAdjustment}
                />
              </Col>
            </Row>
          </div>
        </Content>
      );
    }
  }
}

export const AdjustmentDetailPageContainer = withRouter(
  AdjustmentDetailPageComponent
);
