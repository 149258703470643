// category-translation.form.tsx

import React, { Component, Ref } from "react";
import { Row, Col, Divider, Button, Tabs } from "antd";
import * as _ from "lodash";
import Moment from "react-moment";
import { Form, Input, FormItem, Select } from "formik-antd";
import { Formik, FieldArray, Field } from "formik";
import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { DataOptions } from "../../data/categories-data.options";
import { CategoryTranslation } from "../../models/category.model";

import { CategoryTranslationSchema } from "../../validators/category.validator";
import { createSimpleField } from "../../../../components/create-simple.form";

const { Option } = Select;
const { TabPane } = Tabs;

type Props = {
  initValue: CategoryTranslation;
  isModify: boolean;
  isEqual: boolean;
  languagesValue: any;
  onChange: (value: CategoryTranslation) => void;
  innerRef: any;
  onSubmit: () => void;
};

type State = {
  value: CategoryTranslation;
  activeKey: string;
};

export class CategoryTranslationFormComponent extends Component<Props, State> {
  private formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 }
    }
  };

  private _updateSubject: BehaviorSubject<CategoryTranslation>;

  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      value: props.initValue,
      activeKey: ""
    };
    this._updateSubject = new BehaviorSubject<CategoryTranslation>(
      props.initValue
    );
    this._updateSubject.pipe(debounceTime(500)).subscribe(values => {
      const { onChange } = this.props;
      const categoryTranslation = _.clone(values);
      // delete (category as any)['languages'];
      onChange(categoryTranslation);
    });
  }

  statusValue() {
    return DataOptions.status.map(status => (
      <Option value={status.value}>{status.name}</Option>
    ));
  }

  /*languagesValue() {
    const { value } = this.state;
    console.log(Object.keys(DataOptions.languages).length);
    console.log(
      _.without(
        Object.keys(DataOptions.languages),
        ...value.translations.map(translation => translation.language)
      ).length
    );
    return _.without(
      Object.keys(DataOptions.languages),
      ...value.translations.map(translation => translation.language)
    ).map(language => {
      const detail = _.get(DataOptions.languages, language);
      const title =
        detail.name + " - " + detail.nativeName + "  (" + language + ")";
      return <Option value={language}>{title}</Option>;
    });
  }*/

  updateState(value: CategoryTranslation) {
    console.log("updateState...");
    this._updateSubject.next(value);
  }

  componentDidMount() {}

  render() {
    const { initValue, isModify, onSubmit, isEqual, languagesValue } = this.props;
    const { value } = this.state;
    const idCreateModifyDate = () => {
      return isModify ? (
        <div>
          <FormItem name="Created" label="Created">
            <Moment>{initValue?.createdAt}</Moment>
          </FormItem>
          <FormItem name="Modified" label="Modified">
            <Moment>{initValue?.modifiedAt}</Moment>
          </FormItem>
        </div>
      ) : null;
    };
    return (
      <Formik
        innerRef={this.props.innerRef}
        onSubmit={() => {
          console.log("onSubmit........");
        }}
        initialValues={initValue}
        validationSchema={CategoryTranslationSchema}
        enableReinitialize={true}
        render={({ errors, touched, values, handleReset, setFieldValue }) => {
          this.updateState(values);
          return (
            <Form {...this.formItemLayout}>
              {idCreateModifyDate()}
              {createSimpleField(
                "language",
                "Language",
                values,
                errors,
                touched,
                <Select
                  
                  name={"language"}
                  placeholder="Select a language"
                  allowClear
                >
                  {languagesValue}
                </Select>
              )}

              {createSimpleField(
                "status",
                "Status",
                values,
                errors,
                touched,
                <Select name="status" placeholder="Select a status" allowClear>
                  {this.statusValue()}
                </Select>
              )}
              {createSimpleField(
                "title",
                "Title",
                values,
                errors,
                touched,
                <Input name="title" placeholder="Enter a title" />
              )}
              {createSimpleField(
                "meta",
                "Meta Description",
                values,
                errors,
                touched,
                <Input.TextArea
                  name="meta"
                  placeholder="Enter a meta description"
                />
              )}
              {createSimpleField(
                "keywords",
                "Keywords",
                values,
                errors,
                touched,
                <Input name="keywords" placeholder="Enter a keywords" />
              )}
            </Form>
          );
        }}
      />
    );
  }
}
