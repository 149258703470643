// routing.tsx

import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import { MainPageContainer as SsoMainPage } from "./main.page";
import { RouteData } from "../../main.page";

export const SsoRouting: RouteData[] = [
  {
    key: "/sso",
    path: "/sso",
    component: SsoMainPage,
    exact: true
  }
];
