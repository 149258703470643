// routing.tsx

import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import { MainPageContainer as RamadanMainPage } from "./main.page";
import { RouteData } from "../../main.page";

export const RamadanRouting: RouteData[] = [
  {
    key: "/ramadan",
    path: "/ramadan",
    component: RamadanMainPage,
    exact: true
  }
];
