// search.validator.ts

import * as Yup from "yup";

export const SearchSchema = Yup.object().shape({
  mode: Yup.string()
    .oneOf(["all", "coordinates", "city", 'country', "world"])
    .required("Type is required"),
  lat: Yup.number()
    .min(-90, "Enter a valid latitude!")
    .max(90, "Enter a valid latitude!")
    .when("type", {
      is: "coordinates",
      then: Yup.number().required("Latitude is required"),
      otherwise: Yup.number()
    }),
  lon: Yup.number()
    .min(-180, "Enter a valid longitude!")
    .max(180, "Enter a valid longitude!")
    .when("type", {
      is: "coordinates",
      then: Yup.number().required("Longitude is required"),
      otherwise: Yup.number()
    }),
  distance: Yup.number()
    .integer()
    .min(1, "Enter a valid distance!")
    .max(50, "Enter a valid distance!")
    .when("type", {
      is: "coordinates",
      then: Yup.number().required("Distance is required"),
      otherwise: Yup.number()
    }),
  city: Yup.string()
    .min(2)
    .when("type", {
      is: "city",
      then: Yup.string().required("City is required"),
      otherwise: Yup.string()
    }),
  country: Yup.string()
    .min(2, "Choose a valid country!")
    .max(3, "Choose a valid country!")
    .when("type", {
      is: "country",
      then: Yup.string().required("Country is required"),
      otherwise: Yup.string()
    }),
});
