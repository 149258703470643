// translation-detail.modal.tsx

import React, { ReactNode } from "react";
import * as _ from "lodash";
import { CategoryTranslationFormComponent } from "../forms/category-translation.form";
import { CategoryTranslation } from "../../models/category.model";
import { CategoryTranslationSchema } from "../../validators/category.validator";
import {
  GenericDetailModalComponent,
  State
} from "../../components/generic-detail.modal";

type Props = {
  languages: any;
  onSave: (id: string) => void;
};

export class TranslationDetailModalComponent extends GenericDetailModalComponent<
  CategoryTranslation,
  Props
> {
  constructor(props: Props, state: State<CategoryTranslation>) {
    super(props, state);
  }

  defaultValue(): CategoryTranslation {
    /* if (!_.isNil(this.state.initValue)) {
      const { initValue } = this.state;
      return {
        language: initValue.language,
        status: initValue.status,
        title: initValue.title,
        meta: initValue.meta,
        keywords: initValue.keywords
      };
    } else*/ {
      return {
        language: "fr",
        status: 1,
        title: "",
        meta: "",
        keywords: ""
      };
    }
  }

  isValid(value: CategoryTranslation | undefined): boolean {
    return CategoryTranslationSchema.isValidSync(value);
  }

  renderForm(
    ref: any,
    isModify: boolean,
    initValue: CategoryTranslation,
    isEqual: boolean,
    onChange: any
  ): ReactNode {
    const { languages } = this.props;
    return (
      <CategoryTranslationFormComponent
        innerRef={(reference: any) => (ref = reference)}
        isModify={isModify}
        initValue={initValue!}
        isEqual={isEqual}
        languagesValue={languages}
        onChange={onChange}
        onSubmit={() => {}}
      />
    );
  }
}
