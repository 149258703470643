// category.validator.ts

import * as Yup from "yup";

export const CategoryTranslationSchema = Yup.object().shape({
  language: Yup.string()
    .min(2, "Your language must be at least 2 characters!")
    .max(8, "Your language must be no more than 8 characters!")
    .required("Language is required"),
  status: Yup.number()
    .integer()
    .min(0, "Choose a valid status!")
    .max(1, "Choose a valid status!")
    .required("Status is required"),
  title: Yup.string()
    .min(2, "Your title must be at least 2 characters!")
    .max(128, "Your title must be no more than 128 characters!")
    .required("Title is required"),
  meta: Yup.string()
    .min(2, "Your meta description must be at least 2 characters!")
    .max(128, "Your meta description must be no more than 128 characters!")
    .required("Meta description is required"),
  keywords: Yup.string()
    .min(2, "Your keywords must be at least 2 characters!")
    .max(128, "Your keywords must be no more than 128 characters!")
    .required("Keywords is required")
});

export const CategorySchema = Yup.object().shape({
  status: Yup.number()
    .integer()
    .min(0, "Choose a valid status!")
    .max(1, "Choose a valid status!")
    .required("Status is required"),
  value: Yup.string()
    .min(2, "Your name must be at least 2 characters!")
    .max(128, "Your name must be no more than 128 characters!")
    .required("Name is required"),
    // just minuscule and _ and number
  image: Yup.string()
    .url('Your image url not valid!')
    .required("Image is required"),
  translates: Yup.array().of(CategoryTranslationSchema)
});
