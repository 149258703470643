// settings-adjustment.form.tsx

import React, { Component } from "react";
import * as _ from "lodash";
import {
  Input,
  Select,
  Slider
} from "formik-antd";

import { DataOptions } from "../../data/adjustments-data.options";
import { createSimpleField } from "../../../../components/create-simple.form";

const { Option } = Select;

type Props = {
  errors: any;
  touched: any;
  values: any;
};

type State = {};

export class SettingsAdjustmentFormComponent extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {};
  }

  methodsValue() {
    return DataOptions.method.map(method => (
      <Option value={method.value}>{method.name}</Option>
    ));
  }

  angleBasedMethodsValue() {
    return DataOptions.angleBasedMethod.map(method => (
      <Option value={method.value}>{method.name}</Option>
    ));
  }

  asrMethodsValue() {
    return DataOptions.asrMethod.map(method => (
      <Option value={method.value}>{method.name}</Option>
    ));
  }

  midnightMethodsValue() {
    return DataOptions.midnightMethod.map(method => (
      <Option value={method.value}>{method.name}</Option>
    ));
  }

  render() {
    const { errors, touched, values } = this.props;
    return (
      <div>
        {createSimpleField(
          "method.type",
          "Method",
          values,
          errors,
          touched,
          <Select name="method.type" placeholder="Select a method" allowClear>
            {this.methodsValue()}
          </Select>
        )}
        {createSimpleField(
          "method.parameters.fajrAngle",
          "Fajr angle",
          values,
          errors,
          touched,
          <Input name="method.parameters.fajrAngle" />,
          values.method?.type !== -1 && values.method?.type !== -2
        )}
        {createSimpleField(
          "method.parameters.ishaAngle",
          "Isha angle",
          values,
          errors,
          touched,
          <Input name="method.parameters.ishaAngle" />,
          values.method?.type !== -1
        )}
        {createSimpleField(
          "method.parameters.ishaTime",
          "Isha time",
          values,
          errors,
          touched,
          <Input name="method.parameters.ishaTime" />,
          values.method?.type !== -2
        )}
        
        {createSimpleField(
          "angleBasedMethod",
          "Angle based method",
          values,
          errors,
          touched,
          <Select name="angleBasedMethod" placeholder="Select a angle based method" allowClear>
            {this.angleBasedMethodsValue()}
          </Select>
        )}
        {createSimpleField(
          "asrMethod",
          "Asr method",
          values,
          errors,
          touched,
          <Select name="asrMethod" placeholder="Select a asr method" allowClear>
            {this.asrMethodsValue()}
          </Select>
        )}
        {createSimpleField(
          "midnightMethod",
          "Midnight method",
          values,
          errors,
          touched,
          <Select name="midnightMethod" placeholder="Select a midnight method" allowClear>
            {this.midnightMethodsValue()}
          </Select>
        )}
        {createSimpleField(
          "hijri",
          "Hijri",
          values,
          errors,
          touched,
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>
              <Slider name="hijri" min={-5} max={5} />
            </div>
            <div>{values.hijri} days</div>
          </div>
        )}
      </div>
    );
  }
}
