// adjustments.section.tsx

import React, { Component } from "react";
import { Layout, Button, Icon, Row, Col, Spin, message } from "antd";
import { Map, TileLayer, Marker, Popup, LatLng, Circle } from "react-leaflet";
import * as _ from "lodash";

import "./adjustments.section.css";

import { SearchFormComponent } from "./adjustments/forms/search.form";
import { AdjustmentTableComponent } from "./adjustment-table.component";
import { Search } from "./models/search.model";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RemoveAdjustmentModalComponent } from "./adjustments/modals/remove-adjustment.modal";
import { AdjustmentsService } from "./services/adjustments.service";
import { bindActionCreators } from "redux";
import { count } from "./store/adjustment/actions";
import { connect } from "react-redux";

const { Header, Sider, Content } = Layout;

const indicator = <Icon type="close" style={{ fontSize: "3em" }} />;

const MAP_DEFAULT = {
  latitude: 50.6917977224672,
  longitude: 3.1784561088391,
  zoom: 11,
  distance: 3
};

type PathParamsType = {};
type Props = RouteComponentProps<PathParamsType> & {
  countRequest: () => void;
};

type State = {
  map: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
  search: Search;
};

export class AdjustmentsSectionComponent extends Component<Props, State> {
  private _mapRef: any;
  private _searchFormRef: any = null;
  private _adjustmentTableRef: AdjustmentTableComponent | null = null;
  private _modalRef: RemoveAdjustmentModalComponent | null = null;

  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      map: {
        latitude: MAP_DEFAULT.latitude,
        longitude: MAP_DEFAULT.longitude,
        zoom: MAP_DEFAULT.zoom
      },
      search: {
        mode: "coordinates",
        lat: MAP_DEFAULT.latitude,
        lon: MAP_DEFAULT.longitude,
        distance: MAP_DEFAULT.distance
      }
    };
  }

  updateState(value: Search) {
    const { map, search } = this.state;
    if (!_.isEqual(search, value)) {
      const searchClone = _.clone(value);
      if (searchClone.mode === "coordinates") {
        searchClone.lat = searchClone.lat || MAP_DEFAULT.latitude;
        searchClone.lon = searchClone.lon || MAP_DEFAULT.longitude;
        searchClone.distance = searchClone.distance || MAP_DEFAULT.distance;
        const mapClone = _.clone(map);
        mapClone.latitude = searchClone.lat;
        mapClone.longitude = searchClone.lon;
        this.setState({ search: searchClone, map: mapClone });
      } else {
        this.setState({ search: searchClone });
      }
    }
  }

  render() {
    const { history, countRequest } = this.props;
    const { map, search } = this.state;
    return (
      <div style={{ margin: "2em" }}>
        <RemoveAdjustmentModalComponent
          ref={ref => (this._modalRef = ref)}
          onRemove={id => {
            AdjustmentsService.remove(id).then(() => {
              message.success("This adjustment was removed");
              setTimeout(() => {
                this._adjustmentTableRef?.fetch();
              }, 2000);
              countRequest();
            }).catch(error => {
              message.error(
                "This adjustment was NOT removed, error: " +
                  error.toString()
              );
            });
            this._modalRef?.close();
          }}
        />
        <Row>
          <Col span={3}>
            <Button
              type="primary"
              onClick={() => {
                history.push("/prayertimes/adjustments");
              }}
            >
              <i className="fas fa-plus" style={{ marginRight: "6px" }}></i>
              Add Adjustment
            </Button>
          </Col>
          <Col span={8}>
            <SearchFormComponent
              innerRef={(ref: any) => (this._searchFormRef = ref)}
              initValue={search}
              onChange={this.updateState.bind(this)}
              onSubmit={() => {
                console.log("Submit.....");
                this._adjustmentTableRef?.fetch(null, true);
              }}
            />
          </Col>
          <Col span={13}>
            <Spin
              spinning={search.mode !== "coordinates"}
              indicator={indicator}
            >
              <Map
                id="prayertimes-main-map"
                ref={ref => (this._mapRef = ref)}
                style={{ width: "100%" }}
                center={[map.latitude, map.longitude]}
                zoom={map.zoom}
                onMove={() => {
                  if (this._mapRef?.viewport?.center) {
                    const map = {
                      latitude: this._mapRef.viewport.center[0],
                      longitude: this._mapRef.viewport.center[1],
                      zoom: this._mapRef.viewport.zoom
                    };
                    const searchClone = _.clone(search);
                    searchClone.lat = map.latitude;
                    searchClone.lon = map.longitude;
                    this.setState({ map, search: searchClone });
                  }
                }}
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Circle
                  center={[map.latitude, map.longitude]}
                  radius={(search.distance || 1) * 1000}
                ></Circle>
              </Map>
            </Spin>
          </Col>
        </Row>
        <div style={{ marginTop: "2em" }}>
          <AdjustmentTableComponent
            ref={ref => (this._adjustmentTableRef = ref)}
            search={search}
            onModifyClick={value => {
              history.push("/prayertimes/adjustments/" + value._id);
            }}
            onDeleteClick={value => {
              this._modalRef?.open(value._id);
            }}
          />
        </div>
      </div>
    );
  }
}

/*export const AdjustmentsSectionContainer = withRouter(
  AdjustmentsSectionComponent
);*/

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      countRequest: () => dispatch(count()),
    },
    dispatch,
  );
};

export const AdjustmentsSectionContainer = withRouter(connect(
  null,// mapStateToProps,
  mapDispatchToProps,
)(AdjustmentsSectionComponent));
