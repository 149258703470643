// signout.page.tsx

import React, { Component } from "react";
import * as _ from "lodash";
import { Typography, Spin, Result, Button } from "antd";
import { Redirect } from "react-router-dom";

import "./signout.page.css";

import { AuthService } from "../services/auth.service";
import { AuthContext } from "../components/with-auth";

const { Title } = Typography;

enum StatusType {
  Loading = "Loading",
  Ok = "Ok",
  Ko = "Ko"
}

type Props = {};

type State = {
  status: StatusType;
};

class SignoutPageComponent extends Component<Props, State> {
  static contextType = AuthContext;

  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      status: StatusType.Loading
    };
  }

  componentDidMount() {
    this.disconnected();
  }

  disconnected() {
    this.setState({ status: StatusType.Loading });
    AuthService.signout()
      .then(_ => {
        const { checkAuth } = this.context;
        checkAuth();
        /* setTimeout(() => {
          this.setState({ status: StatusType.Ok });
        }, 1000); */
      })
      .catch((error: any) => {
        this.setState({ status: StatusType.Ko });
      });
  }

  render() {
    const { status } = this.state;
    const content = {
      Loading: <Spin className="spin" size="large" />,
      Ok: (
        <Redirect push to="/" />
      ),
      Ko: (
        <Result
          status="warning"
          title="Connection error, please try again."
          extra={
            <Button
              type="primary"
              style={{ width: "100%" }}
              size={"large"}
              onClick={() => {
                this.disconnected();
              }}
            >
              Try again
            </Button>
          }
        />
      )
    };
    return (
      <div className="auth-layout">
        <div className="outer">
          <div className="inner">
            <div className="inner-header">
              <img src={require("../../../assets/muslimtoolbox_logo.png")} />
            </div>
            <div className="content">
              <Title level={2} style={{ textAlign: "center" }}>
                Sign out
              </Title>
              {content[status]}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignoutPageComponent;
