// remove-adjustment.modal.tsx

import React, { Component, useRef, ReactNode } from "react";
import { Button } from "antd";
import * as _ from "lodash";
import { GenericModalComponent } from "../../../../components/generic-modal.component";

type Props = {
  onRemove: (id: string) => void;
};

type State = {
  id: string;
};

export class RemoveAdjustmentModalComponent extends Component<Props, State> {
  private _modalRef: GenericModalComponent | null = null;

  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      id: ''
    };
  }

  open(id: string) {
    this.setState({ id });
    this._modalRef?.open();
  }

  close() {
    this._modalRef?.close();
  }

  componentDidMount() {}

  render() {
    const { onRemove } = this.props;
    const { id } = this. state;
    return (
      <GenericModalComponent
          ref={ref => (this._modalRef = ref)}
          title="Remove"
          footer={[
            <Button
              key={"cancelButton"}
              onClick={() => {
                this._modalRef?.close();
              }}
            >
              Cancel
            </Button>,
            <Button
              key={"removeButton"}
              type="danger"
              onClick={() => {
                onRemove(id);
              }}
            >
              Remove
            </Button>
          ]}
        >
          <p>Do you want to remove this adjustment ?</p>
        </GenericModalComponent>
    );
  }
}