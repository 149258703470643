import { all, fork, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { CategoryActionTypes } from "./types";
import { count } from "./actions";
import { CategoriesService } from "../../services/categories/categories.service";

function fetchCount() {
  return CategoriesService.count();
}

function* handleCount(action: ReturnType<typeof count>) {
  try {
    const response = yield call(fetchCount);
    console.log(response);
    const count = response.data.countCategories.data;
    console.log(count);
    yield put(fetchApiSuccess(count));
  } catch (error) {
    yield put(fetchApiFail(error));
  }
}

function fetchApiSuccess(data: number) {
  return {
    type: CategoryActionTypes.FETCH_COUNT_SUCCESS,
    data
  };
}

function fetchApiFail(error: any) {
  return {
    type: CategoryActionTypes.FETCH_COUNT_ERROR,
    error
  };
}

function* watchCount() {
  yield takeLatest(CategoryActionTypes.COUNT, handleCount);
}

//----------------------------------------------------------------

/*function* handleSearch(action: ReturnType<typeof search>) {
  search(action.payload);
}

function* watchSearch() {
  yield takeLatest(AdjustmentActionTypes.SEARCH, handleSearch);
}*/

function* categorySaga() {
  yield all([fork(watchCount)/*, fork(watchSearch)*/]);
}

export default categorySaga;
