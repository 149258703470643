// category-translations.service.ts

import { Observable } from "rxjs";
import { gql, ApolloQueryResult, FetchResult } from "apollo-boost";
import {
  HelperService,
  search,
  get,
  update,
  create,
  remove,
  count
} from "../../../../services/helper.service";
import { clientDua } from "../graphql.module";

const FRAGMENT = gql`
  fragment CategoryTranslationFragment on CategoryTranslation {
    _id
    createdAt
    modifiedAt
    category
	  language
    status
    title
    meta
    keywords
  }
`;

export interface CategoryTranslationInput {
  category: string;
  language: string;
  status: number;
  title: string;
  meta: string;
  keywords: string;
}


export interface SearchCategoryTranslationInput {
  perPage: number;
  page: number;
  category: string;
}

export class CategoryTranslationsService {
  public static search(
    input: SearchCategoryTranslationInput
  ): Promise<ApolloQueryResult<any>> {
    const query = HelperService.createQuerySearch(
      "categoryTranslations",
      "SearchCategoryTranslationInput",
      FRAGMENT,
      "CategoryTranslationFragment"
    );
    return search(clientDua, input, query);
  }

  public static get(id: string): Promise<ApolloQueryResult<any>> {
    const query = HelperService.createQueryGet(
      "categoryTranslation",
      FRAGMENT,
      "CategoryTranslationFragment"
    );
    return get(clientDua, id, query);
  }

  public static create(
    input: CategoryTranslationInput
  ): Promise<FetchResult<any>> {
    const query = HelperService.createQueryCreate(
      "createCategoryTranslation",
      "CategoryTranslationInput",
      FRAGMENT,
      "CategoryTranslationFragment"
    );
    return create(clientDua, input, query);
  }

  public static update(id: string, input: CategoryTranslationInput): Promise<FetchResult<any>> {
		const query = HelperService.createQueryUpdate('updateCategoryTranslation', 'CategoryInput', FRAGMENT, "CategoryTranslationFragment");
		return update(clientDua, id, input, query);
	}

  public static remove(id: string): Promise<FetchResult<any>> {
    const query = HelperService.createQueryRemove("removeCategoryTranslation");
    return remove(clientDua, id, query);
  }

  public static count(): Promise<ApolloQueryResult<any>> {
    const query = HelperService.createQueryCount("countCategoryTranslations");
    return count(clientDua, query);
  }

}
