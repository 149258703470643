// main.page.tsx

import React, { Component } from "react";
import Button from "antd/es/button";
import { Layout, Menu, Icon, Tabs, Result } from "antd";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Map, TileLayer, Marker, Popup, LatLng } from "react-leaflet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./main.page.css";

import { ApplicationState } from "../../store";
/* import { InfoCardComponent } from "../../components/info-card.component";
import { AdjustmentsSectionContainer } from "./adjustments.section";
import { AdjustmentsService } from "./services/adjustments.service";

import { AdjustmentState } from "./store/adjustment/types";

import { count } from "./store/adjustment/actions"; */

const { Header, Sider, Content } = Layout;
const { TabPane } = Tabs;

type Props = {};

type State = {};

class MainPageComponent extends Component<Props, State> {
  componentDidMount() {}

  render() {
    return (
      <Content
        style={{
          margin: "24px 16px",
          padding: 24,
          background: "#fff",
          minHeight: 280
        }}
      >
        <Result
          icon={<i className="fas fa-hard-hat dashboard-coming-soon-icon"></i>}
          title="Dashboard"
          subTitle="Coming soon!!"
        />
      </Content>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({}, dispatch);
};

export const MainPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainPageComponent);
