import { all, fork, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { AdjustmentActionTypes } from "./types";
import { count, search } from "./actions";
import { AdjustmentsService } from "../../services/adjustments.service";

// export const FETCH_API_REQUEST = "FETCH_API_REQUEST";
// export const FETCH_API_SUCCESS = "FETCH_API_SUCCESS";
// export const FETCH_API_FAIL = "FETCH_API_FAIL";

function fetchCount() {
  return AdjustmentsService.count();
}

function* handleCount(action: ReturnType<typeof count>) {
  try {
    const response = yield call(fetchCount);
    console.log(response);
    const count = response.data.countAdjustments.data;
    console.log(count);
    yield put(fetchApiSuccess(count));
  } catch (error) {
    yield put(fetchApiFail(error));
  }
}

/*function* handleCount(action: ReturnType<typeof count>) {
  count();
}*/
// Regular action creators
/*export function fetchApiRequest(userId) {
    return {
      type: FETCH_API_REQUEST,
      userId
    }  
}*/

function fetchApiSuccess(data: number) {
  return {
    type: AdjustmentActionTypes.FETCH_COUNT_SUCCESS,
    data
  };
}

function fetchApiFail(error: any) {
  return {
    type: AdjustmentActionTypes.FETCH_COUNT_ERROR,
    error
  };
}

function* watchCount() {
  yield takeLatest(AdjustmentActionTypes.COUNT, handleCount);
  // yield takeEvery(AdjustmentActionTypes.COUNT, handleCount)
}

//----------------------------------------------------------------

function* handleSearch(action: ReturnType<typeof search>) {
  search(action.payload);
}

function* watchSearch() {
  yield takeLatest(AdjustmentActionTypes.SEARCH, handleSearch);
}

function* adjustmentSaga() {
  yield all([fork(watchCount), fork(watchSearch)]);
}

export default adjustmentSaga;
