// main.page.tsx

import React, { Component } from "react";
import { Layout, Menu, Icon } from "antd";
import { withRouter, RouteComponentProps } from "react-router-dom";

import "./menu.section.css";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

type PathParamsType = {};

type Props = RouteComponentProps<PathParamsType> & {
  collapsed: boolean;
};

type State = {
  key: string;
};

export class MenuSectionComponent extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      key: "1"
    };
  }

  render() {
    console.log(this.state.key);
    const { history, location, collapsed } = this.props;
    const { key } = this.state;
    console.log(location);
    const pathname = location.pathname; // `/${location.pathname.split('/')[1]}`;
    console.log(location.pathname.split('/'));
    return (
      <div>
        <div className="logo">
          <img
            src={require("../assets/muslimtoolbox.webp")}
            style={{ width: "100%", margin: "auto" }}
          />
        </div>
        <div className={collapsed ? "collapsed-menu" : "full-menu"}>
          <Menu theme="dark" mode="inline" selectedKeys={[pathname]}>
            <Menu.Item
              key="/"
              onClick={() => {
                history.push("/");
              }}
            >
              <i className="fas fa-tachometer-alt menu-section-icon"></i>
              <span className="title">Dashboard</span>
            </Menu.Item>
            <Menu.Item
              key="/sso"
              onClick={() => {
                history.push("/sso");
              }}
            >
              <i className="fas fa-user menu-section-icon"></i>
              <span className="title">SSO</span>
            </Menu.Item>
            <Menu.Item
              key="/notifications"
              onClick={() => {
                history.push("/notifications");
              }}
            >
              <i className="fas fa-bell menu-section-icon"></i>
              <span className="title">Notifications</span>
            </Menu.Item>
            <Menu.Item
              key="/prayertimes"
              onClick={() => {
                history.push("/prayertimes");
              }}
            >
              <i className="fas fa-pray menu-section-icon"></i>
              <span className="title">Prayertimes</span>
            </Menu.Item>
            <Menu.Item
              key="/ramadan"
              onClick={() => {
                history.push("/ramadan");
              }}
            >
              <i className="fas fa-moon menu-section-icon"></i>
              <span className="title">Ramadan</span>
            </Menu.Item>
            <Menu.Item
              key="/quran"
              onClick={() => {
                history.push("/quran");
              }}
            >
              <i className="fas fa-quran menu-section-icon"></i>
              <span className="title">Quran</span>
            </Menu.Item>
            <Menu.Item
              key="/dua"
              onClick={() => {
                history.push("/dua");
              }}
            >
              <i className="fas fa-praying-hands menu-section-icon"></i>
              <span className="title">Dua</span>
            </Menu.Item>
            {/* <SubMenu
              title={
                <span>
                  <i className="fas fa-praying-hands menu-section-icon"></i>
                  <span className="title">Dua</span>
                </span>
              }
            >
              <Menu.Item
                key="/dua"
                onClick={() => {
                  history.push("/dua/duas");
                }}
              >
                Duas
              </Menu.Item>
              <Menu.Item
                key="7"
                onClick={() => {
                  history.push("/dua/categories");
                }}
              >
                Categories
              </Menu.Item>
              <Menu.Item key="8">Tags</Menu.Item>
              <Menu.Item key="9">Divisions</Menu.Item>
              <Menu.Item key="10">Collections</Menu.Item>
              <Menu.Item key="11">Editions</Menu.Item>
              </SubMenu> */}
            <SubMenu
              title={
                <span>
                  <i className="fas fa-window-maximize menu-section-icon"></i>
                  <span className="title">Websites</span>
                </span>
              }
            >
              <Menu.Item
                key="/websites/apps"
                onClick={() => {
                  history.push("/websites/apps");
                }}
              >
                Apps
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      </div>
    );
  }
}

export const MenuSectionContainer = withRouter(MenuSectionComponent);
