// settings.page.tsx

import React, { Component } from "react";
import {
  Layout,
  Button,
  Alert,
  Typography,
  Icon,
  Result,
  Row,
  Col,
  Divider,
  Form
} from "antd";
import * as _ from "lodash";
import Superagent from "superagent";

import "./signin.page.css";

import { AuthContext } from "../components/with-auth";
import { withRouter, RouteProps, RouteComponentProps } from "react-router-dom";
import { SettingsService } from "../services/settings.service";

const { Content } = Layout;
const { Title } = Typography;

type Props = RouteComponentProps & {};

type State = {
  loading: boolean;
  data?: any;
  error?: string;
};

class SettingsPageComponent extends Component<Props, State> {
  static contextType = AuthContext;

  private formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 2 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };

  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    SettingsService.get()
      .then((response: Superagent.Response) => {
        this.setState({
          loading: false,
          data: JSON.parse(response.text)?.data
        });
      })
      .catch((error: any) => {
        this.setState({ loading: false, error: error });
      });
  }

  render() {
    const { loading, data, error } = this.state;

    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Result icon={<Icon type="loading" />} title="Loading settings..." />
        </div>
      );
    }

    if (error) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Result icon={<Icon type="warning" />} title="Error settings..." />
        </div>
      );
    }
    return (
      <Content
        style={{
          margin: "24px 16px",
          padding: 24,
          minHeight: 280
        }}
      >
        <Row>
          <Col span={16}>
            <Title>Settings</Title>
            <div
              style={{
                backgroundColor: "#fff",
                margin: "20px",
                padding: "20px"
              }}
            >
              <Form {...this.formItemLayout}>
                <Form.Item label="Username">{data?.username}</Form.Item>
                <Divider orientation="left">OTP</Divider>
                <Form.Item label="Secret">{data?.secret}</Form.Item>
                <Form.Item label="QR Code"><img src={data?.qr} /></Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}

export default withRouter(SettingsPageComponent);
