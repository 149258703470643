import * as DMath from './dmath';

export const test = '';
export class Prayertimes {
	public readonly timeNames = {
		imsak: 'Imsak',
		fajr: 'Fajr',
		sunrise: 'Sunrise',
		dhuhr: 'Dhuhr',
		asr: 'Asr',
		sunset: 'Sunset',
		maghrib: 'Maghrib',
		isha: 'Isha',
		midnight: 'Midnight'
	};

	public readonly methods: any = {
		MWL: {
			name: 'Muslim World League',
			params: { fajr: 18, isha: 17 }
		},
		ISNA: {
			name: 'Islamic Society of North America (ISNA)',
			params: { fajr: 15, isha: 15 }
		},
		Egypt: {
			name: 'Egyptian General Authority of Survey',
			params: { fajr: 19.5, isha: 17.5 }
		},
		Makkah: {
			name: 'Umm Al-Qura University, Makkah',
			params: { fajr: 18.5, isha: '90 min' }
		}, // fajr was 19 degrees before 1430 hijri
		Karachi: {
			name: 'University of Islamic Sciences, Karachi',
			params: { fajr: 18, isha: 18 }
		},
		Tehran: {
			name: 'Institute of Geophysics, University of Tehran',
			params: { fajr: 17.7, isha: 14, maghrib: 4.5, midnight: 'Jafari' }
		}, // isha is not explicitly specified in this method
		Jafari: {
			name: 'Shia Ithna-Ashari, Leva Institute, Qum',
			params: { fajr: 16, isha: 14, maghrib: 4, midnight: 'Jafari' }
		},
		UOIF: {
			name: 'Union des Organisations Islamiques de france (UOIF)',
			params: { fajr: 12, isha: 12 }
		}
	};

	public readonly defaultParams = {
		maghrib: '0 min',
		midnight: 'Standard'
	};

	public calcMethod = 'Egypt';

	public setting: any = {
		fajr: 18,
		isha: 17,
		maghrib: '0 min',
		midnight: 'Standard',
		imsak: '10 min',
		dhuhr: '0 min',
		asr: 'Standard',
		highLats: 'NightMiddle'
	};

	public timeFormat = '24h';
	public readonly timeSuffixes = ['am', 'pm'];
	public readonly invalidTime = '-----';

	public numIterations = 1;
	public offset: any = {};

	public lat: any;
	public lng: any;
	public elv: any;

	public timeZone: any;
	public jDate: any;

	public defParams: any = this.defaultParams;

	constructor() {
		for (let i in this.methods) {
			let params = this.methods[i].params;
			for (var j in this.defParams)
				if (typeof params[j] == 'undefined') params[j] = this.defParams[j];
		}

		let params = this.methods[this.calcMethod].params;
		for (var id in params) this.setting[id] = params[id];

		for (var i in this.timeNames) this.offset[i] = 0;
	}

	//----------------------- Public Functions ------------------------

	// set calculation method
	public setMethod(method: any) {
		if (this.methods[method]) {
			this.adjust(this.methods[method].params);
			this.calcMethod = method;
		}
	}

	// set calculating parameters
	public adjust(params: any) {
		for (const id in params) this.setting[id] = params[id];
	}

	// set time offsets
	public tune(timeOffsets: any) {
		for (const i in timeOffsets) this.offset[i] = timeOffsets[i];
	};

	// get current calculation method
	public getMethod() {
		return this.calcMethod;
	}

	// get current setting
	public getSetting() {
		return this.setting;
	}

	// get current time offsets
	public getOffsets() {
		return this.offset;
	}

	// get default calc parametrs
	public getDefaults() {
		return this.methods;
	}

	// return prayer times for a given date
	public getTimes(date: any, coords: any, timezone: any, dst: any, format: any) {
		this.lat = 1 * coords[0];
		this.lng = 1 * coords[1];
		this.elv = coords[2] ? 1 * coords[2] : 0;
		this.timeFormat = format || this.timeFormat;
		if (date.constructor === Date)
			date = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
		if (typeof timezone == 'undefined' || timezone == 'auto')
			timezone = this.getTimeZone(date);
		if (typeof dst == 'undefined' || dst == 'auto') dst = this.getDst(date);
		this.timeZone = 1 * timezone + (1 * dst ? 1 : 0);
		this.jDate = this.julian(date[0], date[1], date[2]) - this.lng / (15 * 24);

		return this.computeTimes();
	}

	// convert float time to the given format (see timeFormats)
	public getFormattedTime(time: any, format: any, suffixes: any) {
		if (isNaN(time)) return this.invalidTime;
		if (format == 'Float') return time;
		suffixes = suffixes || this.timeSuffixes;

		time = DMath.fixHour(time + 0.5 / 60); // add 0.5 minutes to round
		const hours = Math.floor(time);
		const minutes = Math.floor((time - hours) * 60);
		const suffix = format == '12h' ? suffixes[hours < 12 ? 0 : 1] : '';
		const hour =
			format == '24h'
				? this.twoDigitsFormat(hours)
				: ((hours + 12 - 1) % 12) + 1;
		return (
			hour + ':' + this.twoDigitsFormat(minutes) + (suffix ? ' ' + suffix : '')
		);
	}

	//---------------------- Calculation Functions -----------------------

	// compute mid-day time
	private midDay(time: any) {
		var eqt = this.sunPosition(this.jDate + time).equation;
		var noon = DMath.fixHour(12 - eqt);
		return noon;
	}

	// compute the time at which sun reaches a specific angle below horizon
	private sunAngleTime(angle: any, time: any, direction: any) {
		var decl = this.sunPosition(this.jDate + time).declination;
		var noon = this.midDay(time);
		var t =
			(1 / 15) *
			DMath.arccos(
				(-DMath.sin(angle) - DMath.sin(decl) * DMath.sin(this.lat)) /
					(DMath.cos(decl) * DMath.cos(this.lat))
			);
		return noon + (direction == 'ccw' ? -t : t);
	}

	// compute asr time
	private asrTime(factor: any, time: any) {
		var decl = this.sunPosition(this.jDate + time).declination;
		var angle = -DMath.arccot(factor + DMath.tan(Math.abs(this.lat - decl)));
		return this.sunAngleTime(angle, time, null);
	}

	// compute declination angle of sun and equation of time
	// Ref: http://aa.usno.navy.mil/faq/docs/SunApprox.php
	private sunPosition = function(jd: any) {
		var D = jd - 2451545.0;
		var g = DMath.fixAngle(357.529 + 0.98560028 * D);
		var q = DMath.fixAngle(280.459 + 0.98564736 * D);
		var L = DMath.fixAngle(q + 1.915 * DMath.sin(g) + 0.02 * DMath.sin(2 * g));

		var R = 1.00014 - 0.01671 * DMath.cos(g) - 0.00014 * DMath.cos(2 * g);
		var e = 23.439 - 0.00000036 * D;

		var RA = DMath.arctan2(DMath.cos(e) * DMath.sin(L), DMath.cos(L)) / 15;
		var eqt = q / 15 - DMath.fixHour(RA);
		var decl = DMath.arcsin(DMath.sin(e) * DMath.sin(L));

		return { declination: decl, equation: eqt };
	};

	// convert Gregorian date to Julian day
	// Ref: Astronomical Algorithms by Jean Meeus
	private julian(year: any, month: any, day: any) {
		if (month <= 2) {
			year -= 1;
			month += 12;
		}
		var A = Math.floor(year / 100);
		var B = 2 - A + Math.floor(A / 4);

		var JD =
			Math.floor(365.25 * (year + 4716)) +
			Math.floor(30.6001 * (month + 1)) +
			day +
			B -
			1524.5;
		return JD;
	}

	// ---------------------- Compute Prayer Times -----------------------

	// compute prayer times at given julian date
	private computePrayerTimes(times: any) {
		times = this.dayPortion(times);
		const params = this.setting;

		const imsak = this.sunAngleTime(
			this.eval(params.imsak),
			times.imsak,
			'ccw'
		);
		const fajr = this.sunAngleTime(this.eval(params.fajr), times.fajr, 'ccw');
		const sunrise = this.sunAngleTime(
			this.riseSetAngle(),
			times.sunrise,
			'ccw'
		);
		const dhuhr = this.midDay(times.dhuhr);
		const asr = this.asrTime(this.asrFactor(params.asr), times.asr);
		const sunset = this.sunAngleTime(this.riseSetAngle(), times.sunset, null);
		const maghrib = this.sunAngleTime(
			this.eval(params.maghrib),
			times.maghrib,
			null
		);
		const isha = this.sunAngleTime(this.eval(params.isha), times.isha, null);

		return {
			imsak: imsak,
			fajr: fajr,
			sunrise: sunrise,
			dhuhr: dhuhr,
			asr: asr,
			sunset: sunset,
			maghrib: maghrib,
			isha: isha
		};
	}

	// compute prayer times
	private computeTimes() {
		// default times
		var times: any = {
			imsak: 5,
			fajr: 5,
			sunrise: 6,
			dhuhr: 12,
			asr: 13,
			sunset: 18,
			maghrib: 18,
			isha: 18
		};

		// main iterations
		for (var i = 1; i <= this.numIterations; i++)
			times = this.computePrayerTimes(times);

		times = this.adjustTimes(times);

		// add midnight time
		times['midnight'] =
			this.setting.midnight == 'Jafari'
				? times.sunset + this.timeDiff(times.sunset, times.fajr) / 2
				: times.sunset + this.timeDiff(times.sunset, times.sunrise) / 2;

		times = this.tuneTimes(times);
		return this.modifyFormats(times);
	}

	// adjust times
	private adjustTimes(times: any) {
		var params = this.setting;
		for (var i in times) times[i] += this.timeZone - this.lng / 15;

		if (params.highLats != 'None') times = this.adjustHighLats(times);

		if (this.isMin(params.imsak))
			times.imsak = times.fajr - this.eval(params.imsak) / 60;
		if (this.isMin(params.maghrib))
			times.maghrib = times.sunset + this.eval(params.maghrib) / 60;
		if (this.isMin(params.isha))
			times.isha = times.maghrib + this.eval(params.isha) / 60;
		times.dhuhr += this.eval(params.dhuhr) / 60;

		return times;
	}

	// get asr shadow factor
	private asrFactor(asrParam: any) {
		var factor: any = ({ Standard: 1, Hanafi: 2 } as any)[asrParam];
		return factor || this.eval(asrParam);
	}

	// return sun angle for sunset/sunrise
	private riseSetAngle() {
		//var earthRad = 6371009; // in meters
		//var angle = DMath.arccos(earthRad/(earthRad+ elv));
		var angle = 0.0347 * Math.sqrt(this.elv); // an approximation
		return 0.833 + angle;
	}

	// apply offsets to the times
	private tuneTimes(times: any) {
		for (var i in times) times[i] += this.offset[i] / 60;
		return times;
	}

	// convert times to given time format
	private modifyFormats(times: any) {
		for (var i in times) {
			times[i] = this.getFormattedTime(times[i], this.timeFormat, null);
		}
		return times;
	}

	// adjust times for locations in higher latitudes
	private adjustHighLats(times: any) {
		const params = this.setting;
		const nightTime = this.timeDiff(times.sunset, times.sunrise);

		times.imsak = this.adjustHLTime(
			times.imsak,
			times.sunrise,
			this.eval(params.imsak),
			nightTime,
			'ccw'
		);
		times.fajr = this.adjustHLTime(
			times.fajr,
			times.sunrise,
			this.eval(params.fajr),
			nightTime,
			'ccw'
		);
		times.isha = this.adjustHLTime(
			times.isha,
			times.sunset,
			this.eval(params.isha),
			nightTime,
			null
		);
		times.maghrib = this.adjustHLTime(
			times.maghrib,
			times.sunset,
			this.eval(params.maghrib),
			nightTime,
			null
		);

		return times;
	}

	// adjust a time for higher latitudes
	private adjustHLTime(time: any, base: any, angle: any, night: any, direction: any) {
		const portion = this.nightPortion(angle, night);
		const timeDiff =
			direction === 'ccw'
				? this.timeDiff(time, base)
				: this.timeDiff(base, time);
		if (isNaN(time) || timeDiff > portion) {
			time = base + (direction === 'ccw' ? -portion : portion);
		}
		return time;
	}

	// the night portion used for adjusting times in higher latitudes
	private nightPortion(angle: any, night: any) {
		const method = this.setting.highLats;
		let portion = 1 / 2; // MidNight
		if (method === 'AngleBased') { portion = (1 / 60) * angle; }
		if (method === 'OneSeventh') { portion = 1 / 7; }
		return portion * night;
	}

	// convert hours to day portions
	private dayPortion(times: any) {
		for (let i in times) { times[i] /= 24; }
		return times;
	}

	// ---------------------- Time Zone Functions -----------------------

	// get local time zone
	private getTimeZone(date: any) {
		const year = date[0];
		const t1 = this.gmtOffset([year, 0, 1]);
		const t2 = this.gmtOffset([year, 6, 1]);
		return Math.min(t1, t2);
	}

	// get daylight saving for a given date
	private getDst(date: any) {
		const value = this.gmtOffset(date) !== this.getTimeZone(date) ? 1 : 0;
		return 1 * value;
	}

	// GMT offset for a given date
	private gmtOffset(date: any) {
		const localDate: Date = new Date(
			date[0],
			date[1] - 1,
			date[2],
			12,
			0,
			0,
			0
		);
		const GMTString = localDate.toUTCString(); // .toGMTString();
		const GMTDate: Date = new Date(
			GMTString.substring(0, GMTString.lastIndexOf(' ') - 1)
		);
		const hoursDiff =
			(localDate.getTime() - GMTDate.getTime()) / (1000 * 60 * 60);
		return hoursDiff;
	}

	// ---------------------- Misc Functions -----------------------

	// convert given string into a number
	private eval(str: any) {
		const value = String(str).split(/[^0-9.+-]/)[0];
		return 1 * +value;
	}

	// detect if input contains 'min'
	private isMin(arg: any) {
		return (arg + '').indexOf('min') !== -1;
	}

	// compute the difference between two times
	private timeDiff(time1: any, time2: any) {
		return DMath.fixHour(time2 - time1);
	}

	// add a leading 0 if necessary
	private twoDigitsFormat = function(num: any) {
		return num < 10 ? '0' + num : num;
	};
}
