// creator-adjustment.form.tsx

import React, { Component, ReactNode } from "react";
import * as _ from "lodash";
import { Input, Select } from "formik-antd";

import { DataOptions } from "../../data/adjustments-data.options";
import { createSimpleField } from "../../../../components/create-simple.form";

const { Option } = Select;

type Props = {
  errors: any;
  touched: any;
  values: any;
};

type State = {};

export class CreatorAdjustmentFormComponent extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
  }

  creatorValue() {
    return DataOptions.creatorType.map(type => (
      <Option value={type.value}>{type.name}</Option>
    ));
  }

  componentDidMount() {}

  render() {
    const { errors, touched, values } = this.props;
    return (
      <div>
        {createSimpleField(
          "creator.type",
          "Type",
          values,
          errors,
          touched,
          <Select name="creator.type" placeholder="Select a type" allowClear>
            {this.creatorValue()}
          </Select>
        )}
        {createSimpleField(
          "creator.uuid",
          "ID",
          values,
          errors,
          touched,
          <Input name="creator.uuid" placeholder="Enter a uuid"/>,
          values.creator?.type === 0
        )}
      </div>
    );
  }
}
