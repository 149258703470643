// adjustment.validator.ts

import * as Yup from "yup";

export const AdjustmentSchema = Yup.object().shape({
  status: Yup.number()
    .integer()
    .min(0, "Choose a valid status!")
    .max(1, "Choose a valid status!")
    .required("Status is required"),
  description: Yup.string()
    .min(5, "Your description must be at least 5 characters!")
    .max(128, "Your description must be no more than 128 characters!")
    .required("Description is required"),
  creator: Yup.object({
    type: Yup.number()
      .integer()
      .min(0, "Choose a valid creator type!")
      .max(2, "Choose a valid creator type!")
      .required("Creator type is required"),
    uuid: Yup.string()
      .min(128, "Enter a valid ID!")
      .max(128, "Enter a valid ID!")
      .nullable()
      .when("type", {
        is: 1 || 2,
        then: Yup.string().required("ID is required"),
        otherwise: Yup.string()
      })
  }),
  location: Yup.lazy((value: any) => {
    if (value?.type === 0) {
      return Yup.object({
        type: Yup.number()
          .integer()
          .min(0, "Choose a valid location type!")
          .max(3, "Choose a valid location type!")
          .required("Location type is required"),
        parameters: Yup.object({
          latitude: Yup.number()
            .min(-90, "Enter a valid latitude!")
            .max(90, "Enter a valid latitude!")
            .required("Latitude is required"),
          longitude: Yup.number()
            .min(-180, "Enter a valid longitude!")
            .max(180, "Enter a valid longitude!")
            .required("Longitude is required"),
          city: Yup.string()
            .min(2)
            .required("City is required"),
          country: Yup.string()
            .min(2, "Choose a valid country!")
            .max(3, "Choose a valid country!")
            .required("Country is required"),
          timezone: Yup.string()
            .min(2, "Choose a valid timezone!")
            .required("Timezone is required")
        })
      });
    } else if (value?.type === 1) {
      return Yup.object({
        type: Yup.number()
          .integer()
          .min(0, "Choose a valid location type!")
          .max(3, "Choose a valid location type!")
          .required("Location type is required"),
        parameters: Yup.object({
          city: Yup.string()
            .min(2)
            .required("City is required"),
          country: Yup.string()
            .min(2, "Choose a valid country!")
            .max(3, "Choose a valid country!")
            .required("Country is required"),
          timezone: Yup.string()
            .min(2, "Choose a valid timezone!")
            .required("Timezone is required")
        })
      });
    } else if (value?.type === 2) {
      return Yup.object({
        type: Yup.number()
          .integer()
          .min(0, "Choose a valid location type!")
          .max(3, "Choose a valid location type!")
          .required("Location type is required"),
        parameters: Yup.object({
          country: Yup.string()
            .min(2, "Choose a valid country!")
            .max(3, "Choose a valid country!")
            .required("Country is required")
        })
      });
    }
    return Yup.object({
      type: Yup.number()
        .integer()
        .min(0, "Choose a valid location type!")
        .max(3, "Choose a valid location type!")
        .required("Location type is required")
    });
  }),
  method: Yup.lazy((value: any) => {
    if (value?.type === -1) {
      return Yup.object({
        type: Yup.number()
          .integer()
          .min(-2, "Choose a valid method type!")
          .max(7, "Choose a valid method type!")
          .required("Location type is required"),
        parameters: Yup.object({
          fajrAngle: Yup.number().required("Fajr angle is required"),
          ishaAngle: Yup.number().required("Isha angle is required")
        })
      });
    } else if (value?.type === -2) {
      return Yup.object({
        type: Yup.number()
          .integer()
          .min(-2, "Choose a valid method type!")
          .max(7, "Choose a valid method type!")
          .required("Location type is required"),
        parameters: Yup.object({
          fajrAngle: Yup.number().required("Fajr angle is required"),
          ishaTime: Yup.number().required("Isha time is required")
        })
      });
    }
    return Yup.object({
      type: Yup.number()
        .integer()
        .min(-2, "Choose a valid method type!")
        .max(7, "Choose a valid method type!")
        .required("Location type is required")
    });
  }),
  angleBasedMethod: Yup.number()
    .integer()
    .min(0, "Choose a valid angle based method!")
    .max(1, "Choose a valid angle based method!")
    .required("Angle based method is required"),
  asrMethod: Yup.number()
    .integer()
    .min(0, "Choose a valid asr method!")
    .max(1, "Choose a valid asr method!")
    .required("Asr method is required"),
  midnightMethod: Yup.number()
    .integer()
    .min(0, "Choose a valid midnight method!")
    .max(1, "Choose a valid midnight method!")
    .required("Midnight method is required"),
  hijri: Yup.number()
    .integer()
    .min(-5, "Choose a valid hijri!")
    .max(5, "Choose a valid hijri!")
    .required("Hijri method is required"),

  manual: Yup.object({
    sunrise: Yup.number()
      .integer()
      .min(-30, "Choose a valid sunrise!")
      .max(30, "Choose a valid sunrise!")
      .required("Sunrise method is required"),
    sunset: Yup.number()
      .integer()
      .min(-30, "Choose a valid sunset!")
      .max(30, "Choose a valid sunset!")
      .required("Sunset method is required"),
    midnight: Yup.number()
      .integer()
      .min(-30, "Choose a valid midnight!")
      .max(30, "Choose a valid midnight!")
      .required("Midnight method is required"),
    fajr: Yup.number()
      .integer()
      .min(-30, "Choose a valid fajr!")
      .max(30, "Choose a valid fajr!")
      .required("Fajr method is required"),
    dhuhr: Yup.number()
      .integer()
      .min(-30, "Choose a valid dhuhr!")
      .max(30, "Choose a valid dhuhr!")
      .required("Dhuhr method is required"),
    asr: Yup.number()
      .integer()
      .min(-30, "Choose a valid asr!")
      .max(30, "Choose a valid asr!")
      .required("Asr method is required"),
    maghrib: Yup.number()
      .integer()
      .min(-30, "Choose a valid maghrib!")
      .max(30, "Choose a valid maghrib!")
      .required("Maghrib method is required"),
    isha: Yup.number()
      .integer()
      .min(-30, "Choose a valid isha!")
      .max(30, "Choose a valid isha!")
      .required("Isha method is required"),
    sahur: Yup.number()
      .integer()
      .min(-30, "Choose a valid sahur!")
      .max(30, "Choose a valid sahur!")
      .required("Sahur method is required"),
    iftar: Yup.number()
      .integer()
      .min(-30, "Choose a valid iftar!")
      .max(30, "Choose a valid iftar!")
      .required("Iftar method is required")
  })
});
