// categories-table.component.tsx

import React, { Component } from "react";
import { Table, Divider } from "antd";
import * as _ from "lodash";
import { PaginationConfig } from "antd/lib/table";

import "./categories-table.component.css";
import { Category } from "../../models/category.model";
import { CategoriesService, SearchCategoryInput } from "../../services/categories/categories.service";
import { DesignOptions } from "../../data/design.options";
/*import { Search } from "./models/search.model";
import { Adjustment } from "./models/adjustment.model";*/

type Props = {
//  search: Search;
  onModifyClick: (value: any) => void;
  onDeleteClick: (value: any) => void;
};

type State = {
  data: Category[];
  pagination: PaginationConfig;
  totalCount: number;
  loading: boolean;
};

export class CategoriesTableComponent extends Component<Props, State> {
  private columns = [
    {
      title: "ID",
      dataIndex: "_id",
      render: (id: string) => `${id}`,
      key: "id",
      width: "15%"
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: any) => {
        const value = DesignOptions.Status[status];
        const className = value?.class;
        return (
          <span style={{ color: value?.color }}>
            <i
              className={className}
              style={{
                textAlign: "center",
                fontSize: "1em",
                marginRight: "10px"
              }}
            ></i>{" "}
            {value?.name}
          </span>
        );
      },
      key: "status",
      width: "10%"
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      width: "15%"
    },
    {
      title: "Translations",
      dataIndex: "translations",
      render: (translations: any) => {
        return (
          <span>
            {translations?.length || 0} translation
          </span>
        );
      },
      key: "translations",
      width: "30%"
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text: any, record: any) => (
        <span>
          <a onClick={() => {
            this.props.onModifyClick(record);
          }}>
            <i className="fas fa-edit" style={{ fontSize: "1.2em" }}></i> Modify
          </a>
          <Divider type="vertical" />
          <a onClick={() => {
            this.props.onDeleteClick(record);
          }}>
            <i className="fas fa-trash" style={{ fontSize: "1.2em" }}></i> Delete
          </a>
        </span>
      )
    }
  ];

  state = {
    data: [],
    pagination: {
      pageSize: 20,
      current: 1
    },
    totalCount: 0,
    loading: false
  };

  componentDidMount() {
    this.fetch();
  }

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    this.fetch(pagination);
  };

  fetch = (pagination?: any, reInit: boolean = false) => {
    //const { search } = this.props;

    this.setState({ loading: true });

    const newPagination = (pagination) ? {
      pageSize: pagination.pageSize,
      current: pagination.current || 1,
      total: pagination.totalCount
    } : this.state.pagination;

    if (reInit) {
      newPagination.current = 1;
    }

    const params: SearchCategoryInput = {
      perPage: newPagination.pageSize,
      page: newPagination.current - 1,
      //...search
    };
    CategoriesService.search(params)
      .then(response => {
        console.log("CategoriesService.search then.....");
        const data = response.data.categories.data;
        const meta = response.data.categories.meta;
        const pagination2 = {
          ...newPagination,
          current: meta.page+1,
          total: meta.totalCount
        };
        this.setState({
          data,
          pagination: pagination2,
          loading: false
        });
      })
      .catch(error => {});
  };

  render() {
    return (
      <Table
        columns={this.columns}
        rowKey={(record: any) => record._id}
        dataSource={this.state.data}
        pagination={this.state.pagination}
        loading={this.state.loading}
        onChange={this.handleTableChange}
      />
    );
  }
}
