import { Reducer } from 'redux';
import { CategoryState, CategoryActionTypes } from './types';

export const initialState: CategoryState = {
  count: 0
};

const reducer: Reducer<CategoryState> = (state = initialState, action) => {
  switch (action.type) {
    case CategoryActionTypes.COUNT: {
      const value = action.payload;
      console.log("CategoryActionTypes.COUNT");
      console.log(action);
      return { ...state, count: value };
      // return state;
      /*const value = state.search.get(action.payload);
      const search = new Map(state.search);
      if (!value) {
        search.set(action.payload, {
          value: action.payload,
          count: 1,
          lastModified: new Date(),
        });
        return { ...state, search };
      } else {
        search.set(action.payload, {
          value: action.payload,
          count: value.count + 1,
          lastModified: new Date(),
        });
        return { ...state, search };
      }*/
    }
    /*case CategoryActionTypes.SEARCH: {
      console.log('SearchActionTypes.REMOVE');
      const value = state.search.get(action.payload);
      if (value) {
        const search = new Map(state.search);
        search.delete(action.payload);
        return { ...state, search };
      } else {
        return state;
      }
      return state;
    }*/
    case CategoryActionTypes.FETCH_COUNT_SUCCESS: {
      console.log("CategoryActionTypes.FETCH_COUNT_SUCCESS");
      console.log(action);
      const value = action.data;
      return { ...state, count: value };
    }
    default: {
      return state;
    }
  }
};

export { reducer as categoryReducer };
