// categories.section.tsx

import React, { Component } from "react";
import { Layout, Button, Icon, Row, Col, Spin, message } from "antd";
import { Map, TileLayer, Marker, Popup, LatLng, Circle } from "react-leaflet";
import * as _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import "./categories.section.css";

import { RemoveModalComponent } from "./modals/remove-category.modal";
import { CategoriesTableComponent } from "./lists/categories-table.component";
import { CategoriesService } from "../services/categories/categories.service";
// import { SearchFormComponent } from "./forms/search.form";
/*import { AdjustmentTableComponent } from "./categories-table.component";
import { Search } from "./models/search.model";


import { AdjustmentsService } from "./services/adjustments.service";*/

import { count } from "../store/category/actions";


const { Header, Sider, Content } = Layout;


type PathParamsType = {};
type Props = RouteComponentProps<PathParamsType> & {
  countRequest: () => void;
};

type State = {
  // search: Search;
};

export class CategoriesSectionComponent extends Component<Props, State> {
  private _searchFormRef: any = null;
  private _categoriesTableRef: CategoriesTableComponent | null = null;
  private _modalRef: RemoveModalComponent | null = null;

  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      /*search: {
        mode: "coordinates",
        lat: MAP_DEFAULT.latitude,
        lon: MAP_DEFAULT.longitude,
        distance: MAP_DEFAULT.distance
      }*/
    };
  }

  /*updateState(value: Search) {
    const { search } = this.state;
    if (!_.isEqual(search, value)) {
      const searchClone = _.clone(value);
      if (searchClone.mode === "coordinates") {
        searchClone.lat = searchClone.lat || MAP_DEFAULT.latitude;
        searchClone.lon = searchClone.lon || MAP_DEFAULT.longitude;
        searchClone.distance = searchClone.distance || MAP_DEFAULT.distance;
        const mapClone = _.clone(map);
        mapClone.latitude = searchClone.lat;
        mapClone.longitude = searchClone.lon;
        this.setState({ search: searchClone, map: mapClone });
      } else {
        this.setState({ search: searchClone });
      }
    }
  }*/

  render() {
    const { history, countRequest } = this.props;
    // const { search } = this.state;
    return (
      <div style={{ margin: "2em" }}>
        <RemoveModalComponent
          ref={ref => (this._modalRef = ref)}
          onRemove={id => {
            CategoriesService.remove(id).then(() => {
              message.success("This category was removed");
              setTimeout(() => {
                this._categoriesTableRef?.fetch();
              }, 100);
              countRequest();
            }).catch(error => {
              message.error(
                "This category was NOT removed, error: " +
                  error.toString()
              );
            });
            this._modalRef?.close();
          }}
        />
        <Row>
          <Col span={3}>
            <Button
              type="primary"
              onClick={() => {
                history.push("/dua/categories");
              }}
            >
              <i className="fas fa-plus" style={{ marginRight: "6px" }}></i>
              Add Category
            </Button>
          </Col>
          <Col span={8}>
            {/*<SearchFormComponent
              innerRef={(ref: any) => (this._searchFormRef = ref)}
              initValue={search}
              onChange={this.updateState.bind(this)}
              onSubmit={() => {
                console.log("Submit.....");
                this._adjustmentTableRef?.fetch(null, true);
              }}
            />*/}
          </Col>
          <Col span={13}>
          </Col>
        </Row>
        <div style={{ marginTop: "2em" }}>
          <CategoriesTableComponent
            ref={ref => (this._categoriesTableRef = ref)}
            // search={search}
            onModifyClick={value => {
              history.push("/dua/categories/" + value._id);
            }}
            onDeleteClick={value => {
              this._modalRef?.open(value._id);
            }}
          />
        </div>
      </div>
    );
  }
}

/*export const AdjustmentsSectionContainer = withRouter(
  AdjustmentsSectionComponent
);*/

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      countRequest: () => dispatch(count()),
    },
    dispatch,
  );
};

export const CategoriesSectionContainer = withRouter(connect(
  null,// mapStateToProps,
  mapDispatchToProps,
)(CategoriesSectionComponent));
