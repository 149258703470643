// info-card.component.tsx

import React from 'react';
import { Card } from "antd";

import './info-card.component.css';

type Props = {
  title: string;
  icon?: React.ReactElement;
  value: number;
};

export const InfoCardComponent: React.FC<Props> = ({ title, value, icon }: Props) => {
  return (
    <Card className="info-card">
      <p className="title">
        <span className="icon">{icon}</span> {title}
      </p>
      <p className="value">{value}</p>
    </Card>
  );
};
