export const DesignOptions = {
	Status: [
		{ name: 'Disabled', class: 'fas fa-circle', color: '#FF3B30'},
		{ name: 'Enabled', class: 'fas fa-circle', color: '#4CD964'}
	],
	Creators: [
		{ name: 'Admin', class: 'fas fa-user-shield', color: '#00695C'},
		{ name: 'Mosque', class: 'fas fa-mosque', color: '#4E342E'},
		{ name: 'User', class: 'fas fa-user', color: '#546E7A'}
	],
	Locations: [
		{ name: 'GPS', class: 'fas fa-map-marker-alt', color: '#C62828'},
		{ name: 'City', class: 'fas fa-city', color: '#4E342E'},
		{ name: 'Country', class: 'fas fa-flag', color: '#0D47A1'},
		{ name: 'World', class: 'fas fa-globe-europe', color: '#546E7A'}
	]
};
