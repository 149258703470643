// location-adjustment.form.tsx

import React, { Component } from "react";
import { Input, Select } from "formik-antd";
import * as _ from "lodash";

import { DataOptions } from "../../data/adjustments-data.options";
import { createSimpleField } from "../../../../components/create-simple.form";

const { Option } = Select;

type Props = {
  errors: any;
  touched: any;
  values: any;
};

type State = {};

export class LocationAdjustmentFormComponent extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
  }

  locationValue() {
    return DataOptions.locationType.map(type => (
      <Option value={type.value}>{type.name}</Option>
    ));
  }

  countriesValue() {
    return DataOptions.countries.map(country => (
      <Option value={country.code}>{country.name}</Option>
    ));
  }

  timezonesValue() {
    return DataOptions.timezones.map(timezone => (
      <Option value={timezone.value}>{timezone.name}</Option>
    ));
  }

  render() {
    const { errors, touched, values } = this.props;
    return (
      <div>
        {createSimpleField(
          "location.type",
          "Type",
          values,
          errors,
          touched,
          <Select
            name="location.type"
            placeholder="Select a location type"
            allowClear
          >
            {this.locationValue()}
          </Select>
        )}
        {createSimpleField(
          "location.parameters.latitude",
          "Latitude",
          values,
          errors,
          touched,
          <Input name="location.parameters.latitude" placeholder="Enter a latitude"/>,
          values.location?.type !== 0
        )}
        {createSimpleField(
          "location.parameters.longitude",
          "Longitude",
          values,
          errors,
          touched,
          <Input name="location.parameters.longitude" placeholder="Enter a longitude"/>,
          values.location?.type !== 0
        )}
        {createSimpleField(
          "location.parameters.city",
          "City",
          values,
          errors,
          touched,
          <Input name="location.parameters.city" placeholder="Enter a city"/>,
          values.location?.type !== 0 && values.location?.type !== 1
        )}
        {createSimpleField(
          "location.parameters.country",
          "Country",
          values,
          errors,
          touched,
          <Select
            name="location.parameters.country"
            placeholder="Select a country"
            allowClear
          >
            {this.countriesValue()}
          </Select>,
          values.location?.type !== 0 &&
            values.location?.type !== 1 &&
            values.location?.type !== 2
        )}
        {createSimpleField(
          "location.parameters.timezone",
          "Timezone",
          values,
          errors,
          touched,
          <Select
            name="location.parameters.timezone"
            placeholder="Select a timezone"
            allowClear
          >
            {this.timezonesValue()}
          </Select>,
          values.location?.type !== 0 && values.location?.type !== 1
        )}
      </div>
    );
  }
}
