// categories.service.ts

import { Observable } from "rxjs";
import { gql, ApolloQueryResult, FetchResult } from "apollo-boost";
import {
  HelperService,
  search,
  get,
  update,
  create,
  remove,
  count
} from "../../../../services/helper.service";
import { clientDua } from "../graphql.module";

const FRAGMENT_CATEGORY = gql`
  fragment CategoryFragment on Category {
    _id
    createdAt
    modifiedAt
    status
    value
    image
  }
`;

export interface CategoryInput {
  status: number;
  value: string;
  image: string;
}

export interface CategoryTranslationInput {
  category: string;
  language: string;
  title: string;
  meta: string;
  keywords: string;
}

export interface SearchCategoryInput {
  perPage: number;
  page: number;
}

export interface SearchCategoryTranslationInput {
  perPage: number;
  page: number;
  category: string;
}

export class CategoriesService2 {
  /*private _helperService: HelperService;

	public create(input: CategoryInput): Observable<any> {
		return this._helperService.create<CategoryInput>(
			'createCategory',
			'CategoryInput',
			FRAGMENT_CATEGORY,
			input
		);
	}

	public update(id: string, input: CategoryInput): Observable<any> {
		return this._helperService.update<CategoryInput>(
			'updateCategory',
			'CategoryInput',
			FRAGMENT_CATEGORY,
			id,
			input
		);
	}

	*/
  // -------------------------------------------------------
  /*public getAllTranslation(input: SearchCategoryTranslationInput): Observable<any> {
		return this._helperService.getAll<SearchCategoryTranslationInput>(
			'categoryTranslations',
			'SearchCategoryTranslationInput',
			FRAGMENT_CATEGORY_TRANSLATION,
			input
		);
	}

	public getTranslation(id: string): Observable<any> {
		return this._helperService.get(
			'categoryTranslation',
			FRAGMENT_CATEGORY_TRANSLATION,
			id
		);
	}

	public createTranslation(input: CategoryTranslationInput): Observable<any> {
		return this._helperService.create<CategoryTranslationInput>(
			'createCategoryTranslation',
			'CategoryTranslationInput',
			FRAGMENT_CATEGORY_TRANSLATION,
			input
		);
	}

	public updateTranslation(
		id: string,
		input: CategoryTranslationInput
	): Observable<any> {
		return this._helperService.update<CategoryTranslationInput>(
			'updateCategoryTranslation',
			'CategoryTranslationInput',
			FRAGMENT_CATEGORY_TRANSLATION,
			id,
			input
		);
	}

	public removeTranslation(id: string): Observable<any> {
		return this._helperService.remove('removeCategoryTranslation', id);
	}

	public countTranslations(): Observable<any> {
		return this._helperService.count('countCategoryTranslations');
	}*/
}

export class CategoriesService {
  //protected QUERY_GET_ALL = this.createQueryGetAll('adjustments', FRAGMENT);

  public static search(
    input: SearchCategoryInput
  ): Promise<ApolloQueryResult<any>> {
    const query = HelperService.createQuerySearch(
      "categories",
      "SearchCategoryInput",
      FRAGMENT_CATEGORY,
      "CategoryFragment"
    );
    return search(clientDua, input, query);
  }

  public static get(id: string): Promise<ApolloQueryResult<any>> {
    const query = HelperService.createQueryGet(
      "category",
      FRAGMENT_CATEGORY,
      "CategoryFragment"
    );
    return get(clientDua, id, query);
  }

  public static create(input: CategoryInput): Promise<FetchResult<any>> {
    const query = HelperService.createQueryCreate(
      "createCategory",
      "CategoryInput",
      FRAGMENT_CATEGORY,
      "CategoryFragment"
    );
    return create(clientDua, input, query);
  }

  public static update(id: string, input: CategoryInput): Promise<FetchResult<any>> {
		const query = HelperService.createQueryUpdate('updateCategory', 'CategoryInput', FRAGMENT_CATEGORY, "CategoryFragment");
		return update(clientDua, id, input, query);
	}

  public static remove(id: string): Promise<FetchResult<any>> {
    const query = HelperService.createQueryRemove("removeCategory");
    return remove(clientDua, id, query);
  }

  public static count(): Promise<ApolloQueryResult<any>> {
    const query = HelperService.createQueryCount("countCategories");
    return count(clientDua, query);
  }

  /* ------------------------------------------------------------------------------------------- */
  public static searchTranslation(
    input: SearchCategoryInput
  ): Promise<ApolloQueryResult<any>> {
    const query = HelperService.createQuerySearch(
      "categories",
      "SearchCategoryInput",
      FRAGMENT_CATEGORY,
      "CategoryFragment"
    );
    return search(clientDua, input, query);
  }

  public static getTranslation(id: string): Promise<ApolloQueryResult<any>> {
    const query = HelperService.createQueryGet(
      "category",
      FRAGMENT_CATEGORY,
      "CategoryFragment"
    );
    return get(clientDua, id, query);
  }

  public static createTranslation(
    input: CategoryTranslationInput
  ): Promise<FetchResult<any>> {
    const query = HelperService.createQueryCreate(
      "createCategoryTranslation",
      "CategoryTranslationInput",
      FRAGMENT_CATEGORY,
      "CategoryFragment"
    );
    return create(clientDua, input, query);
  }

  /*protected MUTATION_CREATE = this.createQueryCreate(
		'createAdjustment',
		'AdjustmentInput',
		FRAGMENT
	);
	protected MUTATION_UPDATE = this.createQueryUpdate(
		'updateAdjustment',
		'AdjustmentInput',
		FRAGMENT
	);
	protected MUTATION_REMOVE = this.createQueryRemove('removeAdjustment');

	protected QUERY_COUNT = this.createQueryCount('countAdjustments');


	public search(input: SearchAdjustmentParams): Observable<any> {
		return this._helperService.getAll<SearchAdjustmentParams>(
			'adjustments',
			'SearchAdjustmentInput',
			FRAGMENT,
			input
		);
	}*/
}
