// location.form.tsx

import React, { Component } from "react";
import { Form, Input, FormItem, Select } from "formik-antd";
import { Formik } from "formik";
import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { DataOptions } from "../../data/adjustments-data.options";
import { LocationSchema } from "../../validators/location.validator";
import { Location } from "../../models/location.model";
import { createSimpleField } from "../../../../components/create-simple.form";
import { Divider } from "antd";

const { Option } = Select;

type Props = {
  location: Location;
  innerRef: any;
  onChange: (value: Location) => void;
};

type State = {};

class LocationFormComponent extends Component<Props, State> {
  private _updateSubject: BehaviorSubject<Location>;

  constructor(props: Props, state: State) {
    super(props, state);

    this._updateSubject = new BehaviorSubject<Location>(props.location);
    this._updateSubject.pipe(debounceTime(500)).subscribe(values => {
      const { onChange } = this.props;
      onChange(values);
    });
  }

  countriesValue() {
    return DataOptions.countries.map(country => (
      <Option value={country.code}>{country.name}</Option>
    ));
  }

  timezonesValue() {
    return DataOptions.timezones.map(timezone => (
      <Option value={timezone.value}>{timezone.name}</Option>
    ));
  }

  updateState(value: Location) {
    this._updateSubject.next(value);
  }

  componentDidMount() {}

  render() {
    const { location, innerRef } = this.props;
    return (
      <Formik
        innerRef={innerRef}
        onSubmit={() => {}}
        initialValues={location}
        validationSchema={LocationSchema}
        enableReinitialize={true}
        render={({ errors, touched, values, handleReset }) => {
          this.updateState(values);
          return (
            <Form
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 12 }}
              key={"locationForm"}
            >
              <Divider orientation="left">Main Location</Divider>
              {createSimpleField(
                "latitude",
                "Latitude",
                values,
                errors,
                touched,
                <Input name="latitude" placeholder="Enter a latitude"/>
              )}
              {createSimpleField(
                "longitude",
                "Longitude",
                values,
                errors,
                touched,
                <Input name="longitude" placeholder="Enter a longitude"/>
              )}
              {createSimpleField(
                "city",
                "City",
                values,
                errors,
                touched,
                <Input name="city" placeholder="Enter a city"/>
              )}
              {createSimpleField(
                "country",
                "Country",
                values,
                errors,
                touched,
                <Select
                  name="country"
                  placeholder="Select a country"
                  allowClear
                >
                  {this.countriesValue()}
                </Select>
              )}
              {createSimpleField(
                "timezone",
                "Timezone",
                values,
                errors,
                touched,
                <Select
                  name="timezone"
                  placeholder="Select a timezone"
                  allowClear
                >
                  {this.timezonesValue()}
                </Select>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

export const LocationFormContainer = LocationFormComponent;
