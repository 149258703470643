// adjustment-detail.page.tsx

import React, { Component, useRef } from "react";
import {
  Layout,
  Row,
  Col,
  Modal,
  Result,
  Icon,
  message,
  Button,
  Divider,
  Select
} from "antd";
import { Map, TileLayer, Marker } from "react-leaflet";
import { forkJoin } from "rxjs";
import * as _ from "lodash";
import { keys } from "ts-transformer-keys";
import { withRouter, RouteComponentProps } from "react-router-dom";

import "./category-detail.page.css";

// import { CategoriesTableComponent } from "./categories-table.component";

import { CategoryFormComponent } from "./forms/category.form";
import { Category } from "../models/category.model";
import { deepCleaner, read } from "../../../utils/deep-cleaner.util";
import { CategoriesService, CategoryInput } from "../services/categories/categories.service";
import { CategoryTranslationsService, CategoryTranslationInput } from "../services/categories/category-translations.service";
import { TranslationsTableComponent } from "./lists/translations-table.component";
import { TranslationDetailModalComponent } from "./modals/translation-detail.modal";
import { DataOptions } from "../data/categories-data.options";
import { RemoveModalComponent } from "./modals/remove-category.modal";

const { Content } = Layout;
const { Option } = Select;

type PathParamsType = {};
type Props = RouteComponentProps<PathParamsType> & {
  match?: any;
};

type State = {
  isModify: boolean;
  loading: boolean;
  initValueCategory: Category;
  valueCategory: Category;
};

export class CategoryDetailPageComponent extends Component<Props, State> {
  private _categoryFormRef: any = null;
  private _translationModalRef: any = null;
  private _translationsTableRef: TranslationsTableComponent | null = null;
  private _removeModalRef: RemoveModalComponent | null = null;

  constructor(props: Props, state: State) {
    super(props, state);
    const category = {
      status: 1,
      name: "",
      image: "",
      translations: []
    };
    this.state = {
      isModify: Object.keys(this.props.match?.params).length > 0,
      loading: Object.keys(this.props.match?.params).length > 0,
      initValueCategory: category,
      valueCategory: category
    };
  }

  languagesValue() {
    const { valueCategory } = this.state;
    console.log(Object.keys(DataOptions.languages).length);
    /*console.log(
      _.without(
        Object.keys(DataOptions.languages),
        ...valueCategory.translations.map(translation => translation.language)
      ).length
    );
    return _.without(
      Object.keys(DataOptions.languages),
      ...valueCategory.translations.map(translation => translation.language)
    ).map(language => {
      const detail = _.get(DataOptions.languages, language);
      const title =
        detail.name + " - " + detail.nativeName + "  (" + language + ")";
      return <Option value={language}>{title}</Option>;
    });*/
  }

  componentDidMount() {
    console.log(this.props.match);
    if (Object.keys(this.props.match?.params).length > 0) {
      const { id } = this.props.match.params;
      CategoriesService.get(id).then(response => {
        const category = response.data.category.data;
        this.setState({
          loading: false,
          initValueCategory: category
        });
      });
    }
  }

  updateCategoryState(value: Category) {
    const { valueCategory } = this.state;
    if (!_.isEqual(valueCategory, value)) {
      const category = _.clone(value);
      this.setState({ valueCategory: category });
    }
  }

  render() {
    const { history } = this.props;
    const { isModify, loading, initValueCategory, valueCategory } = this.state;

    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Result icon={<Icon type="loading" />} title="Loading category..." />
        </div>
      );
    } else {
      return (
        <>
        <RemoveModalComponent
          ref={ref => (this._removeModalRef = ref)}
          onRemove={id => {
            CategoryTranslationsService.remove(id).then(() => {
              message.success("This translation was removed");
              setTimeout(() => {
                this._translationsTableRef?.fetch();
              }, 100);
            }).catch(error => {
              message.error(
                "This translation was NOT removed, error: " +
                  error.toString()
              );
            });
            this._removeModalRef?.close();
          }}
        />
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280
          }}
        >
          <h1 style={{ fontSize: "1.8em", fontWeight: 600 }}>Category</h1>
          <div
            style={{
              backgroundColor: "#fff",
              marginTop: "30px",
              padding: "15px"
            }}
          >
            <Row>
              <Col span={16}>
                <CategoryFormComponent
                  innerRef={(ref: any) => (this._categoryFormRef = ref)}
                  isModify={isModify}
                  initValue={initValueCategory}
                  isEqual={_.isEqual(initValueCategory, valueCategory)}
                  onChange={this.updateCategoryState.bind(this)}
                  onSubmit={() => {
                    console.log("Submit..........");
                    console.log(valueCategory);
                    const value: any = deepCleaner<CategoryInput>(
                      _.clone(valueCategory)
                    );
                    value["_id"] = undefined;
                    value["createdAt"] = undefined;
                    value["modifiedAt"] = undefined;
                    if (isModify) {
                      const { id } = this.props.match.params;
                      CategoriesService.update(id, value)
                        .then(value => {
                          message.success("This category was updated");
                          history.push("/dua");
                          history.push(this.props.match.url);
                        })
                        .catch(error => {
                          message.error(
                            "This category was NOT updated, error: " +
                              error.toString()
                          );
                        });
                    } else {
                      CategoriesService.create(value)
                        .then(value => {
                          message.success("This category was created");
                          history.push("/dua");
                          history.push("/dua/categories");
                        })
                        .catch(error => {
                          message.error(
                            "This category was NOT created, error: " +
                              error.toString()
                          );
                        });
                    }
                  }}
                />
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Divider orientation="left">Translations</Divider>
              <TranslationDetailModalComponent
                ref={ref => (this._translationModalRef = ref)}
                languages={this.languagesValue()}
                onSave={id => {
                  console.log("[TranslationDetailModalComponent] onSave....");
                  const params = {
                    category: id,
                    language: "fr",
                    status: 1,
                    title: "Titre",
                    meta: "meta description",
                    keywords: "keyword, keyword, keyword"
                  };
                  CategoryTranslationsService.create(params)
                    .then(() => {
                      message.success("This translation was created");
                      setTimeout(() => {
                        this._translationsTableRef?.fetch();
                      }, 100);
                    })
                    .catch((error: any) => {
                      message.error(
                        "This translation was NOT created, error: " +
                          error.toString()
                      );
                    });
                  this._translationModalRef?.close();
                }}
              />
              <Button
                style={{ margin: "10px 0 30px" }}
                type="primary"
                onClick={() => {
                  this._translationModalRef?.open(initValueCategory._id);
                }}
              >
                <i className="fas fa-plus" style={{ marginRight: "6px" }}></i>
                Add Translation
              </Button>
              <TranslationsTableComponent
                ref={ref => (this._translationsTableRef = ref)}
                category={initValueCategory._id?.toString() || ''}
                onModifyClick={(value) => {
                  this._translationModalRef?.open(initValueCategory._id, value);
                }}
                onDeleteClick={(value) => {
                  this._removeModalRef?.open(value._id);
                }}
              />
            </Row>
          </div>
        </Content>
        </>
      );
    }
  }
}

export const CategoryDetailPageContainer = withRouter(
  CategoryDetailPageComponent
);
