// auth.service.ts

import { Observable } from "rxjs";
import Axios, { AxiosResponse } from "axios";
import Superagent from "superagent";

export type SigninStep1Params = {
  username: string;
  password: string;
};

export type SigninStep2Params = {
  state: string;
  token: string;
};

/* Axios.interceptors.response.use(null, error => {
  console.log("inside INTERCEPTOR");
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  console.log(JSON.stringify(error));
  if (!expectedError) {
    // Unexpected Error
    // toast.error("Unexpected Error Occurred");
    // logger.log(error);
    console.log(error);
  }
  return Promise.reject(error);
}); */

export class AuthService {
  private static HOST = "http://localhost:8991";

  public static signinStep1(value: SigninStep1Params): Promise<Superagent.Response> {
    /* const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json"
      },
			withCredentials: true,
			credentials: 'same-origin',
			crossdomain: true
    };
		return Axios.post(`/api/signin`, value, config); */
		return new Promise((resolve, reject) => {
			Superagent
  		.post('/api/signin1')
  		.send(value)
			.set('Accept', 'application/json')
			.withCredentials()
			.then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		});
	}
	
	public static signinStep2(value: SigninStep2Params): Promise<Superagent.Response> {
		return new Promise((resolve, reject) => {
			Superagent
  		.post('/api/signin2')
  		.send(value)
			.set('Accept', 'application/json')
			.withCredentials()
			.then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		});
  }

  public static signout(): Promise<Superagent.Response> {
		return new Promise((resolve, reject) => {
			Superagent
  		.get('/api/signout')
			.set('Accept', 'application/json')
			.withCredentials()
			.then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		});
  }

  public static checkAuth(): Promise<Superagent.Response> {
		return new Promise((resolve, reject) => {
			Superagent
  		.get('/api/checkauth')
			.set('Accept', 'application/json')
			.withCredentials()
			.then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		});
  }
}
