// main.page.tsx

import React, { Component } from "react";
import Button from "antd/es/button";
import { Layout, Menu, Icon, Tabs } from "antd";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import "./main.page.css";
import { InfoCardComponent } from "../../../components/info-card.component";
import { ApplicationState } from "../../../store";
// import { InfoCardComponent } from "../../components/info-card.component";
/*import { AdjustmentsSectionContainer } from "./adjustments.section";
import { AdjustmentsService } from "./services/adjustments.service";
import { ApplicationState } from "../../store";
import { AdjustmentState } from "./store/adjustment/types";

import { count } from "./store/adjustment/actions";*/


const { Header, Sider, Content } = Layout;
const { TabPane } = Tabs;

type Props = {
  // adjustments: AdjustmentState;
  // countRequest: () => void;
};

type State = {
  adjustments: number;
  lat: number;
  lng: number;
  zoom: number;
};

class MainPageComponent extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13,
      adjustments: 0
    };
  }

  componentDidMount() {
    // this.props.countRequest();
  }

  render() {
    // const { adjustments } = this.props;

    return (
      <Content
        style={{
          margin: "24px 16px",
          padding: 24,
          minHeight: 280
        }}
      >
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <InfoCardComponent
            title={"Duas"}
            value={0}
            icon={<i className="fas fa-adjust"></i>}
          />
        </div>

        <div style={{ backgroundColor: "#fff", padding: "1em" }}>
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={
                <span>
                  <i className="fas fa-adjust" style={{ fontSize: "1.4em" }}></i> Adjustments
                </span>
              }
              key="1"
            >
              Tab 1
            </TabPane>
            <TabPane
              tab={
                <span>
                  <i className="fas fa-mosque" style={{ fontSize: "1.4em" }}></i> Mosques
                </span>
              }
              key="2"
            >
              Tab 2
            </TabPane>
          </Tabs>
        </div>
      </Content>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    adjustments: state.adjustment,
  };
};

/*const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      countRequest: () => dispatch(count()),
      // removeBookmark: (id: string) => dispatch(remove(id)),
    },
    dispatch,
  );
};*/

export const MainPageContainer = connect(
  mapStateToProps,
  // mapDispatchToProps,
)(MainPageComponent);
