// generic-detail.modal.tsx

import React, { Component, ReactNode } from "react";
import { Button } from "antd";
import * as _ from "lodash";
import * as Yup from "yup";

import { GenericModalComponent } from "../../../components/generic-modal.component";

export type Props = {
  onSave: (id: string) => void;
};

export type State<T> = {
  initValue: T;
  value?: T;
  id: string;
  isModify: boolean;
};

export abstract class GenericDetailModalComponent<
  T,
  P extends Props
> extends Component<P, State<T>> {
  private _modalRef: GenericModalComponent | null = null;
  private _formRef: any | null = null;

  constructor(props: P, state: State<T>) {
    super(props, state);
    const value = this.defaultValue();
    this.state = {
      initValue: value,
      value: value,
      id: "",
      isModify: false
    };
  }
  abstract defaultValue(): T;

  abstract renderForm(
    ref: any,
    isModify: boolean,
    initValue: T,
    isEqual: boolean,
    onChange: any
  ): ReactNode;

  abstract isValid(value: T | undefined): boolean;

  open(id: string, initValue?: T) {
    if (initValue) {
      this.setState({ id, initValue, value: initValue, isModify: true });
    } else {
      const initValue = this.defaultValue();
      this.setState({ id, initValue, value: initValue, isModify: false });
    }
    this._modalRef?.open();
  }

  close() {
    this._modalRef?.close();
  }

  updateValueState(newValue: T) {
    const { value } = this.state;
    console.log(newValue);
    console.log(value);
    if (!_.isEqual(newValue, value)) {
      console.log("updateValueState");
      const translation = _.clone(newValue);
      this.setState({ value: translation });
    }
  }

  componentDidMount() {}

  render() {
    const { onSave } = this.props;
    const { initValue, value, id } = this.state;
    const isModify = false;

    const isEqual = _.isEqual(initValue, value);
    return (
      <GenericModalComponent
        ref={ref => (this._modalRef = ref)}
        title="Translation"
        footer={[
          <Button
            key={"cancelButton"}
            onClick={() => {
              this._modalRef?.close();
            }}
          >
            <i className="fas fa-ban" style={{ marginRight: "6px" }}></i> Cancel
          </Button>,
          <Button
            key={"saveButton"}
            type="primary"
            disabled={!(this.isValid(value) && !isEqual)}
            onClick={() => {
              onSave(id);
            }}
          >
            <i className="fas fa-save" style={{ marginRight: "6px" }}></i> Save
          </Button>
        ]}
      >
        {this.renderForm(
          this._formRef,
          isModify,
          initValue,
          isEqual,
          this.updateValueState.bind(this)
        )}
      </GenericModalComponent>
    );
  }
}
