// create-simple.form.tsx

import React, { ReactNode } from "react";
import * as _ from "lodash";
import {
  FormItem
} from "formik-antd";

export const createSimpleField = (name: string, label: string, values: any, errors: any, touched: any, input: ReactNode, disabled: boolean = false) => {
  if (disabled) {
    _.set(values, name, undefined);
  }
  return (
    <FormItem
      style={{ display: disabled ? "none" : "" }}
      name={label}
      label={label}
      validateStatus={
        _.get(errors, name) && _.get(touched, name) ? "error" : ""
      }
      help={
        _.get(errors, name) && _.get(touched, name)
          ? _.get(errors, name)
          : ""
      }
    >
      {input}
    </FormItem>
  );
};
