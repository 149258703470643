// routing.tsx

import React from "react";

import { MainPageContainer as MainPage } from "./main.page";
import { MainPageContainer as DuasMainPage } from "./duas/main.page";
import { CategoryDetailPageContainer } from "./categories/category-detail.page";
import { RouteData } from "../../main.page";

export const DuaRouting: RouteData[] = [
  {
    key: "/dua/duas",
    path: "/dua/duas",
    component: DuasMainPage
  },
  {
    key: "/dua/categories",
    path: "/dua/categories",
    component: CategoryDetailPageContainer,
    exact: true
  },
  {
    key: "/dua/categories/:id",
    path: "/dua/categories/:id",
    component: CategoryDetailPageContainer,
    exact: true
  },
  {
    key: "/dua",
    path: "/dua",
    component: MainPage,
    exact: true
  }
];
