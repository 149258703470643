import React from 'react';
import logo from './logo.svg';
import './App.css';
import MainPageComponent from './app/main.page';
// import '@fontawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/free-solid-svg-icons';

const App: React.FC = () => {
  return (
      <MainPageComponent></MainPageComponent>
  );
}

export default App;
