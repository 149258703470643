// routing.tsx

import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import { MainPageContainer as PrayertimesMainPage } from "./main.page";
import { AdjustmentDetailPageContainer } from "./adjustments/adjustment-detail.page";
import { RouteData } from "../../main.page";

/*export const PrayertimesRouting: React.FC = () => {
  return (
    <Switch>
      <Route path="/prayertimes" exact={true}>
        <PrayertimesMainPage />
      </Route>
      <Route path="/prayertimes/adjustments" exact={true}>
        <AdjustmentDetailPageContainer />
      </Route>
      <Route path="/prayertimes/adjustments/:id" component={AdjustmentDetailPageContainer}>
      </Route>
    </Switch>
  );
};*/

export const PrayertimesRouting: RouteData[] = [
  {
    key: "/prayertimes",
    path: "/prayertimes",
    component: PrayertimesMainPage,
    exact: true
  },
  {
    key: "/prayertimes/adjustments",
    path: "/prayertimes/adjustments",
    component: AdjustmentDetailPageContainer,
    exact: true
  },
  {
    key: "/prayertimes/adjustments/:id",
    path: "/prayertimes/adjustments/:id",
    component: AdjustmentDetailPageContainer
  }
];
