// category.form.tsx

import React, { Component, Ref } from "react";
import { Row, Col, Divider, Button, Tabs } from "antd";
import * as _ from "lodash";
import Moment from "react-moment";
import { Form, Input, FormItem, Select } from "formik-antd";
import { Formik, FieldArray, Field } from "formik";
import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import "./category.form.css";

import { DataOptions } from "../../data/categories-data.options";
import { Category } from "../../models/category.model";

import { CategorySchema } from "../../validators/category.validator";
import { createSimpleField } from "../../../../components/create-simple.form";

const { Option } = Select;
const { TabPane } = Tabs;

type Props = {
  initValue: Category;
  isModify: boolean;
  isEqual: boolean;
  onChange: (value: Category) => void;
  innerRef: any;
  onSubmit: () => void;
};

type State = {
  value: Category;
  activeKey: string;
};

export class CategoryFormComponent extends Component<Props, State> {
  private formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };

  private _updateSubject: BehaviorSubject<Category>;

  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      value: props.initValue,
      activeKey: ""
    };
    this._updateSubject = new BehaviorSubject<Category>(props.initValue);
    this._updateSubject.pipe(debounceTime(500)).subscribe(values => {
      const { onChange } = this.props;
      const category = _.clone(values);
      delete (category as any)['languages'];
      onChange(category);
    });
  }

  onChange = (activeKey: string) => {
    this.setState({ activeKey });
  };

  onEdit = (
    targetKey: string | React.MouseEvent<HTMLElement, MouseEvent>,
    action: "add" | "remove"
  ) => {
    this[action](targetKey as string);
  };

  add = (language: string) => {
    const { value } = this.state;
    const activeKey = language;
    value.translations.push({
      language: language,
      status: 1,
      title: "Titre",
      meta: "description....",
      keywords: "keywords, keywords"
    });
    this.setState({ value, activeKey });
  };

  remove = (targetKey: string) => {
    let { value, activeKey } = this.state;
    let lastIndex: number = 0;

    _.remove(value.translations, value => value.language === targetKey);
    const active =
      value.translations.length > 0 ? value.translations[0].language : "";
    this.setState({ value, activeKey: active });
  };

  statusValue() {
    return DataOptions.status.map(status => (
      <Option value={status.value}>{status.name}</Option>
    ));
  }

  languagesValue() {
    const { value } = this.state;
    console.log(Object.keys(DataOptions.languages).length);
    console.log(
      _.without(
        Object.keys(DataOptions.languages),
        ...value.translations.map(translation => translation.language)
      ).length
    );
    return _.without(
      Object.keys(DataOptions.languages),
      ...value.translations.map(translation => translation.language)
    ).map(language => {
      const detail = _.get(DataOptions.languages, language);
      const title =
        detail.name + " - " + detail.nativeName + "  (" + language + ")";
      return <Option value={language}>{title}</Option>;
    });
  }

  updateState(value: Category) {
    console.log("updateState...");
    this._updateSubject.next(value);
  }

  componentDidMount() {}

  render() {
    const { initValue, isModify, onSubmit, isEqual } = this.props;
    const { value } = this.state;
    const idCreateModifyDate = () => {
      return isModify ? (
        <div>
          <FormItem name="ID" label="ID">
            {initValue?._id}
          </FormItem>
          <FormItem name="Created" label="Created">
            <Moment>{Number(initValue?.createdAt)}</Moment>
          </FormItem>
          <FormItem name="Modified" label="Modified">
            <Moment>{Number(initValue?.modifiedAt)}</Moment>
          </FormItem>
        </div>
      ) : null;
    };
    return (
      <Formik
        innerRef={this.props.innerRef}
        onSubmit={() => {
          console.log("onSubmit........");
        }}
        initialValues={value}
        validationSchema={CategorySchema}
        enableReinitialize={true}
        render={({ errors, touched, values, handleReset, setFieldValue }) => {
          this.updateState(values);
          return (
            <Form {...this.formItemLayout}>
              <Row>
                <Col span={12}>
                  <Row
                    style={{
                      backgroundColor: "#F0F0F0",
                      padding: "15px",
                      textAlign: "center"
                    }}
                  >
                    <Col span={12}>
                      <Button
                        onClick={() => {
                          handleReset();
                        }}
                      >
                        <i
                          className="fas fa-ban"
                          style={{ marginRight: "6px" }}
                        ></i>{" "}
                        Cancel
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        type="primary"
                        disabled={
                          !(CategorySchema.isValidSync(values) && !isEqual)
                        }
                        onClick={() => {
                          onSubmit();
                        }}
                      >
                        <i
                          className="fas fa-save"
                          style={{ marginRight: "6px" }}
                        ></i>
                        Save
                      </Button>
                    </Col>
                  </Row>
                  {idCreateModifyDate()}
                  {createSimpleField(
                    "status",
                    "Status",
                    values,
                    errors,
                    touched,
                    <Select
                      name="status"
                      placeholder="Select a status"
                      allowClear
                    >
                      {this.statusValue()}
                    </Select>
                  )}
                  {createSimpleField(
                    "value",
                    "Value",
                    values,
                    errors,
                    touched,
                    <Input name="value" placeholder="Enter a value" />
                  )}
                  {createSimpleField(
                    "image",
                    "Image",
                    values,
                    errors,
                    touched,
                    <Input name="image" placeholder="Enter a image" />
                  )}
                </Col>
                <Col span={12}></Col>
              </Row>
              <Row>
                
                {/*<FieldArray
                  name="translates"
                  render={arrayHelpers => (
                    <div>
                      <div style={{ marginBottom: 16 }}>
                        <Select
                        style={{width: "30%", margin: "10px 50px 50px"}}
                          name={"languages"}
                          placeholder="Select a language"
                          allowClear
                        >
                          {this.languagesValue()}
                        </Select>
                        <Button
                          disabled={!(values as any)["languages"]}
                          onClick={() => {
                            this.add((values as any)["languages"]);
                            setFieldValue("languages", "");
                          }}
                        >
                          ADD
                        </Button>
                      </div>
                      <Tabs
                        hideAdd
                        onChange={this.onChange}
                        activeKey={this.state.activeKey}
                        type="editable-card"
                        onEdit={this.onEdit}
                      >
                        {values.translations.map((translation, index) => (
                          <TabPane
                            tab={translation.language}
                            key={translation.language}
                          >
                            {createSimpleField(
                              `translates[${index}].language`,
                              "Language",
                              values,
                              errors,
                              touched,
                              <Input
                                name={`translates[${index}].language`}
                                placeholder="Enter a language"
                              />
                            )}
                            {createSimpleField(
                              `translates[${index}].status`,
                              "Status",
                              values,
                              errors,
                              touched,
                              <Select
                                name={`translates[${index}].status`}
                                placeholder="Select a status"
                                allowClear
                              >
                                {this.statusValue()}
                              </Select>
                            )}
                            {createSimpleField(
                              `translates[${index}].title`,
                              "Title",
                              values,
                              errors,
                              touched,
                              <Input
                                name={`translates[${index}].title`}
                                placeholder="Enter a title"
                              />
                            )}
                            {createSimpleField(
                              `translates[${index}].metaDescription`,
                              "Meta Description",
                              values,
                              errors,
                              touched,
                              <Input
                                name={`translates[${index}].metaDescription`}
                                placeholder="Enter a meta description"
                              />
                            )}
                            {createSimpleField(
                              `translates[${index}].keywords`,
                              "Keywords",
                              values,
                              errors,
                              touched,
                              <Input
                                name={`translates[${index}].keywords`}
                                placeholder="Enter a keywords"
                              />
                            )}
                          </TabPane>
                        ))}
                      </Tabs>
                    </div>
                  )}
                            />*/}
              </Row>
            </Form>
          );
        }}
      />
    );
  }
}
