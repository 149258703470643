// adjustment.form.tsx

import React, { Component, Ref } from "react";
import { Row, Col, Divider } from "antd";
import Button from "antd/es/button";
import * as _ from "lodash";
import Moment from "react-moment";
import { Form, Input, FormItem, Select } from "formik-antd";
import { Formik } from "formik";
import { Subject, BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import "./adjustment.form.css";

import { DataOptions } from "../../data/adjustments-data.options";
import { Adjustment } from "../../models/adjustment.model";

import { CreatorAdjustmentFormComponent } from "./creator-adjustment.form";
import { LocationAdjustmentFormComponent } from "./location-adjustment.from";
import { SettingsAdjustmentFormComponent } from "./settings-adjustment.form";
import { ManualAdjustmentsFormComponent } from "./manual-adjustments.form";

import { AdjustmentSchema } from "../../validators/adjustment.validator";
import { createSimpleField } from "../../../../components/create-simple.form";

const { Option } = Select;

type Props = {
  initValue: Adjustment;
  isModify: boolean;
  isEqual: boolean;
  onChange: (value: Adjustment) => void;
  innerRef: any;
  onSubmit: () => void;
};

type State = {
  value: Adjustment;
};

export class AdjustmentFormComponent extends Component<Props, State> {
  private formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };

  private _updateSubject: BehaviorSubject<Adjustment>;

  constructor(props: Props, state: State) {
    super(props, state);

    this.state = {
      value: props.initValue
    };
    this._updateSubject = new BehaviorSubject<Adjustment>(props.initValue);
    this._updateSubject.pipe(debounceTime(500)).subscribe(values => {
      const { onChange } = this.props;
      onChange(values);
    });
  }

  statusValue() {
    return DataOptions.status.map(status => (
      <Option value={status.value}>{status.name}</Option>
    ));
  }

  updateState(value: Adjustment) {
    this._updateSubject.next(value);
  }

  componentDidMount() {}

  render() {
    const { initValue, isModify, onChange, onSubmit, isEqual } = this.props;
    const { value } = this.state;
    const idCreateModifyDate = () => {
      return isModify ? (
        <div>
          <FormItem name="ID" label="ID">
            {initValue?._id}
          </FormItem>
          <FormItem name="Created" label="Created">
            <Moment>{initValue?.createdAt}</Moment>
          </FormItem>
          <FormItem name="Modified" label="Modified">
            <Moment>{initValue?.modifiedAt}</Moment>
          </FormItem>
        </div>
      ) : null;
    };
    return (
      <Formik
        innerRef={this.props.innerRef}
        onSubmit={() => {
          console.log("onSubmit........");
        }}
        initialValues={value}
        validationSchema={AdjustmentSchema}
        enableReinitialize={true}
        render={({ errors, touched, values, handleReset }) => {
          this.updateState(values);
          return (
            <Form {...this.formItemLayout}>
              <Row>
                <Col span={12}>
                  <Row
                    style={{
                      backgroundColor: "#F0F0F0",
                      padding: "15px",
                      textAlign: "center"
                    }}
                  >
                    <Col span={12}>
                      <Button
                        onClick={() => {
                          handleReset();
                        }}
                      >
                        <i
                          className="fas fa-ban"
                          style={{ marginRight: "6px" }}
                        ></i>{" "}
                        Cancel
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        type="primary"
                        disabled={
                          !(AdjustmentSchema.isValidSync(values) && !isEqual)
                        }
                        onClick={() => {
                          onSubmit();
                        }}
                      >
                        <i
                          className="fas fa-save"
                          style={{ marginRight: "6px" }}
                        ></i>
                        Save
                      </Button>
                    </Col>
                  </Row>
                  {idCreateModifyDate()}
                  {createSimpleField(
                    "status",
                    "Status",
                    values,
                    errors,
                    touched,
                    <Select
                      name="status"
                      placeholder="Select a status"
                      allowClear
                    >
                      {this.statusValue()}
                    </Select>
                  )}
                  {createSimpleField(
                    "description",
                    "Description",
                    values,
                    errors,
                    touched,
                    <Input.TextArea name="description" placeholder="Enter a description"/>
                  )}
                  <Divider orientation="left">Creator</Divider>
                  <CreatorAdjustmentFormComponent
                    errors={errors}
                    touched={touched}
                    values={values}
                  />
                  <Divider orientation="left">Location</Divider>
                  <LocationAdjustmentFormComponent
                    errors={errors}
                    touched={touched}
                    values={values}
                  />
                  <Divider orientation="left">Settings</Divider>
                  <SettingsAdjustmentFormComponent
                    errors={errors}
                    touched={touched}
                    values={values}
                  />
                </Col>
                <Col span={12}>
                  <ManualAdjustmentsFormComponent
                    errors={errors}
                    touched={touched}
                    values={values}
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      />
    );
  }
}
