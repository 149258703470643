// main.page.tsx

import React, { Component } from "react";
import Button from "antd/es/button";
import { Layout, Menu, Icon } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import "./main.page.css";
import { MenuSectionContainer } from "./menu.section";

import { DashboardRouting } from "./pages/dashboard/routing";
import { SsoRouting } from "./pages/sso/routing";
import { NotificationsRouting } from "./pages/notifications/routing";
import { PrayertimesRouting } from "./pages/prayertimes/routing";
import { RamadanRouting } from "./pages/ramadan/routing";
import { QuranRouting } from "./pages/quran/routing";
import { DuaRouting } from "./pages/dua/routing";
import SigninPageComponent from "./authentication/pages/signin.page";
import { withAuth, AuthType } from "./authentication/components/with-auth";
import MainLayout from "./layouts/main.layout";
import { AuthContext } from "./authentication/components/with-auth";
import SignoutPageComponent from "./authentication/pages/signout.page";
import SettingsPageComponent from "./authentication/pages/settings.page";

const { Header, Sider, Content } = Layout;

// const RouterContext = React.createContext('light');

export type RouteData = {
  key: string;
  path: string;
  component: any;
  exact?: boolean;
};

type Props = {};

type State = {};

class MainPageComponent extends Component<Props, State> {
  static contextType = AuthContext;

  private routes: RouteData[] = [
    ...DashboardRouting,
    ...SsoRouting,
    ...NotificationsRouting,
    ...PrayertimesRouting,
    ...RamadanRouting,
    ...QuranRouting,
    ...DuaRouting
  ];

  appRender() {
    return (
      <>
        <Route path="/signout">
          <SignoutPageComponent />
        </Route>

        <MainLayout>
          <Route path="/settings">
            <SettingsPageComponent />
          </Route>
          {this.routes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              component={route.component}
              exact={route.exact}
            />
          ))}
        </MainLayout>
      </>
    );
  }

  render() {
    const { isAuth } = this.context;
    console.log(`isAuth = ${isAuth}`);
    const app = {
      Undetermined: "",
      Disconnected: (
        <>
          <Redirect to="/signin" />
          <Route path="/signin">
            <SigninPageComponent />
          </Route>
        </>
      ),
      Connected: this.appRender()
    };
    return (
      <Router>
        <Switch>{app[isAuth as AuthType]}</Switch>
      </Router>
    );
  }

}

export default withAuth(MainPageComponent);
