// routing.tsx

import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import { MainPageContainer as DashboardMainPage } from "./main.page";
import { RouteData } from "../../main.page";

export const DashboardRouting: RouteData[] = [
  {
    key: "/",
    path: "/",
    component: DashboardMainPage,
    exact: true
  }
];
