// adjustments.service.ts

import { Observable } from "rxjs";
import { gql, ApolloQueryResult, FetchResult } from "apollo-boost";
import {
  HelperService,
  search,
  get,
  update,
  create,
  remove,
  count
} from "../../../services/helper.service";
import { client } from "./graphql.module";

const FRAGMENT = gql`
  fragment AdjustmentFragment on Adjustment {
    _id
    createdAt
    modifiedAt
    status
    description
    creator {
      type
      _id
    }
    location {
      type
      parameters {
        geo {
          type
          coordinates
        }
        city
        country
        timezone
      }
    }
    method {
      type
      parameters {
        fajrAngle
        ishaAngle
        ishaTime
      }
    }
    angleBasedMethod
    asrMethod
    midnightMethod
    hijri
    manual {
      sunrise
      sunset
      midnight
      fajr
      dhuhr
      asr
      maghrib
      isha
      sahur
      iftar
    }
  }
`;

export interface AdjustmentParams {
  status: number;
  description: string;
  creator: {
    type: number;
    id?: string;
  };
  location: {
    type: number;
    parameters?: {
      latitude?: number;
      longitude?: number;
      city?: string;
      country?: string;
      timezone?: string;
    };
  };
  method: {
    type: number;
    parameters?: {
      fajrAngle?: number;
      ishaAngle?: number;
      ishaTime?: number;
    };
  };
  angleBasedMethod: number;
  asrMethod: number;
  midnightMethod: number;
  hijri: number;
  manual: {
    sunrise: number;
    sunset: number;
    midnight: number;
    fajr: number;
    dhuhr: number;
    asr: number;
    maghrib: number;
    isha: number;
    sahur: number;
    iftar: number;
  };
}

export interface SearchAdjustmentParams {
  perPage: number;
  page: number;
  mode: string;
  city?: string;
  country?: string;
  lat?: number;
  lon?: number;
  distance?: number;
}

export class AdjustmentsService {
  //protected QUERY_GET_ALL = this.createQueryGetAll('adjustments', FRAGMENT);

  public static search(
    input: SearchAdjustmentParams
  ): Promise<ApolloQueryResult<any>> {
    const query = HelperService.createQuerySearch(
      "adjustments",
      "SearchAdjustmentInput",
			FRAGMENT,
			"AdjustmentFragment"
    );
    return search(client, input, query);
  }

  public static get(id: string): Promise<ApolloQueryResult<any>> {
    const query = HelperService.createQueryGet("adjustment", FRAGMENT, "AdjustmentFragment");
    return get(client, id, query);
  }

  public static create(input: AdjustmentParams): Promise<FetchResult<any>> {
    const query = HelperService.createQueryCreate(
      "createAdjustment",
      "AdjustmentInput",
			FRAGMENT,
			"AdjustmentFragment"
    );
    return create(client, input, query);
  }

  public static update(
    id: string,
    input: AdjustmentParams
  ): Promise<FetchResult<any>> {
    const query = HelperService.createQueryUpdate(
      "updateAdjustment",
      "AdjustmentInput",
			FRAGMENT,
			"AdjustmentFragment"
    );
    return update(client, id, input, query);
  }

  public static remove(id: string): Promise<FetchResult<any>> {
    const query = HelperService.createQueryRemove("removeAdjustment");
    return remove(client, id, query);
  }

  public static count(): Promise<ApolloQueryResult<any>> {
    const query = HelperService.createQueryCount("countAdjustments");
    return count(client, query);
  }

  /*protected MUTATION_CREATE = this.createQueryCreate(
		'createAdjustment',
		'AdjustmentInput',
		FRAGMENT
	);
	protected MUTATION_UPDATE = this.createQueryUpdate(
		'updateAdjustment',
		'AdjustmentInput',
		FRAGMENT
	);
	protected MUTATION_REMOVE = this.createQueryRemove('removeAdjustment');

	protected QUERY_COUNT = this.createQueryCount('countAdjustments');


	public search(input: SearchAdjustmentParams): Observable<any> {
		return this._helperService.getAll<SearchAdjustmentParams>(
			'adjustments',
			'SearchAdjustmentInput',
			FRAGMENT,
			input
		);
	}*/
}
