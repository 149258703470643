// search.form.tsx

import React, { Component, Ref } from "react";
import { Row, Col, Divider } from "antd";
import Button from "antd/es/button";
import * as _ from "lodash";
import Moment from "react-moment";
import { Form, Input, FormItem, Select, Slider } from "formik-antd";
import { Formik } from "formik";
import { Subject, BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { DataOptions } from "../../data/adjustments-data.options";

import { createSimpleField } from "../../../../components/create-simple.form";
import { Search } from "../../models/search.model";
import { SearchSchema } from "../../validators/search.validator";

const { Option } = Select;

type Props = {
  innerRef: any;
  initValue: Search;
  onChange: (value: Search) => void;
  onSubmit: () => void;
};

type State = {};

export class SearchFormComponent extends Component<Props, State> {
  private formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };

  private static MODES = [
    { value: "all", name: "All" },
    { value: "coordinates", name: "Coordinates" },
    { value: "city", name: "City" },
    { value: "country", name: "Country" },
    { value: "world", name: "World" }
  ];

  private _updateSubject: BehaviorSubject<Search>;

  constructor(props: Props, state: State) {
    super(props, state);

    this._updateSubject = new BehaviorSubject<Search>(props.initValue);
    this._updateSubject.pipe(debounceTime(500)).subscribe(values => {
      const { onChange } = this.props;
      onChange(values);
    });
  }

  modesValue() {
    return SearchFormComponent.MODES.map(mode => (
      <Option value={mode.value}>{mode.name}</Option>
    ));
  }

  countriesValue() {
    return DataOptions.countries.map(country => (
      <Option value={country.code}>{country.name}</Option>
    ));
  }

  updateState(value: Search) {
    this._updateSubject.next(value);
  }

  componentDidMount() {}

  render() {
    const { initValue, onChange, onSubmit } = this.props;
    // const { value } = this.state;
    return (
      <Formik
        onSubmit={() => {
          console.log("onSubmit........");
        }}
        initialValues={initValue}
        validationSchema={SearchSchema}
        enableReinitialize={true}
        render={({ errors, touched, values }) => {
          this.updateState(values);
          return (
            <Form {...this.formItemLayout}>
              <Row
                    style={{
                      paddingBottom: "15px",
                      textAlign: "center"
                    }}
                  >
                    <Col span={12}></Col>
                    <Col span={12}>
                      <Button
                        type="primary"
                        disabled={!SearchSchema.isValidSync(values)}
                        onClick={() => {
                          onSubmit();
                        }}
                      >
                        <i
                          className="fas fa-search"
                          style={{ marginRight: "6px" }}
                        ></i>
                        Search
                      </Button>
                    </Col>
                  </Row>

                  {createSimpleField(
                    "mode",
                    "Mode",
                    values,
                    errors,
                    touched,
                    <Select name="mode" placeholder="Select a mode" allowClear>
                      {this.modesValue()}
                    </Select>
                  )}
                  {createSimpleField(
                    "lat",
                    "Latitude",
                    values,
                    errors,
                    touched,
                    <Input name="lat" />,
                    values.mode !== "coordinates"
                  )}
                  {createSimpleField(
                    "lon",
                    "Longitude",
                    values,
                    errors,
                    touched,
                    <Input name="lon" />,
                    values.mode !== "coordinates"
                  )}
                  {createSimpleField(
                    "distance",
                    "Distance",
                    values,
                    errors,
                    touched,
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ flex: 1 }}>
                        <Slider name="distance" min={1} max={50} />
                      </div>
                      <div style={{ marginLeft: "30px" }}>
                        {values.distance} km
                      </div>
                    </div>,
                    values.mode !== "coordinates"
                  )}
                  {createSimpleField(
                    "city",
                    "City",
                    values,
                    errors,
                    touched,
                    <Input name="city" />,
                    values.mode !== "city"
                  )}
                  {createSimpleField(
                    "country",
                    "Country",
                    values,
                    errors,
                    touched,
                    <Select
                      name="country"
                      placeholder="Select a country"
                      allowClear
                    >
                      {this.countriesValue()}
                    </Select>,
                    values.mode !== "country"
                  )}
            </Form>
          );
        }}
      />
    );
  }
}
