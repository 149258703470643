// graphql.module.ts

import { ApolloClient, DefaultOptions } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink, split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { OperationDefinitionNode } from "graphql";

const httpLink = new HttpLink({
  uri: "/api/prayertimes/graphql"
});

const wsLink = new WebSocketLink({
  uri: "ws://localhost:8990/prayertimes/graphql",
  options: {
    reconnect: false
  }
});

const terminatingLink = split(
  ({ query: { definitions } }) =>
    definitions.some(node => {
      const { kind, operation } = node as OperationDefinitionNode;
      return kind === "OperationDefinition" && operation === "subscription";
    }),
  wsLink,
  httpLink
);

const link = ApolloLink.from([terminatingLink]);

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore"
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all"
  }
};

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
});
