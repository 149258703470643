// types.ts

export interface SearchModel {
  value: string;
  count: number;
  lastModified: Date;
}

export enum AdjustmentActionTypes {
  COUNT = '@@adjustment/COUNT',
  FETCH_COUNT_SUCCESS = '@@adjustment/FETCH_COUNT_SUCCESS',
  FETCH_COUNT_ERROR = '@@adjustment/FETCH_COUNT_ERROR',
  SEARCH = '@@adjustment/SEARCH',
}

export interface AdjustmentState {
  readonly count: number;
  readonly search: SearchModel[]; //Map<string, SearchModel>;
}
