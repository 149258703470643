// store.ts

// export const test = "";
import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
// import { connectRouter, RouterState } from 'connected-react-router';
// import { History } from 'history';

// import { LayoutState, layoutReducer } from './layout';

import adjustmentSaga from '../pages/prayertimes/store/adjustment/sagas';
import { AdjustmentState } from '../pages/prayertimes/store/adjustment/types';
import { adjustmentReducer } from '../pages/prayertimes/store/adjustment/reducer';

import categorySaga from '../pages/dua/store/category/sagas';
import { CategoryState } from '../pages/dua/store/category/types';
import { categoryReducer } from '../pages/dua/store/category/reducer';

// The top-level state object
export interface ApplicationState {
  /*layout: LayoutState;
  heroes: HeroesState;*/
  adjustment: AdjustmentState;
  categories: CategoryState;
  /*bookmarks: BookmarksState;
  search: SearchState;
  router: RouterState;*/
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const createRootReducer = () =>
  combineReducers({
    /*layout: layoutReducer,
    heroes: heroesReducer,*/
    adjustment: adjustmentReducer,
    categories: categoryReducer,
    /*bookmarks: bookmarksReducer,
    search: searchReducer,*/
    // router: connectRouter(history),
  });

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
  yield all([fork(adjustmentSaga), fork(categorySaga)/*, fork(searchSaga)*/]);
}
