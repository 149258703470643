// deep-cleaner.util.ts

import * as _ from 'lodash';
import { AdjustmentParams } from '../pages/prayertimes/services/adjustments.service';
// import { keys } from 'ts-transformer-keys';

const _deepCleaner = (obj: any): any =>
	_.transform(obj, (r, v, k) => {
    if (k.toString() == "__typename") { return; }
    if (_.isUndefined(v) || _.isNull(v) || v === '') { return; }
    if (_.isObject(v)) {
      const t = _deepCleaner(v);
      if (Object.keys(t).length > 0) {
        r[k] = t;
      }
    } else {
      r[k] = v;
    }
	});

export const deepCleaner = <T>(obj: any): T => {
	const ret = _deepCleaner(obj);
	return ret as T;
}

export const read = <K extends keyof AdjustmentParams>(arg: AdjustmentParams, key: K): AdjustmentParams[K] => {
  return arg[key];
}
