// helper.service.ts

import ApolloClient, { ApolloQueryResult, FetchResult, NormalizedCacheObject } from "apollo-boost";
import { gql } from "apollo-boost";

export class HelperService {
  public static createQuerySearch(
    name: string,
    inputType: string,
    fragment: any,
    fragmentName: string
  ): any {
    return gql`
		query search($input: ${inputType}!) {
			${name}(input: $input) {
				meta {
					page
					perPage
					pageCount
					totalCount
				}
				data {
					...${fragmentName}
				}
			}
		}
		${fragment}
	`;
  }

  public static createQueryGet(name: string, fragment: any, fragmentName: string): any {
    return gql`
		query get($id: ID!) {
			${name}(id: $id) {
				data {
					...${fragmentName}
				}
			}
		}
		${fragment}
	`;
  }

  public static createQueryGetInput(
    name: string,
    inputType: string,
    fragment: any,
    fragmentName: string
  ): any {
    return gql`
		query getInput($input: ${inputType}!) {
			${name}(input: $input) {
				data {
					...${fragmentName}
				}
			}
		}
		${fragment}
	`;
  }

  public static createQueryCreate(
    name: string,
    inputType: string,
    fragment: any,
    fragmentName: string
  ): any {
    return gql`
		mutation create($input: ${inputType}!) {
			${name}(input: $input) {
				data {
					...${fragmentName}
				}
			}
		}
		${fragment}
	`;
  }

  public static createQueryUpdate(
    name: string,
    inputType: string,
    fragment: any,
    fragmentName: string
  ): any {
    return gql`
			mutation update($id: ID!, $input: ${inputType}!) {
				${name}(id: $id, input: $input) {
					data {
						...${fragmentName}
					}
				}
			}
			${fragment}
		`;
  }

  public static createQueryRemove(name: string): any {
    return gql`
			mutation remove($id: ID!) {
				${name}(id: $id) {
					data
				}
			}
		`;
  }

  public static createQueryRemoveWithInput(
    name: string,
    inputType: string
  ): any {
    return gql`
			mutation remove($id: ID!, $input: ${inputType}!) {
				${name}(id: $id, input: $input) {
					data
				}
			}
		`;
  }

  public static createQueryCount(name: string): any {
    return gql`
		query count {
			${name} {
				data
			}
		}
	`;
  }
}

export const search = <T>(
  client: ApolloClient<NormalizedCacheObject>,
  input: T,
  query: any
): Promise<any> => {
  return client.query({
    query: query,
    variables: {
      input: input
    }
  });
};

export const get = (
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
  query: any
): Promise<ApolloQueryResult<any>> => {
  return client.query({
    query: query,
    variables: {
      id: id
    }
  });
};

export const create = <T>(
  client: ApolloClient<NormalizedCacheObject>,
	input: T,
  query: any
): Promise<FetchResult<any>> => {
  return client.mutate({
    mutation: query,
    variables: {
			input: input
    }
  });
};

export const update = <T>(
  client: ApolloClient<NormalizedCacheObject>,
	id: string,
	input: T,
  query: any
): Promise<FetchResult<any>> => {
  return client.mutate({
    mutation: query,
    variables: {
			id: id,
			input: input
    }
  });
};

export const remove = <T>(
  client: ApolloClient<NormalizedCacheObject>,
	id: string,
  query: any
): Promise<FetchResult<any>> => {
  return client.mutate({
    mutation: query,
    variables: {
			id: id
    }
  });
};


export const count = (
  client: ApolloClient<NormalizedCacheObject>,
  query: any
): Promise<ApolloQueryResult<any>> => {
  return client.query({
    query: query,
    variables: {}
  });
};