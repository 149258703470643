// with-auth.tsx

import { useCookies } from 'react-cookie';
import React, { createContext, useState, useEffect } from "react";
import { AuthService } from '../services/auth.service';

export enum AuthType {
  Undetermined = "Undetermined",
  Disconnected = "Disconnected",
  Connected = "Connected"
}

type AuthContextProps = {
  isAuth: AuthType;
};

type AuthContextProviderProps = {
  isAuth: AuthType;
  checkAuth: () => void;
  setAuth: (value: AuthType) => void;
};

type AuthContextState = {
  isAuth: AuthType;
};

export const AuthContext = createContext({ isAuth: AuthType.Undetermined } as AuthContextProviderProps);

export const withAuth = (App: any): any => {
  const WithAuth = (): any/*: React.FC<AuthContextProps>*/ => {
    // const [cookies, setCookie] = useCookies(['isAuth']);
    const [authContextState, setAuthContextState] = useState({ isAuth: /* cookies.isAuth || */ AuthType.Undetermined });

    useEffect(() => {
      checkAuth();
    }, ['isAuth']);

    function setAuth(value: AuthType) {
      console.log(`setAuth value = ${value}`);
      // setCookie('isAuth', true);
      setAuthContextState({ isAuth: value });
    }

    function checkAuth() {
      AuthService.checkAuth().then(_ => {
        setAuthContextState({ isAuth: AuthType.Connected })
      }).catch(error => {
        setAuthContextState({ isAuth: AuthType.Disconnected })
      });
    }

    // const { ...props } = this.props;
    // const { isAuth } = this.state;
    return (
      <AuthContext.Provider value={{ isAuth: authContextState.isAuth, checkAuth: checkAuth, setAuth: setAuth }}>
        <App />
      </AuthContext.Provider>
    );
  };
  return WithAuth;
};

/* export const withAuth = (App: any) => {
  return class extends React.Component<AuthContextProps, AuthContextState> {
    static displayName = 'withAuth(App)'

    state = {
      isAuth: this.props.isAuth
    }

    setAuth(value: boolean) {
      this.setState({ isAuth: value })
    }

    render() {
      const { ...props } = this.props
      const { isAuth } = this.state
      return (
        <AuthContext.Provider value={{ isAuth, setAuth: this.setAuth }}>
          <App {...props} />
        </AuthContext.Provider>
      )
    }
  }
} */

/* type Props = {
  statusCode: number
}

export const StatusCode: React.FC<Props> = ({ statusCode, children }) => {
  return (
    <AuthContext.Consumer>
      {response => {
        response.statusCode = statusCode
        return children
      }}
    </AuthContext.Consumer>
  )
} */
