// location.validator.ts

import * as Yup from "yup";

export const LocationSchema = Yup.object().shape({
  latitude: Yup.number()
    .min(-90, "Enter a valid latitude!")
    .max(90, "Enter a valid latitude!")
    .required("Latitude is required"),
  longitude: Yup.number()
    .min(-180, "Enter a valid longitude!")
    .max(180, "Enter a valid longitude!")
    .required("Longitude is required"),
  city: Yup.string()
    .min(2)
    .required("City is required"),
  country: Yup.string()
    .min(2, "Choose a valid country!")
    .max(3, "Choose a valid country!")
    .required("Country is required"),
  timezone: Yup.string().min(2, "Choose a valid timezone!").required("Timezone is required")
});
