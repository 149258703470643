// main.page.tsx

import React, { Component } from "react";
import Button from "antd/es/button";
import { Layout, Menu, Icon, Tabs } from "antd";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import "./main.page.css";
import { InfoCardComponent } from "../../components/info-card.component";
import { ApplicationState } from "../../store";
import { CategoriesSectionContainer } from "./categories/categories.section";
import { CategoryState } from "./store/category/types";
// import { InfoCardComponent } from "../../components/info-card.component";
/*import { AdjustmentsSectionContainer } from "./adjustments.section";
import { AdjustmentsService } from "./services/adjustments.service";
import { ApplicationState } from "../../store";
import { AdjustmentState } from "./store/adjustment/types";*/

import { count } from "./store/category/actions";


const { Header, Sider, Content } = Layout;
const { TabPane } = Tabs;

type Props = {
  categories: CategoryState;
  categoriesCountRequest: () => void;
};

type State = {
  adjustments: number;
  lat: number;
  lng: number;
  zoom: number;
};

class MainPageComponent extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13,
      adjustments: 0
    };
  }

  componentDidMount() {
    this.props.categoriesCountRequest();
  }

  render() {
    const { categories } = this.props;

    return (
      <Content
        style={{
          margin: "24px 16px",
          padding: 24,
          minHeight: 280
        }}
      >
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <InfoCardComponent
            title={"Categories"}
            value={categories.count}
            icon={<i className="fas fa-adjust"></i>}
          />
        </div>

        <div style={{ backgroundColor: "#fff", padding: "1em" }}>
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={
                <span>
                  <i className="fas fa-list" style={{ fontSize: "1.4em" }}></i> Categories
                </span>
              }
              key="1"
            >
              <CategoriesSectionContainer />

            </TabPane>
            <TabPane
              tab={
                <span>
                  <i className="fas fa-tags" style={{ fontSize: "1.4em" }}></i> Tags
                </span>
              }
              key="2"
            >
              Tags
            </TabPane>
            <TabPane
              tab={
                <span>
                  <i className="fas fa-layer-group" style={{ fontSize: "1.4em" }}></i> Divisions
                </span>
              }
              key="3"
            >
              Divisions
            </TabPane>
            <TabPane
              tab={
                <span>
                  <i className="fas fa-cubes" style={{ fontSize: "1.4em" }}></i> Collections
                </span>
              }
              key="4"
            >
              Collections
            </TabPane>
            <TabPane
              tab={
                <span>
                  <i className="fas fa-swatchbook" style={{ fontSize: "1.4em" }}></i> Editions
                </span>
              }
              key="5"
            >
              Editions
            </TabPane>
            <TabPane
              tab={
                <span>
                  <i className="fas fa-praying-hands" style={{ fontSize: "1.4em" }}></i> Duas
                </span>
              }
              key="6"
            >
              Duas
            </TabPane>
          </Tabs>
        </div>
      </Content>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    categories: state.categories,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      categoriesCountRequest: () => dispatch(count())
    },
    dispatch,
  );
};

export const MainPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainPageComponent);
